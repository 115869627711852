import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FC, Fragment, useEffect, useState } from 'react';
import AppMenuItemComponent from '../AppMenuItemComponent/AppMenuItemComponent';

export const AppMenuItemPropTypes = {
  Icon: PropTypes.elementType,
  items: PropTypes.array,
  link: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

type AppMenuItemsPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>;

type AppMenuItemPropsWithoutItems = Omit<AppMenuItemsPropTypes, 'items'>;

export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[];
  matchUrl?: string;
};

const AppMenuItem: FC<AppMenuItemProps> = ({
  Icon,
  items = [],
  link,
  matchUrl,
  name,
  title,
}) => {
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const detailPath = `/${matchUrl?.split('/')[1]}`;
    if (matchUrl && detailPath) {
      const match = items?.find((val) => {
        return val.link === detailPath;
      });
      if (match) {
        setOpen(true);
      }
    }
  }, [matchUrl, link, items]);

  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      link={link}
      onClick={handleClick}
    >
      {!!Icon && (
        <ListItemIcon>
          <Icon fontSize="13px" />
        </ListItemIcon>
      )}
      <ListItemText primary={title} inset={!Icon} />
      {isExpandable && !open && <IconExpandMore fontSize="small" />}
      {isExpandable && open && <IconExpandLess fontSize="small" />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" dense disablePadding>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <Fragment>
      {MenuItemRoot}
      {MenuItemChildren}
    </Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '& .MuiListItemText-primary': {
        fontSize: '0.8125rem',
      },
      '& .MuiListItemText-inset': {
        paddingLeft: '38px',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '38px',
      },
      '&.active': {
        background: 'rgb(0 98 245 / 8%)',
        color: theme.palette.primary.dark,
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.dark,
        },
      },
    },
  })
);

export { AppMenuItem };
