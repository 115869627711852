import { FC, Fragment } from 'react';
import {
  AddButton,
  RefreshButton,
  ResetButton,
  Searchbox,
  Toolbar,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { AdvancedSearch } from './components/AdvancedSearch';
import { ResourceFormDialog as CreateDialog } from './components/ResourceFormDialog/ResourceFormDialog';
import {
  ResourceDeleteDialog as DeleteDialog,
  ResourceTable2,
} from './components';
import { useResource2 } from './hooks';

const Resource2: FC = () => {
  const {
    canAdd,
    canDelete,
    canEdit,
    data,
    handleAdvancedSearchChange,
    handleCreateAction,
    handleCreateCancel,
    handleCreateRequest,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    handlePageChange,
    handleRefresh,
    handleSearchChange,
    handleSearchClear,
    handleSearchEnter,
    handleSearchReset,
    handleStepChange,
    isCreateDialogOpen,
    isDeleteDialogOpen,
    isDeleting,
    isFetched,
    isFetching,
    isFilter,
    isLoading,
    isProcessing,
    isSearch,
    isSearching,
    paginationOffset: currentOffset,
    paginationStep: pageStep,
    params,
    searchQuery,
    selectedItem,
    wasSearch,
  } = useResource2();
  const showAddButton = canAdd;

  return (
    <Fragment>
      <Toolbar>
        <Toolbar.Control>
          <Toolbar.Search>
            <Searchbox
              autoFocus
              name="name"
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              onEnter={handleSearchEnter}
              placeholder="Search by name"
              value={searchQuery}
            />
            <AdvancedSearch
              onApply={handleAdvancedSearchChange}
              query={{
                type: params.type,
              }}
            />
            <ResetButton
              disabled={!isSearch && !isFilter}
              onClick={handleSearchReset}
            >
              Reset Search
            </ResetButton>
          </Toolbar.Search>
          <Toolbar.Action>
            <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>
            {showAddButton && (
              <AddButton
                data-test-id="btn-add-resource"
                onClick={handleCreateRequest}
              >
                Add Resource
              </AddButton>
            )}
          </Toolbar.Action>
        </Toolbar.Control>
      </Toolbar>
      <ResourceTable2
        canDelete={canDelete}
        canEdit={canEdit}
        data={data.items}
        isFetched={isFetched}
        isFetching={isFetching}
        isFilter={isFilter}
        isLoading={isLoading}
        isProcessing={isProcessing}
        isSearch={isSearch || isFilter}
        isSearching={isSearching}
        onDelete={handleDeleteRequest}
        onPageChange={handlePageChange}
        onStepChange={handleStepChange}
        paginationOffset={currentOffset}
        paginationStep={pageStep}
        totalCount={undefined}
        totalRecord={data.totalRecord}
        wasSearch={wasSearch}
      />
      <CreateDialog
        open={isCreateDialogOpen}
        onClose={handleCreateCancel}
        onSave={handleCreateAction}
      />
      <DeleteDialog
        loading={isDeleting}
        name={selectedItem?.name}
        onClose={handleDeleteCancel}
        onSubmit={handleDeleteAction}
        open={isDeleteDialogOpen}
      />
    </Fragment>
  );
};

export { Resource2 };
