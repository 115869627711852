import DeleteIcon from '@mui/icons-material/Delete';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC } from 'react';

const DeleteButton: FC<ButtonProps> = ({ children = 'Delete', ...rest }) => {
  return (
    <Button
      color="error"
      startIcon={<DeleteIcon />}
      variant="outlined"
      {...rest}
    >
      {children}
    </Button>
  );
};

export { DeleteButton };
