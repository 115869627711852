import 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectPermissionGroupDetail } from '@bom-nextgen-keycloak/web/core';
import {
  AttributeList,
  FormActionsGroup,
  FormBox,
  FormField,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import { PARENT_PATH } from '../../shared';
import { validationSchema } from './config';

type Attributes = {
  industry: string;
};

type IndustryFormProps = {
  attributes: Record<string, string>;
  loading: boolean;
  onSubmit: (attributes: AttributeList) => void;
};

const IndustryForm2: FC<IndustryFormProps> = ({
  attributes,
  onSubmit,
  loading,
}) => {
  const navigate = useNavigate();
  const initialValues = {
    industry: attributes?.['industry'] || '',
  };

  const { isEditAttributesTab: canEdit } = useSelector(
    selectPermissionGroupDetail
  );

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(payload, { setSubmitting }: FormikHelpers<Attributes>) => {
        onSubmit({
          industry: [payload.industry],
        });
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, resetForm }) => {
        const isUpdating = loading;
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: isUpdating,
          isValid,
        });

        return (
          <Form>
            <FormBox>
              <FormField>
                <TextField
                  disabled={!canEdit}
                  label="Industry"
                  name="industry"
                  required
                />
              </FormField>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={isUpdating}
              onCancel={handleCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export { IndustryForm2 };
