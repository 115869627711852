import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectPermissionGroupDetail,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  FormActionsGroup,
  FormBox,
  SwitchField,
  SwitchGroup,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import { PARENT_PATH } from '../../shared';
import { validationSchema } from './config';
import { FieldGroup } from './SubscriptionForm2.styled';

type FormValues = Record<string, string[]>;

type DateRange = Date | string | null;

type Attributes = {
  demo: boolean;
  enabled: boolean;
  endDate: DateRange;
  startDate: DateRange;
  trial: boolean;
  unlimited: boolean;
};

type SubscriptionsFormProps = {
  attributes: Record<string, any>;
  loading: boolean;
  onSubmit: (attributes: FormValues) => void;
  name?: string;
};

const mapAttribute = (element: string[]) => {
  if (element) {
    return element[0];
  }

  return 'false';
};

const SubscriptionForm2: FC<SubscriptionsFormProps> = ({
  attributes,
  loading,
  name = '',
  onSubmit,
}) => {
  const resource = useResource();
  const { clientName } = resource;
  const navigate = useNavigate();
  const { isEditAttributesTab: canEdit } = useSelector(
    selectPermissionGroupDetail
  );

  const getAttribute = (name: string) => {
    const value = attributes?.[`${clientName}.${name}`] || null;

    if (value !== null && value instanceof Array && value[0] === '') {
      return null;
    }

    return value;
  };

  const initialValues = {
    demo: mapAttribute(getAttribute('demo')) === 'true',
    enabled: mapAttribute(getAttribute('enabled')) === 'true',
    endDate: getAttribute('endDate'),
    startDate: getAttribute('startDate'),
    trial: mapAttribute(getAttribute('trial')) === 'true',
    unlimited: mapAttribute(getAttribute('unlimited')) === 'true',
  };

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(payload, { setSubmitting }: FormikHelpers<Attributes>) => {
        const formValues: FormValues = {
          [`${clientName}.startDate`]: payload.startDate
            ? [payload.startDate?.toLocaleString()]
            : [''],
          [`${clientName}.endDate`]: payload.endDate
            ? [payload.endDate?.toLocaleString()]
            : [''],
          [`${clientName}.unlimited`]: [payload.unlimited.toString()],
          [`${clientName}.demo`]: [payload.demo.toString()],
          [`${clientName}.trial`]: [payload.trial.toString()],
          [`${clientName}.enabled`]: [payload.enabled.toString()],
          [`${clientName}.name`]: [name],
        };

        onSubmit(formValues);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, getFieldMeta, isValid, resetForm, setFieldValue, values }) => {
        const isUpdating = loading;
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: isUpdating,
          isValid,
        });

        return (
          <Form>
            <FormBox>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  endText="End date"
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setFieldValue('endDate', newValue[1] ?? null);
                    setFieldValue('startDate', newValue[0] ?? null);
                  }}
                  renderInput={(startProps, endProps) => (
                    <FieldGroup>
                      <TextField
                        {...startProps}
                        disabled={!canEdit}
                        error={!!getFieldMeta('startDate').error}
                        helperText={
                          getFieldMeta('startDate').error
                            ? getFieldMeta('startDate').error
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: values.unlimited,
                        }}
                        name="startDate"
                        value={values.startDate}
                        variant="standard"
                      />
                      <TextField
                        {...endProps}
                        disabled={values.unlimited || !canEdit}
                        error={!!getFieldMeta('endDate').error}
                        helperText={
                          getFieldMeta('endDate').error
                            ? getFieldMeta('endDate').error
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="endDate"
                        style={{ marginTop: '1.875rem' }}
                        value={values.startDate}
                        variant="standard"
                      />
                    </FieldGroup>
                  )}
                  startText="Start date"
                  value={[values.startDate, values.endDate]}
                />
              </LocalizationProvider>
              <SwitchGroup>
                <Stack direction="row" spacing={4}>
                  <SwitchField
                    checked={values.unlimited}
                    disabled={!canEdit}
                    label="Unlimited"
                    name="unlimited"
                  />
                  <SwitchField
                    checked={values.demo}
                    disabled={!canEdit}
                    label="Demo"
                    name="demo"
                  />
                  <SwitchField
                    checked={values.trial}
                    disabled={!canEdit}
                    label="Trial"
                    name="trial"
                  />
                  <SwitchField
                    checked={values.enabled}
                    disabled={!canEdit}
                    label="Enabled"
                    name="enabled"
                  />
                </Stack>
              </SwitchGroup>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={isUpdating}
              onCancel={handleCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export { SubscriptionForm2 };
