import { ResourceDto } from '@bom-nextgen-keycloak/models';
import { ResourceApplication } from '@bom-nextgen-keycloak/web/shared/data-access';
import { getPreferedWorkspace } from '@bom-nextgen-keycloak/web/shared/util';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../store';

export interface ResourceState {
  active: ResourceApplication;
  list: ResourceApplication[];
}

const initialState: ResourceState = {
  active: {
    resourceId: '',
    resourceName: '',
    displayName: '',
    clientId: '',
    clientName: '',
    clientNamePublic: '',
    groupId: '',
    scopes: [],
  },
  list: [],
};

export const mapResourceApplication = (
  data: ResourceDto[]
): ResourceApplication[] => {
  return data.map((val) => ({
    appName: val?.attributes?.app_name ? val?.attributes?.app_name[0] : '',
    resourceId: val._id || uuidv4(),
    resourceName: val.name || '',
    displayName: val?.displayName || 'Default Name',
    clientId: val?.attributes?.client_id ? val?.attributes?.client_id[0] : '',
    clientName: val?.attributes?.client_name
      ? val?.attributes?.client_name[0]
      : '',
    clientNamePublic: val?.attributes?.client_name_public
      ? val?.attributes?.client_name_public[0]
      : '',
    groupId: val?.attributes?.group_id ? val?.attributes?.group_id[0] : '',
    scopes: val.scopes || [],
  }));
};

export const mapdefaultResourceApplication = (
  resources: ResourceDto
): ResourceApplication => {
  return {
    appName: resources?.attributes?.app_name
      ? resources?.attributes?.app_name[0]
      : '',
    resourceId: resources?._id || '',
    resourceName: resources?.name || '',
    displayName: resources?.displayName || 'Default Name',
    clientId: resources?.attributes?.client_id
      ? resources?.attributes?.client_id[0]
      : '',
    clientName: resources?.attributes?.client_name
      ? resources?.attributes?.client_name[0]
      : '',
    clientNamePublic: resources?.attributes?.client_name_public
      ? resources?.attributes?.client_name_public[0]
      : '',
    groupId: resources?.attributes?.group_id
      ? resources?.attributes?.group_id[0]
      : '',
  };
};

const getDefaultPermissions = (persmission: ResourceDto[]) => {
  const workspace = getPreferedWorkspace();
  const resource: ResourceApplication = workspace
    ? JSON.parse(workspace || '')
    : null;
  const find = persmission.find((val) => val._id === resource?.resourceId);
  if (find) {
    return resource;
  }
  return mapdefaultResourceApplication(persmission[0]);
};

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    resetResource: (state) => {
      state.active = initialState.active;
      state.list = initialState.list;
    },
    setActiveResource: (state, action: PayloadAction<ResourceApplication>) => {
      state.active = action.payload;
    },
    setResourceList: (state, action: PayloadAction<ResourceDto[]>) => {
      state.active = getDefaultPermissions(action.payload);
      state.list = mapResourceApplication(action.payload);
    },
  },
});

export const { resetResource, setActiveResource, setResourceList } =
  resourceSlice.actions;

export const selectResource = (state: RootState) => state.resource.active;
export const selectResourceList = (state: RootState) => state.resource.list;

export default resourceSlice.reducer;
