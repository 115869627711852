import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import { FC, Suspense } from 'react';
import { Outlet } from 'react-router';
import { Spinner } from '@bom-nextgen-keycloak/web/shared/ui';
import { Placeholder } from './Content.styled';

type ContentProps = {
  isReady: boolean;
};

const Content: FC<ContentProps> = ({ isReady }) => {
  const renderContent = () => {
    return isReady ? (
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    ) : (
      <Placeholder>
        <Chip label="Loading..." variant="filled" />
      </Placeholder>
    );
  };

  return <Container maxWidth="lg">{renderContent()}</Container>;
};

export { Content };
