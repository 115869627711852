import ClearIcon from '@mui/icons-material/Clear';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react';
import { Action, Box, Button, Input, Wrapper } from './Searchbox.styled';

export type SearchboxEmit = {
  name: string;
  value: string;
};

export type SearchboxOptions = {
  event: SearchboxEmit;
  isEnter?: boolean;
};

type SearchboxProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps;
  name?: string;
  maxLength?: number;
  onChange: (value: SearchboxEmit) => void;
  onClear?: () => void;
  onEnter?: (value: SearchboxEmit) => void;
  onReset?: () => void;
  placeholder?: string;
  value?: string;
  width?: number | 'auto';
};

const Searchbox: FC<SearchboxProps> = ({
  autoFocus = false,
  disabled = false,
  inputProps,
  maxLength = 255,
  name = '',
  onChange,
  onClear,
  onEnter,
  onReset,
  placeholder = 'Search',
  value = '',
  width = 260,
}) => {
  const [inputValue, setInputValue] = useState('');

  const canClear = Boolean(onClear);
  const canReset = Boolean(onReset);
  const isActive = inputValue !== '';

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const trimInput = (value: string) => value.trim();

  const getEventTarget = (
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
  ) => event.target as HTMLInputElement;

  const getOutput = (eventTarget: HTMLInputElement) => ({
    name: eventTarget.name,
    value: trimInput(eventTarget.value),
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const eventTarget = getEventTarget(event);

    setInputValue(eventTarget.value);
    onChange(getOutput(eventTarget));
  };

  const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    const eventTarget = getEventTarget(event);

    if (onEnter && event.key === 'Enter' && eventTarget.value) {
      onEnter(getOutput(eventTarget));
    }
  };

  const handleClear = () => {
    const value = '';

    setInputValue(value);
    onClear && onClear();
  };

  const handleReset = () => {
    const value = '';

    setInputValue(value);
    onReset && onReset();
  };

  return (
    <Wrapper>
      <Box width={width}>
        <Input
          autoFocus={autoFocus}
          disabled={disabled}
          inputProps={{
            ...inputProps,
            inputMode: 'search',
            maxLength,
          }}
          name={name}
          onChange={handleChange}
          onKeyDown={handleEnter}
          placeholder={placeholder}
          size="small"
          value={inputValue}
        />
        <Action>
          {canReset && (
            <Button
              aria-label="reset search"
              disabled={!isActive}
              onClick={handleReset}
              title="Reset search"
            >
              <RestartAltIcon fontSize="inherit" />
            </Button>
          )}
          {canClear && (
            <Button
              aria-label="clear search"
              disabled={!isActive}
              onClick={handleClear}
              size="small"
              title="Clear search"
            >
              <ClearIcon fontSize="inherit" />
            </Button>
          )}
        </Action>
      </Box>
    </Wrapper>
  );
};

export { Searchbox };
