import { styled } from '@mui/material/styles';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { FC } from 'react';

export const Capitalize = styled('span')`
  text-transform: capitalize;
`;

export const Section = styled('div')`
  margin-bottom: 2rem;
`;

export const SectionHead = styled((props: TypographyProps) => (
  <Typography {...props} variant="h2" />
))`
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const TableCellHead: FC<TableCellProps> = ({
  children,
  ...restProps
}) => (
  <TableCell component="th" scope="row" {...restProps}>
    {children}
  </TableCell>
);
