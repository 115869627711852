export const enum FeaturePage {
  USER = 'User',
  SCOPE = 'Scope',
  ROLE = 'Role',
  RESOURCE = 'Resource',
  POLICY = 'Policy',
  PERMISSION = 'Permission',
  GROUP = 'Group',
  EVENT = 'Event',
  COMPANY = 'Company',
  ENVIRONMENT = 'Environment',
}

export const enum ScopeApplication {
  'VIEW' = 'VIEW',
  'READ' = 'READ',
  'EDIT' = 'EDIT',
  'TOGGLE' = 'TOGGLE',
  'CREATE' = 'CREATE',
  'DELETE' = 'DELETE',
  'UPDATE' = 'UPDATE',
  'EXPORT' = 'EXPORT',
  'REMOVE' = 'REMOVE',
  'CHANGE' = 'CHANGE',
  'DEBUG' = 'DEBUG',
}
