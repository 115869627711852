import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectPermissionGroupDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AttributeList,
  FormAttribute,
  FormSpinner,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { useGroupDetail2 } from '../../hooks';
import { PARENT_PATH } from '../../shared';
import { IndustryForm2 } from '../IndustryForm2/IndustryForm2';
import { SubscriptionForm2 } from '../SubscriptionForm2/SubscriptionForm2';

const Attribute: FC = () => {
  const { id = '' } = useParams();
  const { data, isLoading, isSubscription, updateMutation } =
    useGroupDetail2(id);
  const { isEditAttributesTab: canEdit } = useSelector(
    selectPermissionGroupDetail
  );

  const handleUpdate = (attributes: AttributeList) => {
    if (data) {
      updateMutation.mutate({
        id,
        payload: {
          attributes: {
            ...attributes,
          },
          clientRoles: data.clientRoles || {},
          id: data.id,
          name: data.name || '',
          path: data.path,
          subGroups: data.subGroups || [],
        },
      });
    } else {
      // TODO: Implement a handler
    }
  };

  const renderContent = () => {
    if (isSubscription) {
      return (
        <SubscriptionForm2
          attributes={data?.attributes || {}}
          loading={updateMutation.isLoading}
          name={data?.name || ''}
          onSubmit={handleUpdate}
        />
      );
    } else if (data?.level === 2) {
      return (
        <IndustryForm2
          attributes={data?.attributes || {}}
          loading={updateMutation.isLoading}
          onSubmit={handleUpdate}
        />
      );
    } else {
      return (
        <FormAttribute
          disabled={!canEdit}
          loading={updateMutation.isLoading}
          onSubmit={handleUpdate}
          parentPath={PARENT_PATH}
          values={data?.attributes || {}}
        />
      );
    }
  };

  return isLoading ? <FormSpinner /> : renderContent();
};

const WrappedGroupAttributePage2: FC = () => {
  const { canViewAttributesTab } = useSelector(selectPermissionGroupDetail);

  return (
    <ProtectedRouter
      canView={canViewAttributesTab}
      feature={FeaturePage.GROUP}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <Attribute />
    </ProtectedRouter>
  );
};

export { WrappedGroupAttributePage2 };
