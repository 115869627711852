import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { GroupUpdateDto } from '@bom-nextgen-keycloak/models';
import { selectPermissionGroupDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  FormActionsGroup,
  FormBox,
  FormField,
  FormSpinner,
  ProtectedRouter,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import { useGroupDetail2 } from '../../hooks';
import { PARENT_PATH } from '../../shared';
import { validationSchema } from './config';

type Form = {
  id: string;
  level: number;
  name: string;
};

const General: FC = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, updateMutation } = useGroupDetail2(id);
  const { isEditGeneralTab: canEdit } = useSelector(
    selectPermissionGroupDetail
  );

  const initialValues: Form = {
    id: data?.id ?? '',
    level: data?.level ?? 0,
    name: data?.name ?? '',
  };

  const handleUpdate = (payload: GroupUpdateDto | undefined) => {
    if (payload) {
      updateMutation.mutate({
        id,
        payload: {
          ...payload,
          attributes: data?.attributes,
        },
      });
    } else {
      // TODO: Implement a handler
    }
  };

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  return isLoading ? (
    <FormSpinner />
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(payload: Form, { setSubmitting }: FormikHelpers<Form>) => {
        handleUpdate({
          ...payload,
          attributes: data?.attributes || {},
          clientRoles: data?.clientRoles || {},
          path: data?.path,
          subGroups: data?.subGroups || [],
        });

        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, resetForm }) => {
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: updateMutation.isLoading,
          isValid,
        });

        return (
          <Form>
            <FormBox>
              <FormField>
                <TextField
                  autoFocus
                  disabled={data?.level === 3 || !canEdit}
                  label="Name"
                  name="name"
                  required
                />
              </FormField>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={updateMutation.isLoading}
              onCancel={handleCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const WrappedGroupGeneralPage: FC = () => {
  const { canViewGeneralTab } = useSelector(selectPermissionGroupDetail);

  return (
    <ProtectedRouter
      canView={canViewGeneralTab}
      feature={FeaturePage.GROUP}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <General />
    </ProtectedRouter>
  );
};

export { WrappedGroupGeneralPage };
