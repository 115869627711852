import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  FeaturePage,
  PermissionEnvironment,
  ScopeApplication,
  EnvironmentEvent,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentEnvironment = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.ENVIRONMENT.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapEnvironmentPermission = (
  resources: ResourceDto[]
): PermissionEnvironment => {
  const environmentComponentList: ResourceType[] =
    getComponentEnvironment(resources);

  return {
    canChange: matchPermission(
      environmentComponentList,
      EnvironmentEvent.CHANGE,
      ScopeApplication.CHANGE
    ),
    canDebug: matchPermission(
      environmentComponentList,
      EnvironmentEvent.DEBUG,
      ScopeApplication.DEBUG
    ),
    canView: matchPermission(
      environmentComponentList,
      EnvironmentEvent.VIEW,
      ScopeApplication.VIEW
    ),
  };
};
