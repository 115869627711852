import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const Brand = styled(Link)`
  &,
  &:hover,
  &:visited {
    color: #fff;
    text-decoration: none;
  }
`;

export const Info = styled('div')`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 0 1rem;
`;

export const Menu = styled('div')``;

export const Navbar = styled('div')`
  display: flex;
  flex-grow: 1;
  padding-left: 2rem;
`;

export const Selector = styled(Button)`
  text-transform: none;

  &.MuiButton-root.Mui-disabled {
    color: #fff;
  }
`;

export const Wrapper = styled('div')`
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;
