import { CONFIG as DEFAULT_CONFIG } from '@bom-nextgen-keycloak/web/core';

export const CONFIG = {
  ...DEFAULT_CONFIG,
  query: {
    ...DEFAULT_CONFIG.query,
    staleTime: 5 * 1000,
  },
};

export const PARENT_PATH = '/resources';
