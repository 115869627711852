import { FC, Fragment } from 'react';
import {
  AddButton,
  RefreshButton,
  ResetButton,
  Searchbox,
  Toolbar,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  ScopeDeleteDialog as DeleteDialog,
  ScopeFormDialog as CreateDialog,
  ScopeTable2,
} from './components';
import { useScope2 } from './hooks';

const Scope2: FC = () => {
  const {
    canAdd,
    canDelete,
    canEdit,
    data,
    handleCreateAction,
    handleCreateCancel,
    handleCreateRequest,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    handlePageChange,
    handleRefresh,
    handleSearchChange,
    handleSearchClear,
    handleSearchEnter,
    handleSearchReset,
    handleStepChange,
    isCreateDialogOpen,
    isDeleteDialogOpen,
    isDeleting,
    isFetched,
    isFetching,
    isLoading,
    isProcessing,
    isSearch,
    isSearching,
    paginationOffset: currentOffset,
    paginationStep: pageStep,
    searchQuery,
    selectedItem,
    wasSearch,
  } = useScope2();
  const showAddButton = canAdd;

  return (
    <Fragment>
      <Toolbar>
        <Toolbar.Control>
          <Toolbar.Search>
            <Searchbox
              autoFocus
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              onEnter={handleSearchEnter}
              placeholder="Search by name"
              value={searchQuery}
            />
            <ResetButton disabled={!isSearch} onClick={handleSearchReset}>
              Reset Search
            </ResetButton>
          </Toolbar.Search>
          <Toolbar.Action>
            <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>
            {showAddButton && (
              <AddButton
                data-test-id="btn-add-scope"
                onClick={handleCreateRequest}
              >
                Add Scope
              </AddButton>
            )}
          </Toolbar.Action>
        </Toolbar.Control>
      </Toolbar>
      <ScopeTable2
        canDelete={canDelete}
        canEdit={canEdit}
        data={data.items}
        isFetched={isFetched}
        isFetching={isFetching}
        isLoading={isLoading}
        isProcessing={isProcessing}
        isSearch={isSearch}
        isSearching={isSearching}
        onDelete={handleDeleteRequest}
        onPageChange={handlePageChange}
        onStepChange={handleStepChange}
        paginationOffset={currentOffset}
        paginationStep={pageStep}
        totalCount={undefined}
        totalRecord={data.totalRecord}
        wasSearch={wasSearch}
      />
      <CreateDialog
        onClose={handleCreateCancel}
        onSave={handleCreateAction}
        open={isCreateDialogOpen}
      />
      <DeleteDialog
        loading={isDeleting}
        name={selectedItem?.name}
        onClose={handleDeleteCancel}
        onSubmit={handleDeleteAction}
        open={isDeleteDialogOpen}
      />
    </Fragment>
  );
};

export { Scope2 };
