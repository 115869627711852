import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { FC } from 'react';
import { AppMenuItem } from '@bom-nextgen-keycloak/web/shared/ui';
import { Placeholder } from '../index';

type MainMenuProps = {
  data: any[];
  loading: boolean;
  matchUrl: string;
};

const MainMenu: FC<MainMenuProps> = ({ data, loading, matchUrl }) => {
  const menuList = (
    <List dense>
      {data.map((item, index) => (
        <AppMenuItem matchUrl={matchUrl} {...item} key={index} />
      ))}
    </List>
  );

  const menuPlaceholder = (
    <List dense>
      <ListItem>
        <Placeholder amount={6} />
      </ListItem>
    </List>
  );

  return loading ? menuPlaceholder : menuList;
};

export { MainMenu };
