import { styled } from '@mui/material/styles';

export const Content = styled('div')`
  position: relative;
`;

export const InfoItem = styled('li')`
  font-size: 0.8125rem;
  margin: 0;
  padding: 0;
`;

export const Sublist = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SublistItem = styled('li')`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8125rem;
`;

export const Error = styled('span')`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const Info = styled('div')`
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  height: 100%;
`;
