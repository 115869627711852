import * as yup from 'yup';
import { CONFIG as DEFAULT_CONFIG } from '@bom-nextgen-keycloak/web/core';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';

export const CONFIG = {
  ...DEFAULT_CONFIG,
  default: {
    value: {
      user: {
        email: '',
        firstName: '',
        id: 'undefined',
        lastName: '',
        username: 'undefined',
      },
    },
  },
  form: {
    fields: {
      clientId: {
        message: {
          validation: 'Client is required',
        },
        name: 'clientId',
        label: 'Clients',
        placeholder: 'Search by client name',
        value: [],
      },
      description: {
        name: 'description',
        label: 'Description',
        value: '',
      },
      groups: {
        message: {
          validation: 'Group is required',
        },
        name: 'groups',
        label: 'Groups',
        value: [],
      },
      logic: {
        message: {
          validation: 'Logic is required',
        },
        name: 'logic',
        label: 'Logic',
        placeholder: 'Select logic',
        value: '',
      },
      name: {
        message: {
          validation: 'Name is required',
        },
        name: 'name',
        label: 'Name',
        value: '',
      },
      roles: {
        message: {
          validation: 'Role is required',
        },
        name: 'roles',
        label: 'Roles',
        placeholder: 'Enter role name',
        value: [],
      },
      type: {
        name: 'type',
        label: 'Policy type',
      },
      users: {
        message: {
          validation: 'User is required',
        },
        name: 'users',
        label: 'Users',
        placeholder: 'Search by username',
        value: [],
      },
    },
  },
  pagination: {
    ...DEFAULT_CONFIG.pagination,
    first: DEFAULT_CONFIG.pagination.offset,
    max: DEFAULT_CONFIG.pagination.step,
  },
  query: {
    ...DEFAULT_CONFIG.query,
    staleTime: 5 * 1000,
  },
};

export const PARENT_PATH = '/policies';

export const VALIDATION_SCHEMA = {
  name: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required(CONFIG.form.fields.name.message.validation),
  logic: yup.string().required(CONFIG.form.fields.logic.message.validation),
};

export type PolicyForm = {
  description: string;
  name: string;
  logic: string;
  type: string;
};
