import { GroupPolicy } from '@bom-nextgen-keycloak/models';
import { IconButtonDelete } from '@bom-nextgen-keycloak/web/shared/ui';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableContainer as Container } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';

type GroupTableFormProps = {
  data: GroupPolicy[];
  isDisabled?: boolean;
  onChangeCheckBox: (checked: boolean, groupId: string | undefined) => void;
  onClickDelate: (data: GroupPolicy) => void;
};

export const TableContainer = styled(Container)`
  max-height: 250px;
`;

const GroupTableForm: FC<GroupTableFormProps> = ({
  data,
  onChangeCheckBox,
  onClickDelate,
  isDisabled = false,
}) => {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Path</TableCell>
            <TableCell>Extend to Children</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.path || ''}</TableCell>
              <TableCell>
                <Checkbox
                  disabled={isDisabled}
                  checked={item.extendChildren || false}
                  onChange={(e) => onChangeCheckBox(e.target.checked, item.id)}
                />
              </TableCell>
              <TableCell>
                <Tooltip title="Remove group">
                  <IconButtonDelete
                    aria-label="delete"
                    disabled={isDisabled}
                    onClick={() => onClickDelate(item)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButtonDelete>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { GroupTableForm };
