import { configureStore } from '@reduxjs/toolkit';
import accountReducer from '../reducer/account.reducer';
import errorReducer from '../reducer/error.reducer';
import permissionReducer from '../reducer/permission.reducer';
import resourceReducer from '../reducer/resource.reducer';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    error: errorReducer,
    permission: permissionReducer,
    resource: resourceReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
