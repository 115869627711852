const ADMIN_EMAIL = 'analytics.pmo.support@truedigital.com';

type MailTo = {
  subject: string;
  to?: string;
};

export const mailTo = ({ subject, to = ADMIN_EMAIL }: MailTo): string => {
  return `mailto:${to}?subject=${subject}`;
};
