import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  PermissionApplication,
  ResourceApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  mapCompanyPermission,
  mapEventPermission,
  mapGroupPermission,
  mapGroupPermissionDetail,
  mapPermission,
  mapPermissionDetail,
  mapPolicyPermission,
  mapPolicyPermissionDetail,
  mapRescourcePermission,
  mapRescourcePermissionDetail,
  mapRolePermission,
  mapRolePermissionDetail,
  mapScopePermission,
  mapScopePermissionDetail,
  mapUserPermission,
  mapUserPermissionDetail,
  mapEnvironmentPermission,
} from '../permission';
import { mapResourceApplication } from './resource.reducer';

export interface PermissionState {
  features: ResourceApplication[];
  application: PermissionApplication;
}
export type ResourceType = Omit<ResourceDto, 'scopes'> & {
  scopes: (string | undefined)[];
};

const initialState: PermissionState = {
  features: [],
  application: {
    user: {
      canViewAdd: false,
      canViewDelete: false,
      canViewExport: false,
      canViewToggle: false,
      canViewLeaveGroup: false,
    },
    userDetail: {
      canViewPage: false,
      canViewGeneralTab: false,
      canViewAttributesTab: false,
      canViewPasswordTab: false,
      canViewGroupTab: false,
      canViewRoleMappingTab: false,
      canViewSessionTab: false,
      canViewDeveloperToolTab: false,
      canViewIdpTab: false,
      isEditGeneralTab: false,
      isEditAttributesTab: false,
      isEditPasswordTab: false,
      isEditGroupTab: false,
      isEditRoleMappingTab: false,
      isEditUsername: false,
    },
    group: {
      canViewAdd: false,
      canViewDelete: false,
      canViewExport: false,
    },
    groupDetail: {
      canViewPage: false,
      canViewGeneralTab: false,
      canViewAttributesTab: false,
      canViewMemberTab: false,
      canViewRoleMappingTab: false,
      isEditGeneralTab: false,
      isEditAttributesTab: false,
      isEditMemberTab: false,
      isEditRoleMappingTab: false,
    },
    company: {
      canViewDelete: false,
      canViewEdit: false,
    },
    role: {
      canViewAdd: false,
      canViewDelete: false,
    },
    roleDetail: {
      canViewPage: false,
      canViewGeneralTab: false,
      canViewAttributesTab: false,
      canViewUserTab: false,
      isEditGeneralTab: false,
      isEditAttributesTab: false,
    },
    scope: {
      canViewAdd: false,
      canViewDelete: false,
    },
    scopeDetail: {
      canViewPage: false,
      canViewGeneralTab: false,
      isEditGeneralTab: false,
    },
    resource: {
      canViewAdd: false,
      canViewDelete: false,
    },
    resourceDetail: {
      canViewPage: false,
      canViewGeneralTab: false,
      canViewAttributesTab: false,
      isEditGeneralTab: false,
      isEditAttributesTab: false,
    },
    policy: {
      canViewAdd: false,
      canViewDelete: false,
    },
    policyDetail: {
      canViewPage: false,
      canViewGeneralTab: false,
      isEditGeneralTab: false,
    },
    permission: {
      canViewAdd: false,
      canViewDelete: false,
    },
    permissionDetail: {
      canViewGeneralTab: false,
      canViewPage: false,
      isEditGeneralTab: false,
    },
    event: {
      canViewLogin: false,
      canViewPermission: false,
    },
    environment: {
      canChange: false,
      canDebug: false,
      canView: false,
    },
  },
};

export const PermissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setFeature: (state, action: PayloadAction<ResourceDto[]>) => {
      state.features = mapResourceApplication(action.payload);
    },
    setPermissionPage: (state, action: PayloadAction<ResourceDto[]>) => {
      state.application = getPermissions(action.payload);
    },
  },
});

const getPermissions = (resource: ResourceDto[]): PermissionApplication => {
  return {
    user: mapUserPermission(resource),
    userDetail: mapUserPermissionDetail(resource),
    group: mapGroupPermission(resource),
    groupDetail: mapGroupPermissionDetail(resource),
    company: mapCompanyPermission(resource),
    role: mapRolePermission(resource),
    roleDetail: mapRolePermissionDetail(resource),
    scope: mapScopePermission(resource),
    scopeDetail: mapScopePermissionDetail(resource),
    resource: mapRescourcePermission(resource),
    resourceDetail: mapRescourcePermissionDetail(resource),
    policy: mapPolicyPermission(resource),
    policyDetail: mapPolicyPermissionDetail(resource),
    permission: mapPermission(resource),
    permissionDetail: mapPermissionDetail(resource),
    event: mapEventPermission(resource),
    environment: mapEnvironmentPermission(resource),
  };
};

export const matchPermission = (
  resource: ResourceType[],
  event: string,
  scope: string
): boolean => {
  return resource.some(
    (val) => val.displayName === event && val.scopes?.includes(scope)
  );
};

// Action creators are generated for each case reducer function
export const { setFeature, setPermissionPage } = PermissionSlice.actions;

export default PermissionSlice.reducer;
