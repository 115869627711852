import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  GroupDto,
  GroupPolicy,
  PolicyRepresentationDto,
  PolicyUpdateDto,
} from '@bom-nextgen-keycloak/models';
import {
  DECISION_STRATEGIES,
  POLICY_LOGICS,
  POLICY_TYPES,
  selectPermissionPolicyDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchGroupById,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { GroupTableForm2 } from '@bom-nextgen-keycloak/web/pages/feature-policy';
import { TreeViewUserGroup } from '@bom-nextgen-keycloak/web/pages/feature-user-detail';
import {
  Capitalize,
  DropdownFieldPro,
  FormActionsGroup,
  FormBox,
  FormField,
  FormText,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import { CONFIG } from '../../shared';
import { GroupForm, validationSchema } from './config';
import {
  TreeViewActions,
  TreeViewContainer,
  TreeViewContent,
} from './GeneralGroup.styled';

const FORM_FIELDS = { ...CONFIG.form.fields };

type GeneralGroupType = {
  data: PolicyRepresentationDto | null;
  isUpdating: boolean;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (payload: PolicyUpdateDto) => void;
};

const GeneralGroup: FC<GeneralGroupType> = ({
  data,
  isUpdating,
  loading,
  onCancel,
  onSubmit,
}) => {
  const params = useParams();
  const { type = '' } = params;
  const { groupId } = useResource();
  const { isEditGeneralTab: canEdit } = useSelector(
    selectPermissionPolicyDetail
  );
  const { setAlertMessage } = useAlertMessage();
  const [selectedNode, setSelectedNode] = useState<GroupDto | null>(null);

  const disableField = !canEdit;
  const groups = data?.groups || [];
  const hasGroups = Boolean(groups.length);

  const queryKeys = {
    groupDetail: [QUERY_KEY.GROUP_DETAIL, 'POLICY_GENERAL_GBAC', groups],
  };

  const grouptDetailQuery = useQuery(
    queryKeys.groupDetail,
    () => fetchGroupById(groupId),
    {
      enabled: hasGroups,
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get group detail';

        setAlertMessage({ message, typeStatusMessage: 'error' });
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const getGroupPath = (
    rootGroup: GroupDto | undefined,
    nodeId: string
  ): GroupDto | void => {
    const rootId = rootGroup?.id || '';
    const path = rootGroup?.path || '';
    const subGroups = rootGroup?.subGroups || [];

    if (nodeId === rootId) {
      return { path };
    }

    if (subGroups && subGroups.length) {
      for (const child of subGroups) {
        const result = getGroupPath(child, nodeId);

        if (result) {
          return result;
        }
      }
    }
  };

  const generateGroupOptions = (
    selectedGroups: GroupPolicy[] | undefined,
    groupDetail: GroupDto | undefined
  ): GroupPolicy[] => {
    if (selectedGroups && selectedGroups.length && groupDetail) {
      return selectedGroups.map((item) => ({
        ...item,
        path: getGroupPath(grouptDetailQuery.data, item.id)?.path || '',
      }));
    }

    return [];
  };

  const handleGroupCheck = (
    selectedGroups: GroupPolicy[],
    node: GroupDto | null
  ) => {
    const selectedGroupIds = selectedGroups.map((item) => item.id || '');
    const isSelected = selectedGroupIds.includes(node?.id || '');

    if (node && !isSelected) {
      setSelectedNode(node);
    } else {
      setSelectedNode(null);
    }
  };

  const handleGroupSelect = (
    groups: GroupPolicy[],
    setFieldValue: (field: string, value: GroupPolicy[]) => void
  ) => {
    if (selectedNode) {
      setFieldValue(FORM_FIELDS.groups.name, [
        ...groups,
        {
          extendChildren: false,
          id: selectedNode.id || '',
          path: selectedNode.path || '',
        },
      ]);
      setSelectedNode(null);
    }
  };

  const handleExtendChildrenChange = (
    checked: boolean,
    groupId: string,
    groupList: GroupPolicy[],
    setFieldValue: (field: string, value: GroupPolicy[]) => void
  ) => {
    const output = groupList.map((item) =>
      item.id === groupId
        ? {
            ...item,
            extendChildren: checked,
          }
        : item
    );

    setFieldValue(FORM_FIELDS.groups.name, output);
  };

  const handleGroupRemove = (
    groupItem: GroupPolicy,
    groupList: GroupPolicy[],
    setFieldValue: (field: string, value: GroupPolicy[]) => void
  ) => {
    if (groupItem) {
      const output = groupList.filter((item) => item.id !== groupItem.id);

      setFieldValue(FORM_FIELDS.groups.name, output);
    }
  };

  const handleSubmit = (value: PolicyUpdateDto) => {
    onSubmit(value);
  };

  const initialValues: GroupForm = {
    description: data?.description || '',
    groupClaim: data?.groupsClaim || '',
    groups: generateGroupOptions(groups, grouptDetailQuery.data),
    logic: data?.logic || '',
    name: data?.name || '',
    type: POLICY_TYPES.group,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(
        payload: GroupForm,
        { setSubmitting }: FormikHelpers<GroupForm>
      ) => {
        const requestPayload: PolicyUpdateDto = {
          decisionStrategy: DECISION_STRATEGIES.unanimous.value,
          description: payload.description,
          groupsClaim: payload.groupClaim,
          groups: payload.groups,
          id: data?.id || 'undefined',
          logic: payload.logic,
          name: payload.name,
          type: payload.type,
        };

        handleSubmit(requestPayload);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, resetForm, setFieldValue, values }) => {
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: loading,
          isValid,
        });
        const { groups } = values;
        const showTreeView =
          !grouptDetailQuery.isLoading && grouptDetailQuery.data;
        const showSelectedList = groups && groups.length !== 0;
        const fieldProps = { disabled: disableField };

        return (
          <Form>
            <FormBox>
              <FormField>
                <InputLabel shrink>{FORM_FIELDS.type.label}</InputLabel>
                <FormText>
                  <Capitalize>{type}</Capitalize>-based
                </FormText>
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.name.label}
                  name={FORM_FIELDS.name.name}
                  required
                />
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.description.label}
                  multiline
                  name={FORM_FIELDS.description.name}
                />
              </FormField>

              <FormField>
                <InputLabel shrink>Groups *</InputLabel>
                {showTreeView ? (
                  <TreeViewContainer>
                    <TreeViewContent>
                      <TreeViewUserGroup
                        groupDetail={grouptDetailQuery.data || {}}
                        isDisabled={disableField}
                        memberIds={groups.map((item) => item.id || '')}
                        onNodeSelected={(node: GroupDto | null) =>
                          handleGroupCheck(groups, node)
                        }
                      />
                    </TreeViewContent>
                    <TreeViewActions>
                      <Button
                        disabled={!selectedNode}
                        onClick={() => handleGroupSelect(groups, setFieldValue)}
                        variant="outlined"
                      >
                        Select
                      </Button>
                    </TreeViewActions>
                  </TreeViewContainer>
                ) : (
                  <FormText>Loading...</FormText>
                )}
              </FormField>

              {showSelectedList && (
                <FormField>
                  <GroupTableForm2
                    data={groups}
                    disabled={disableField}
                    onRemove={(item: GroupPolicy) =>
                      handleGroupRemove(item, groups, setFieldValue)
                    }
                    onSelect={(checked: boolean, id: string) =>
                      handleExtendChildrenChange(
                        checked,
                        id,
                        groups,
                        setFieldValue
                      )
                    }
                  />
                </FormField>
              )}

              <FormField>
                <DropdownFieldPro
                  {...fieldProps}
                  label={FORM_FIELDS.logic.label}
                  name={FORM_FIELDS.logic.name}
                  options={POLICY_LOGICS}
                  placeholder={FORM_FIELDS.logic.placeholder}
                  required
                />
              </FormField>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={isUpdating}
              onCancel={onCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export { GeneralGroup };
