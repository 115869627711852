import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const Content = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Indicator = styled('div')`
  height: 41px;
`;

export const Title = styled(Typography)`
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1;
  margin: 2rem 0 0 0;
`;

export const Wrapper = styled(Backdrop)`
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  z-index: ${({ theme }) => theme.zIndex.appBar + 1};
`;
