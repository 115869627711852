import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import {
  AutoCompleteOption,
  StyledTableCellHead,
  TableFormFooter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { TableContainer } from './RoleTableForm2.styled';

type RoleTableFormProps = {
  clientName: string;
  data: AutoCompleteOption[];
  disabled?: boolean;
  onRemove: (item: AutoCompleteOption) => void;
  onSelect: (checked: boolean, id: string) => void;
};

const RoleTableForm2: FC<RoleTableFormProps> = ({
  clientName,
  data,
  disabled = false,
  onRemove,
  onSelect,
}) => {
  const totalRecord = data.length;

  return (
    <Paper>
      <TableContainer>
        <Table aria-label="role table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCellHead>Role name</StyledTableCellHead>
              <StyledTableCellHead>Resource server</StyledTableCellHead>
              <StyledTableCellHead align="center" sx={{ width: '85px' }}>
                Required
              </StyledTableCellHead>
              <StyledTableCellHead align="center" sx={{ width: '85px' }}>
                Actions
              </StyledTableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              const { value } = item;
              const { id, name, required } = value;

              return (
                <TableRow key={id}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{clientName}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={required}
                      disabled={disabled}
                      onChange={(event) => onSelect(event.target.checked, id)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Remove role">
                      <IconButton
                        aria-label="delete"
                        onClick={() => onRemove(item)}
                        size="small"
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFormFooter>
        <b>{`${totalRecord} role${totalRecord > 1 ? 's' : ''}`}</b> selected
      </TableFormFooter>
    </Paper>
  );
};

export { RoleTableForm2 };
