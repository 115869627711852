import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { ADMIN_EMAIL, ADMIN_NAME } from '@bom-nextgen-keycloak/web/core';
import { Content, Heading, Wrapper } from './Contacts.styled';

const Contacts: FC = () => (
  <Wrapper>
    <Content>
      <Heading>Administrator</Heading>
      <Typography>{ADMIN_NAME}</Typography>
      <Typography>{ADMIN_EMAIL}</Typography>
    </Content>
  </Wrapper>
);

export { Contacts };
