import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns-tz';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { IEventDto } from '@bom-nextgen-keycloak/models';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  Loading,
  NotFound2,
  PaginationPro,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';

type LoginEventTableSortKey = Pick<
  IEventDto,
  'error' | 'ipAddress' | 'time' | 'username'
>;

type LoginEventTableProps = {
  data: IEventDto[];
  isFetched?: boolean;
  isFetching?: boolean;
  isFilter?: boolean;
  isLoading?: boolean;
  isProcessing?: boolean;
  isSearch?: boolean;
  isSearching?: boolean;
  onPageChange?: (offset: number) => void;
  onStepChange?: (event: SelectChangeEvent<unknown>) => void;
  paginationOffset?: number;
  paginationStep?: number;
  totalCount?: number;
  totalRecord: number;
  wasSearch?: boolean;
};

const headCells: HeadCell<keyof LoginEventTableSortKey>[] = [
  {
    disablePadding: false,
    id: 'time',
    label: 'Time',
    numeric: false,
    sortKey: 'time',
  },
  {
    disablePadding: false,
    id: 'username',
    label: 'Logged in as',
    numeric: false,
    sortKey: 'username',
  },
  {
    disablePadding: false,
    id: 'ipAddress',
    label: 'IP address',
    numeric: false,
    sortKey: 'ipAddress',
  },
  {
    disablePadding: false,
    id: 'error',
    label: 'Error',
    numeric: false,
    sortKey: 'error',
  },
];

const LoginEventTable2: FC<LoginEventTableProps> = ({
  data,
  isFetched = false,
  isFetching = true,
  isFilter = false,
  isLoading = true,
  isProcessing = true,
  isSearch = false,
  isSearching = false,
  onPageChange,
  onStepChange,
  paginationOffset = 0,
  paginationStep = 10,
  totalCount = undefined,
  totalRecord = 0,
  wasSearch = false,
}) => {
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] = useState<keyof LoginEventTableSortKey>('time');

  const isEmpty = data.length === 0;
  const columnLength = headCells.length;
  const showSkeleton = isProcessing || wasSearch;
  const showHeader =
    isProcessing || wasSearch || (!isProcessing && totalRecord > 0);

  const handleSort = (
    event: MouseEvent<unknown>,
    property: keyof LoginEventTableSortKey
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      {isSearching ? (
        <Loading title="Searching..." />
      ) : (
        <Fragment>
          <TableContainer>
            <Table
              aria-label="login event table"
              aria-labelledby="tableTitle"
              stickyHeader
            >
              {showHeader && (
                <EnhancedTableHead
                  headCells={headCells}
                  onRequestSort={handleSort}
                  order={order}
                  orderBy={orderBy}
                />
              )}
              <TableBody>
                <TableRowSkeleton
                  cellLength={columnLength}
                  loading={showSkeleton}
                >
                  {!isEmpty ? (
                    stableSort<IEventDto>(
                      data,
                      getComparator(order, orderBy)
                    ).map((row, index) => {
                      const { error, ipAddress, time, username } = row;

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {format(new Date(time), 'MM/dd/yyyy HH:mm:ss', {
                              timeZone: 'Asia/Bangkok',
                            }) || '-'}
                          </TableCell>
                          <TableCell title={username}>
                            <StyledTextEllipsis>
                              {username || '-'}
                            </StyledTextEllipsis>
                          </TableCell>
                          <TableCell>{ipAddress || '-'}</TableCell>
                          <TableCell>{error || '-'}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columnLength}>
                        <NotFound2 />
                      </TableCell>
                    </TableRow>
                  )}
                </TableRowSkeleton>
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationPro
            isFetched={isFetched}
            isFetching={isFetching}
            isFilter={isFilter}
            isLoading={isLoading}
            isProcessing={isProcessing}
            isSearch={isSearch}
            isSearching={isSearching}
            onPageChange={onPageChange}
            onStepChange={onStepChange}
            paginationOffset={paginationOffset}
            paginationStep={paginationStep}
            totalCount={totalCount}
            totalRecord={totalRecord}
            wasSearch={wasSearch}
          />
        </Fragment>
      )}
    </Paper>
  );
};

export { LoginEventTable2 };
