import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ResourceApplication } from '@bom-nextgen-keycloak/web/shared/data-access';
import { Dialog, Radio } from './Workspace.styled';

type WorkspaceProps = {
  onChange: (resourceId: string) => void;
  onClose: () => void;
  open: boolean;
  selectedId: string;
  workspaces: ResourceApplication[];
};

const Workspace: FC<WorkspaceProps> = ({
  onChange,
  onClose,
  open,
  selectedId,
  workspaces,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<string>(selectedId);

  const showOpenButton = workspaces.length > 1;
  const isClean = selectedItem === selectedId;

  useEffect(() => {
    setSelectedItem(selectedId);
  }, [selectedId]);

  const handleClose = () => {
    setSelectedItem(selectedId);
    onClose();
  };

  // TODO: Recheck the implementation solution
  const handleChange = () => {
    const isDetailPath = location.pathname?.match(/\//g)?.length;
    const parentPath = location.pathname?.replace(
      /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}.*/g,
      ''
    );

    if (location.pathname && parentPath && isDetailPath && isDetailPath >= 2) {
      onChange(selectedItem);
      onClose();
      navigate(parentPath);
    } else {
      onChange(selectedItem);
      onClose();
    }
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItem((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      aria-describedby="workspace-dialog-description"
      aria-labelledby="workspace-dialog-title"
      onClose={handleClose}
      open={open}
      scroll="paper"
    >
      <DialogTitle id="workspace-dialog-title">Select a project</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            onChange={handleSelect}
            name="workspace-list"
            value={selectedItem}
          >
            {workspaces.map((item, index) => {
              const { displayName, resourceId } = item;
              const isActive = resourceId === selectedId;

              return (
                <FormControlLabel
                  control={<Radio />}
                  disabled={isActive}
                  key={`${index}-${resourceId}`}
                  label={displayName}
                  value={resourceId}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} variant="text">
          Close
        </Button>
        {showOpenButton && (
          <Button
            autoFocus
            disabled={isClean}
            onClick={handleChange}
            variant="text"
          >
            Open
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { Workspace };
