import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  useAlertMessage,
  useNotification,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchRoleById,
  QUERY_KEY,
  updateRoleById,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { PARENT_PATH } from '../shared';

const useRoleDetail2 = (roleId: string) => {
  const defaultData = null;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();

  const queryKeys = [QUERY_KEY.CLIENT_ROLE_DETAIL, roleId];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    () => fetchRoleById(roleId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get role detail';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
        navigate(PARENT_PATH);
      },
      staleTime: 5 * 1000,
    }
  );

  const updateMutation = useMutation(updateRoleById, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot update role';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: () => {
      setNotification({
        message: 'Role updated',
        type: 'success',
      });
      handleReload();
    },
  });

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  return {
    data,
    isLoading,
    updateMutation,
  };
};

export { useRoleDetail2 };
