import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { AxiosError } from 'axios';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  FederatedIdentities,
  IFederatedIdentities,
} from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchUserIdentityProvider,
  HeadCell,
  OrderTable,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  Loading,
  NotFound2,
  PaginationLite,
  ProtectedRouter,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';
import { CONFIG, PARENT_PATH } from '../../shared';

type IdentityProviderProps = {
  isSearching?: boolean;
};

const headCells: HeadCell<keyof FederatedIdentities>[] = [
  {
    disablePadding: false,
    id: 'identityProvider',
    label: 'Identity Provider Alias',
    numeric: false,
    sortKey: 'identityProvider',
  },
  {
    disablePadding: false,
    id: 'userId',
    label: 'Provider User ID',
    numeric: false,
    sortKey: 'userId',
  },
  {
    disablePadding: false,
    id: 'userName',
    label: 'Provider Username',
    numeric: false,
    sortKey: 'userName',
  },
];

const IdentityProvider2: FC<IdentityProviderProps> = ({
  isSearching = false,
}) => {
  const { id = '' } = useParams();
  const { setAlertMessage } = useAlertMessage();
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof IFederatedIdentities>('identityProvider');

  const defaultData: IFederatedIdentities[] = [];
  const queryKeys = [QUERY_KEY.USER_IDENTITY_PROVIDER_LIST, id];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    () => fetchUserIdentityProvider(id),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get IdP links';

        setAlertMessage({ message, typeStatusMessage: 'error' });
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const handleSort = (
    event: MouseEvent<unknown>,
    property: keyof FederatedIdentities
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isEmpty = data.length === 0;
  const columnLength = headCells.length;
  const showSkeleton = isLoading;
  const showHeader = !isSearching;

  return (
    <Paper>
      {isSearching ? (
        <Loading title="Searching..." />
      ) : (
        <Fragment>
          <TableContainer>
            <Table
              aria-label="identity provider link table"
              aria-labelledby="tableTitle"
              stickyHeader
            >
              {showHeader && (
                <EnhancedTableHead
                  headCells={headCells}
                  onRequestSort={handleSort}
                  order={order}
                  orderBy={orderBy}
                />
              )}
              <TableBody>
                <TableRowSkeleton
                  cellLength={columnLength}
                  loading={showSkeleton}
                >
                  {!isEmpty ? (
                    stableSort<IFederatedIdentities>(
                      data,
                      getComparator(order, orderBy)
                    ).map((row) => {
                      const { identityProvider, userId, userName } = row;

                      return (
                        <TableRow key={`${userId}-${identityProvider}`}>
                          <TableCell>{identityProvider}</TableCell>
                          <TableCell>{userId}</TableCell>
                          <TableCell>{userName}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columnLength}>
                        <NotFound2 />
                      </TableCell>
                    </TableRow>
                  )}
                </TableRowSkeleton>
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationLite loading={isLoading} />
        </Fragment>
      )}
    </Paper>
  );
};

const WrappedUserIdpPage: FC = () => {
  const { canViewIdpTab } = useSelector(selectPermissionUserDetail);

  return (
    <ProtectedRouter
      canView={canViewIdpTab}
      feature={FeaturePage.USER}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <IdentityProvider2 />
    </ProtectedRouter>
  );
};

export { WrappedUserIdpPage };
