import { grey } from '@mui/material/colors';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import MuiTableContainer from '@mui/material/TableContainer';

// TODO: Share the same component with PaginationPro.Wrapper
export const Footer = styled('div')`
  align-items: center;
  background-color: ${grey[50]};
  border-radius: 0 0 4px 4px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  font-size: 0.8125rem;
  justify-content: space-between;
  padding: 5px 1rem;
  position: relative;
`;

export const Input = styled(MuiInput)`
  width: 100%;
`;

export const TableContainer = styled(MuiTableContainer)`
  max-height: 492px;
  margin-bottom: 1rem;
`;
