import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns-tz';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { LoggingDto } from '@bom-nextgen-keycloak/models';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  Loading,
  NotFound2,
  PaginationPro,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';

type PermissionEventTableSortKey = Pick<
  LoggingDto,
  'createdAt' | 'email' | 'id' | 'status' | 'summary' | 'type' | 'username'
>;

type PermissionEventTableProps = {
  data: LoggingDto[];
  isFetched?: boolean;
  isFetching?: boolean;
  isFilter?: boolean;
  isLoading?: boolean;
  isProcessing?: boolean;
  isSearch?: boolean;
  isSearching?: boolean;
  onPageChange?: (offset: number) => void;
  onStepChange?: (event: SelectChangeEvent<unknown>) => void;
  paginationOffset?: number;
  paginationStep?: number;
  totalCount?: number;
  totalRecord: number;
  wasSearch?: boolean;
};

const headCells: HeadCell<keyof PermissionEventTableSortKey>[] = [
  {
    disablePadding: false,
    id: 'status',
    label: 'Severity',
    numeric: false,
    sortKey: 'status',
  },
  {
    disablePadding: false,
    id: 'createdAt',
    label: 'Time',
    numeric: false,
    sortKey: 'createdAt',
  },
  {
    disablePadding: false,
    id: 'username',
    label: 'Username',
    numeric: false,
    sortKey: 'username',
  },
  {
    disablePadding: false,
    id: 'email',
    label: 'Email',
    numeric: false,
    sortKey: 'email',
  },
  {
    disablePadding: false,
    id: 'type',
    label: 'Type',
    numeric: false,
    sortKey: 'type',
  },
  {
    disablePadding: false,
    id: 'summary',
    label: 'Summary',
    numeric: false,
    sortKey: 'summary',
  },
  {
    disablePadding: false,
    id: 'id',
    label: 'Actions',
    numeric: false,
    sortKey: 'id',
  },
];

const PermissionEventTable2: FC<PermissionEventTableProps> = ({
  data,
  isFetched = false,
  isFetching = true,
  isFilter = false,
  isLoading = true,
  isProcessing = true,
  isSearch = false,
  isSearching = false,
  onPageChange,
  onStepChange,
  paginationOffset = 0,
  paginationStep = 10,
  totalCount = undefined,
  totalRecord = 0,
  wasSearch = false,
}) => {
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof PermissionEventTableSortKey>('createdAt');

  const isEmpty = data.length === 0;
  const columnLength = headCells.length;
  const showSkeleton = isProcessing || wasSearch;
  const showHeader =
    isProcessing || wasSearch || (!isProcessing && totalRecord > 0);

  const handleSort = (
    event: MouseEvent<unknown>,
    property: keyof PermissionEventTableSortKey
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <Paper>
      {isSearching ? (
        <Loading title="Searching..." />
      ) : (
        <Fragment>
          <TableContainer>
            <Table
              aria-label="permission event table"
              aria-labelledby="tableTitle"
              stickyHeader
            >
              {showHeader && (
                <EnhancedTableHead
                  headCells={headCells}
                  onRequestSort={handleSort}
                  order={order}
                  orderBy={orderBy}
                />
              )}
              <TableBody>
                <TableRowSkeleton
                  cellLength={columnLength}
                  loading={showSkeleton}
                >
                  {!isEmpty ? (
                    stableSort<LoggingDto>(
                      data,
                      getComparator(order, orderBy)
                    ).map((row) => {
                      const {
                        createdAt,
                        email,
                        id,
                        status,
                        summary,
                        type,
                        username,
                      } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell title={capitalizeFirstLetter(status)}>
                            {status === 'success' ? (
                              <CheckIcon color="success" fontSize="small" />
                            ) : (
                              <ReportProblemIcon
                                color="error"
                                fontSize="small"
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {format(
                              new Date(createdAt),
                              'MM/dd/yyyy HH:mm:ss',
                              { timeZone: 'Asia/Bangkok' }
                            ) || '-'}
                          </TableCell>
                          <TableCell title={username}>
                            {username || '-'}
                          </TableCell>
                          <TableCell title={email}>{email || '-'}</TableCell>
                          <TableCell>
                            <Typography
                              textTransform="capitalize"
                              variant="body2"
                            >
                              {type || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell title={summary}>
                            <StyledTextEllipsis>
                              {summary || '-'}
                            </StyledTextEllipsis>
                          </TableCell>
                          <TableCell title={summary}>
                            <Link to={`/events/permission/${id}`}>
                              <Tooltip title="View event details">
                                <IconButton aria-label="view" size="small">
                                  <InfoIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columnLength}>
                        <NotFound2 />
                      </TableCell>
                    </TableRow>
                  )}
                </TableRowSkeleton>
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationPro
            isFetched={isFetched}
            isFetching={isFetching}
            isFilter={isFilter}
            isLoading={isLoading}
            isProcessing={isProcessing}
            isSearch={isSearch}
            isSearching={isSearching}
            onPageChange={onPageChange}
            onStepChange={onStepChange}
            paginationOffset={paginationOffset}
            paginationStep={paginationStep}
            totalCount={totalCount}
            totalRecord={totalRecord}
            wasSearch={wasSearch}
          />
        </Fragment>
      )}
    </Paper>
  );
};

export { PermissionEventTable2 };
