export const CREDENTIAL_TYPES = {
  password: 'password',
};

export const DECISION_STRATEGIES = {
  affirmative: {
    label: 'Affirmative',
    value: 'AFFIRMATIVE',
  },
  consensus: {
    label: 'Consensus',
    value: 'CONSENSUS',
  },
  unanimous: {
    label: 'Unanimous',
    value: 'UNANIMOUS',
  },
};

export const PERMISSION_TYPES = {
  resource: 'resource',
  scope: 'scope',
};

export const POLICY_LOGICS = {
  negative: {
    label: 'Negative',
    value: 'NEGATIVE',
  },
  positive: {
    label: 'Positive',
    value: 'POSITIVE',
  },
};

export const POLICY_TYPES = {
  client: 'client',
  group: 'group',
  role: 'role',
  user: 'user',
};
