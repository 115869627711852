import {
  GroupDto,
  GroupPolicy,
  PolicyCreateDto,
} from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { TreeViewUserGroup } from '@bom-nextgen-keycloak/web/pages/feature-user-detail';
import {
  createPolicy,
  ErrorMessage,
  fetchGroupById,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ButtonRounded,
  DropdownField,
  StyledDialogTitle,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';
import { CircularProgress, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';
import {
  ContainerGroup,
  LoaddingContainer,
  StyledDialog,
  StyledLabel,
} from './GroupFormDialog.styled';
import { GroupTableForm } from './GroupTableForm';

type GroupFormDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

type GroupForm = {
  name: string;
  description: string;
  type: string;
  logic: string;
  groups: GroupPolicy[];
  groupsClaim: string;
};

const validationSchema = yup.object({
  name: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Name is required'),
  groups: yup.array().min(1),
  logic: yup.string().required('Logic is required'),
});

const initialValues: GroupForm = {
  name: '',
  description: '',
  groups: [],
  logic: 'POSITIVE',
  type: 'group',
  groupsClaim: '',
};

const GroupFormDialog: FC<GroupFormDialogProps> = ({
  onClose,
  onSave,
  open,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const resource = useResource();
  const [selectedNode, setSelectedNode] = useState<GroupDto | null>(null);

  const { isLoading, data: groupDetail = {} } = useQuery(
    [QUERY_KEY.GROUP_DETAIL],
    () => fetchGroupById(resource.groupId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get list group and group';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
      },
    }
  );

  const createPolicyMutation = useMutation(createPolicy, {
    onSuccess: () => {
      setNotification({
        message: 'Group-based policy created',
        type: 'success',
      });
      onSave();
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot create policy';
      setAlertMessage({
        message:
          error.response?.status === 409
            ? 'Group name already exists. try another name'
            : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleNodeSelected = (groups: GroupPolicy[], node: GroupDto | null) => {
    const groupIds = groups.map((val) => val.id || '');
    const active = groupIds.includes(node?.id || '');
    if (node && !active) {
      setSelectedNode(node);
    } else {
      setSelectedNode(null);
    }
  };

  const handleClickSelected = (
    groups: GroupPolicy[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (selectedNode) {
      setFieldValue('groups', [
        { id: selectedNode.id, path: selectedNode.path },
        ...groups,
      ]);
      setSelectedNode(null);
    }
  };

  const handleChange = (
    checked: boolean,
    groupId: string | undefined,
    setFieldValue: (field: string, value: any) => void,
    groups: GroupPolicy[]
  ) => {
    const newGroup = groups.map((val) =>
      val?.id === groupId ? { ...val, extendChildren: checked } : val
    );
    setFieldValue('groups', newGroup);
  };

  const handleDelateGroup = (
    data: GroupPolicy,
    setFieldValue: (field: string, value: any) => void,
    groups: GroupPolicy[]
  ) => {
    if (data) {
      const newGroups = groups.filter((val) => val.id !== data.id);
      setFieldValue('groups', newGroups);
    }
  };

  return (
    <StyledDialog
      onClose={() => onClose()}
      aria-labelledby="dialog-title"
      open={open}
    >
      <StyledDialogTitle id="dialog-title">
        Add group-based policy
      </StyledDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: GroupForm,
          { setSubmitting }: FormikHelpers<GroupForm>
        ) => {
          const payload: PolicyCreateDto = {
            logic: values.logic,
            name: values.name,
            description: values.description,
            type: values.type,
            decisionStrategy: 'UNANIMOUS',
            groupsClaim: values.groupsClaim,
            groups: values.groups,
          };
          createPolicyMutation.mutate({ clientId: resource.clientId, payload });
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
          values,
          getFieldMeta,
        }) => (
          <Form>
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <TextField name="name" label="Name" required />
              </Box>

              <Box sx={{ mb: 2 }}>
                <TextField name="description" label="Description" multiline />
              </Box>

              <Box sx={{ mb: 2 }}>
                <TextField name="groupsClaim" label="Group claim" />
              </Box>

              <Box sx={{ mb: 2 }}>
                {isLoading ? (
                  <LoaddingContainer>
                    <CircularProgress />
                  </LoaddingContainer>
                ) : (
                  <ContainerGroup>
                    <StyledLabel>Groups *</StyledLabel>
                    <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
                      <TreeViewUserGroup
                        groupDetail={groupDetail}
                        memberIds={values.groups.map((val) => val.id || '')}
                        onNodeSelected={(node: GroupDto | null) =>
                          handleNodeSelected(values.groups, node)
                        }
                      />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <ButtonRounded
                        disabled={!selectedNode}
                        variant="contained"
                        onClick={() =>
                          handleClickSelected(values.groups, setFieldValue)
                        }
                      >
                        Select
                      </ButtonRounded>
                    </Box>
                  </ContainerGroup>
                )}
              </Box>

              {values.groups.length !== 0 && (
                <Box sx={{ mb: 2 }}>
                  <GroupTableForm
                    data={values.groups}
                    onChangeCheckBox={(
                      checked: boolean,
                      groupId: string | undefined
                    ) =>
                      handleChange(
                        checked,
                        groupId,
                        setFieldValue,
                        values.groups
                      )
                    }
                    onClickDelate={(data) =>
                      handleDelateGroup(data, setFieldValue, values.groups)
                    }
                  />
                </Box>
              )}

              <Box sx={{ mb: 2 }}>
                <DropdownField
                  name="logic"
                  label="Logic"
                  placeholder="please enter your logic"
                  required
                  variant="standard"
                >
                  <MenuItem value="POSITIVE">Positive</MenuItem>
                  <MenuItem value="NEGATIVE">Negative</MenuItem>
                </DropdownField>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                color="secondary"
                onClick={() => onClose()}
                variant="text"
              >
                Cancel
              </Button>
              <LoadingButton
                disabled={!(isValid && dirty) || isSubmitting}
                loading={createPolicyMutation.isLoading}
                type="submit"
                variant="text"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export { GroupFormDialog };
