import { UserRegisterDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUser,
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  createUser,
  ErrorMessage,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  // ButtonRounded,
  PasswordTextField,
  StyledDialogTitle,
  SwitchToggleField,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  EMAIL_REGEX,
  ENGLISH_REGEX,
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
  NO_SPACE_REGEX,
  USERNAME_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';
// import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { StyledDialog } from './UserFormDialog.styled';

type UserFormDialogProps = {
  open: boolean;
  email: string;
  onClose: () => void;
  onSaveUser: () => void;
};

type TypeUserForm = UserRegisterDto & {
  isIdentityProvider: boolean;
};

const validForm = {
  username: yup
    .string()
    .min(5, 'Username should be of minimum 5 characters length')
    .max(15, 'Username should be of maximum 15 characters length')
    .matches(NO_SPACE_REGEX, 'Not allowed space')
    .matches(USERNAME_REGEX, 'Invalid username')
    .required('Username is required'),
  firstName: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('First name is required'),
  lastName: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Last name is required'),
  email: yup
    .string()
    .email()
    .matches(EMAIL_REGEX, 'Invalid email address')
    .required('Email is required'),
};

const validationSchema = yup.lazy((values) => {
  if (values.isIdentityProvider) {
    return yup.object(validForm);
  } else {
    return yup.object({
      ...validForm,
      password: yup
        .string()
        .matches(NO_SPACE_REGEX, 'Not allowed space')
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password is required'),
    });
  }
});

const UserFormDialog: FC<UserFormDialogProps> = ({
  onClose,
  onSaveUser,
  open,
  email,
}) => {
  const { canViewToggle } = useSelector(selectPermissionUser);
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const resource = useResource();

  const createUserMutation = useMutation(createUser, {
    onSuccess: () => {
      setNotification({
        message: 'User created',
        type: 'success',
      });
      onSaveUser();
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot create user';
      setAlertMessage({
        message:
          error.response?.status === 409
            ? 'Username already exists. try another name'
            : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const initialValues: TypeUserForm = {
    username: '',
    firstName: '',
    lastName: '',
    email: email || '',
    password: '',
    groupId: resource.groupId,
    enabled: true,
    enabledSSO: true,
    isIdentityProvider: false,
  };

  return (
    <StyledDialog
      onClose={() => onClose()}
      aria-labelledby="dialog-title"
      open={open}
    >
      <StyledDialogTitle id="dialog-title">Add user</StyledDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: TypeUserForm,
          { setSubmitting }: FormikHelpers<TypeUserForm>
        ) => {
          const { password, ...valueRest } = values;
          const payload: UserRegisterDto = values.isIdentityProvider
            ? { ...valueRest }
            : values;
          createUserMutation.mutate(payload);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting, isValid, values, dirty, setFieldValue }) => (
          <Form>
            <DialogContent dividers>
              <Box sx={{ mb: 3 }}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let value = e.target.value;
                    value = value.replace(ENGLISH_REGEX, '');
                    setFieldValue('email', value);
                  }}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField
                  name="username"
                  label="Username"
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let value = e.target.value;
                    value = value.replace(ENGLISH_REGEX, '');
                    setFieldValue('username', value);
                  }}
                />
              </Box>
              {!values.isIdentityProvider && (
                <Box sx={{ mb: 3 }}>
                  <PasswordTextField
                    name="password"
                    label="Password"
                    required
                  />
                </Box>
              )}

              <Box sx={{ mb: 3 }}>
                <TextField name="firstName" label="First name" required />
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField name="lastName" label="Last name" required />
              </Box>

              <Box sx={{ mb: 2 }}>
                <SwitchToggleField
                  label="Authenticate with external identity provider"
                  name="isIdentityProvider"
                  checked={values.isIdentityProvider}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <SwitchToggleField
                  label="Status"
                  name="enabled"
                  checked={values.enabled}
                />
              </Box>

              {canViewToggle && (
                <SwitchToggleField
                  label="User enabled (SSO)"
                  name="enabledSSO"
                  checked={values.enabledSSO}
                />
              )}
            </DialogContent>

            <DialogActions>
              <Button color="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!(isValid && dirty) || isSubmitting}
                loading={createUserMutation.isLoading}
                type="submit"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export { UserFormDialog };
