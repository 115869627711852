import { styled } from '@mui/material/styles';

export const Control = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Flexbox = styled('div')<{ width?: number }>`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
`;

export const Group = styled('div')`
  display: flex;
`;

export const Info = styled('div')`
  margin-bottom: 1rem;
`;
