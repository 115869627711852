import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { FC } from 'react';
import { FormActions, ButtonStack } from '../../index';

type FormActionsGroupType = {
  disableCancel: boolean;
  disableReset: boolean;
  disableSave: boolean;
  loading: boolean;
  onCancel: () => void;
  onReset: () => void;
  onSubmit?: () => void;
};

const FormActionsGroup: FC<FormActionsGroupType> = ({
  disableCancel,
  disableReset,
  disableSave,
  loading,
  onCancel,
  onReset,
  onSubmit,
}) => (
  <FormActions>
    <ButtonStack>
      <LoadingButton
        loading={loading}
        disabled={disableSave}
        onClick={onSubmit}
        type={onSubmit ? 'button' : 'submit'}
        variant="contained"
      >
        Save
      </LoadingButton>
      <Button disabled={disableCancel} onClick={onCancel} variant="outlined">
        Cancel
      </Button>
    </ButtonStack>
    <ButtonStack>
      <Button
        color="error"
        disabled={disableReset}
        onClick={onReset}
        variant="contained"
      >
        Reset
      </Button>
    </ButtonStack>
  </FormActions>
);

export { FormActionsGroup };
