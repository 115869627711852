import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const H2 = styled((props: TypographyProps) => (
  <Typography {...props} variant="h2" />
))`
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const H3 = styled((props: TypographyProps) => (
  <Typography {...props} variant="h3" />
))`
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 0;
`;

export const Section = styled('div')`
  margin-bottom: 2rem;
`;
