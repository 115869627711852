const PREFERED_WORKSPACE = 'preferedWorkspace';

export const getPreferedWorkspace = () => {
  return localStorage.getItem(PREFERED_WORKSPACE);
};

export const resetPreferedWorkspace = () => {
  localStorage.removeItem(PREFERED_WORKSPACE);
};

export const setPreferedWorkspace = (workspace: any) => {
  localStorage.setItem(PREFERED_WORKSPACE, JSON.stringify(workspace));
};
