import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { FieldHookConfig, useField } from 'formik';
import { FC } from 'react';

type Option = {
  [key: string]: {
    label: string;
    value: string;
  };
};

const DropdownFieldPro: FC<
  SelectProps & FieldHookConfig<string> & { options: Option }
> = ({ children, label, options, required, ...rest }) => {
  const [field, meta] = useField(rest);

  const mapObjectToArray = (object: Option) => {
    const array = [];

    for (const [key, value] of Object.entries(object)) {
      array.push({
        key,
        label: value.label,
        value: value.value,
      });
    }

    return array;
  };

  return (
    <FormControl fullWidth variant="standard" error={!!meta.error}>
      <InputLabel shrink required={required}>
        {label}
      </InputLabel>
      <Select {...field} {...rest}>
        {options
          ? mapObjectToArray(options).map(({ key, label, value }) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))
          : children}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export { DropdownFieldPro };
