import { FC } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { selectPermissionRoleDetail } from '@bom-nextgen-keycloak/web/core';
import {
  FormBox,
  FormField,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  NO_BEGINNING_SPACE_REGEX,
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';

export const validationSchema = yup.object({
  name: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Name is required'),
});

type RoleFormProps = {
  isEdit?: boolean;
};

const RoleForm: FC<RoleFormProps> = ({ isEdit = false }) => {
  const { isEditGeneralTab } = useSelector(selectPermissionRoleDetail);
  const disableButton = isEdit && !isEditGeneralTab;

  return (
    <FormBox>
      <FormField>
        <TextField
          autoFocus
          disabled={disableButton}
          label="Name"
          name="name"
          required
        />
      </FormField>
      <FormField>
        <TextField
          disabled={disableButton}
          label="Description"
          multiline
          name="description"
        />
      </FormField>
    </FormBox>
  );
};

export { RoleForm };
