import Button from '@mui/material/Button';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DecisionStrategy,
  IPermissionRepresentation,
  Logic,
} from '@bom-nextgen-keycloak/models';
import {
  selectPermissionDetail,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  PermissionDeleteDialog as DeleteDialog,
  usePermission2,
} from '@bom-nextgen-keycloak/web/pages/feature-permission';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ProtectedRouter,
  TabbedContent,
  TabList,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { usePermissionDetail } from './hooks';
import { PARENT_PATH } from './shared';

const PermissionDetail2: FC = () => {
  const { id = '', type = '' } = useParams();
  const navigate = useNavigate();
  const { clientId } = useResource();
  const {
    canDelete,
    isDeleteDialogOpen,
    isDeleting,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
  } = usePermission2({
    callback: () => navigate(PARENT_PATH),
    disableQuery: true,
  });
  const { data, isLoading } = usePermissionDetail(clientId, id, type);
  const { canViewGeneralTab } = useSelector(selectPermissionDetail);
  const parentPath = PARENT_PATH;
  const rootPath = `${parentPath}/${id}/${type}`;
  const showDeleteButton = canDelete;
  const disableDeleteButton = isLoading;

  const tabList: TabList = [
    {
      enabled: canViewGeneralTab,
      label: 'General',
      value: '',
    },
  ];

  const itemRepresentation: IPermissionRepresentation = {
    decisionStrategy: data?.decisionStrategy as DecisionStrategy,
    description: data?.description ?? '',
    id: data?.id ?? '',
    logic: data?.logic as Logic,
    name: data?.name ?? '',
    type: data?.type ?? '',
  };

  const handleDelete = () => {
    handleDeleteRequest(itemRepresentation);
  };

  const renderActions = () => {
    return showDeleteButton ? (
      <Button
        color="error"
        disabled={disableDeleteButton}
        onClick={handleDelete}
        variant="text"
      >
        Delete
      </Button>
    ) : null;
  };

  return (
    <Fragment>
      <TabbedContent
        actions={renderActions()}
        breadcrumb={data?.name}
        feature="Permissions"
        parentPath={parentPath}
        rootPath={rootPath}
        tabList={tabList}
      />
      <DeleteDialog
        loading={isDeleting}
        name={itemRepresentation?.name}
        onClose={handleDeleteCancel}
        onSubmit={handleDeleteAction}
        open={isDeleteDialogOpen}
      />
    </Fragment>
  );
};

const WrappedPermissionDetailPage: FC = () => {
  const { canViewPage } = useSelector(selectPermissionDetail);

  return (
    <ProtectedRouter
      canView={canViewPage}
      feature={FeaturePage.PERMISSION}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <PermissionDetail2 />
    </ProtectedRouter>
  );
};

export { WrappedPermissionDetailPage };
