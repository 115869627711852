import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Searchbox,
  SearchboxEmit,
  SearchButton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { Action, Form, Wrapper } from './AdvancedSearch.styled';

export type AdvancedSearchCriteria = {
  type: string;
};

type ResourceQuery = {
  type?: string;
};

type AdvancedSearchProps = {
  onApply: (data: AdvancedSearchCriteria) => void;
  query: ResourceQuery;
};

const AdvancedSearch: FC<AdvancedSearchProps> = ({ onApply, query }) => {
  const defaultCriteria = useMemo(
    () => ({
      type: '',
    }),
    []
  );

  const initialCriteria = useMemo(
    () => ({
      type: query?.type || defaultCriteria.type,
    }),
    [defaultCriteria, query]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [criteria, setCriteria] =
    useState<AdvancedSearchCriteria>(initialCriteria);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const canAdvancedSearchOpen = isPopoverOpen && Boolean(anchorEl);
  const id = canAdvancedSearchOpen ? 'advanced-search-popover' : undefined;

  useEffect(() => {
    setCriteria(initialCriteria);
  }, [initialCriteria]);

  const handleInputChange = (event: SearchboxEmit) => {
    if (event.name) {
      setCriteria((previous) => ({
        ...previous,
        [event.name || '']: event.value,
      }));
    }
  };

  const handleInputClear = (name: string) => {
    const inputName = name;
    const inputValue = '';

    setCriteria((previous) => ({
      ...previous,
      [inputName]: inputValue,
    }));
  };

  const handleClear = () => {
    setCriteria(defaultCriteria);
  };

  const handleRestore = () => {
    setCriteria(initialCriteria);
  };

  const handleSearch = () => {
    handleClose();
    onApply(criteria);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);

    setCriteria((previous) => ({
      ...previous,
      ...initialCriteria,
    }));
  };

  return (
    <Fragment>
      <SearchButton aria-describedby={id} onClick={handleOpen}>
        Advanced Search
      </SearchButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id={id}
        onClose={handleClose}
        open={isPopoverOpen}
      >
        <Wrapper>
          <Form>
            <Box mb={2}>
              <Searchbox
                autoFocus
                name="type"
                onChange={handleInputChange}
                onClear={() => handleInputClear('type')}
                placeholder="Filter by type"
                value={criteria.type}
                width="auto"
              />
            </Box>
          </Form>
          <Action>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button onClick={handleClose} variant="outlined">
                Close
              </Button>
              <Button onClick={handleRestore} variant="outlined">
                Restore
              </Button>
              <Button onClick={handleClear} variant="outlined">
                Clear
              </Button>
              <Button onClick={handleSearch} variant="contained">
                Apply
              </Button>
            </Stack>
          </Action>
        </Wrapper>
      </Popover>
    </Fragment>
  );
};

export { AdvancedSearch };
