import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { UserResetPasswordDto } from '@bom-nextgen-keycloak/models';
import {
  CREDENTIAL_TYPES,
  useAlertMessage,
  useNotification,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  resetPasswordUser,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  FormBox,
  FormField,
  FormActionsGroup,
  PasswordTextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import { PasswordForm, validationSchema } from '../config';
import { CONFIG } from '../../../shared';
const FORM_FIELDS = { ...CONFIG.form.fields };

type CredentialFormProps = {
  canEdit?: boolean;
  onCancel: () => void;
  onReload: () => void;
  userId: string;
};

const initialValues: PasswordForm = {
  confirmPassword: '',
  newPassword: '',
  temporary: true,
};

const CredentialForm: FC<CredentialFormProps> = ({
  canEdit = false,
  onCancel,
  onReload,
  userId,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const disableField = !canEdit;

  const updateMutation = useMutation(resetPasswordUser, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot set password';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: () => {
      setNotification({
        message: 'Password updated',
        type: 'success',
      });
      onReload();
    },
  });

  const handlePasswordUpdate = (
    payload: PasswordForm,
    successCallback: () => void
  ) => {
    const { newPassword, temporary } = payload;

    const requestPayload: UserResetPasswordDto = {
      temporary,
      type: CREDENTIAL_TYPES.password,
      value: newPassword,
    };

    updateMutation.mutate(
      {
        id: userId,
        payload: requestPayload,
      },
      {
        onSuccess: () => {
          successCallback();
        },
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(
        payload: PasswordForm,
        { resetForm, setSubmitting }: FormikHelpers<PasswordForm>
      ) => {
        handlePasswordUpdate(payload, resetForm);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, resetForm, values }) => {
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: updateMutation.isLoading,
          isValid,
        });
        const fieldProps = { disabled: disableField };

        return (
          <Form>
            <FormBox>
              <FormField>
                <PasswordTextField
                  {...fieldProps}
                  label={FORM_FIELDS.newPassword.label}
                  name={FORM_FIELDS.newPassword.name}
                />
              </FormField>
              <FormField>
                <PasswordTextField
                  {...fieldProps}
                  label={FORM_FIELDS.confirmPassword.label}
                  name={FORM_FIELDS.confirmPassword.name}
                />
              </FormField>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={updateMutation.isLoading}
              onCancel={onCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export { CredentialForm };
