import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectPermissionRoleDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AttributeList,
  FormAttribute,
  FormSpinner,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { useRoleDetail2 } from '../../hooks';
import { PARENT_PATH } from '../../shared';

const Attribute: FC = () => {
  const { id = '' } = useParams();
  const { data, isLoading, updateMutation } = useRoleDetail2(id);
  const { isEditAttributesTab: canEdit } = useSelector(
    selectPermissionRoleDetail
  );
  const disableField = !canEdit;

  const handleUpdate = (payload: AttributeList) => {
    if (data) {
      updateMutation.mutate({
        roleId: id,
        payload: {
          ...data,
          attributes: {
            ...payload,
          },
          id: data.id || '',
          name: data.name || '',
        },
      });
    } else {
      // TODO: Implement a handler
    }
  };

  return isLoading ? (
    <FormSpinner />
  ) : (
    <FormAttribute
      disabled={disableField}
      loading={updateMutation.isLoading}
      onSubmit={handleUpdate}
      parentPath={PARENT_PATH}
      values={data?.attributes || {}}
    />
  );
};

const WrappedRoleAttributePage2: FC = () => {
  const { canViewAttributesTab } = useSelector(selectPermissionRoleDetail);

  return (
    <ProtectedRouter
      canView={canViewAttributesTab}
      feature={FeaturePage.ROLE}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <Attribute />
    </ProtectedRouter>
  );
};

export { WrappedRoleAttributePage2 };
