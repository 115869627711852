import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloudIcon from '@mui/icons-material/Cloud';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FC, Fragment } from 'react';
import { useEnvironment } from '@bom-nextgen-keycloak/web/core';
import { AccountMenu } from '../AccountMenu';
import { Brand, Info, Menu, Navbar, Selector, Wrapper } from './Header.styled';

type HeaderProps = {
  disableWorkspace?: boolean;
  onEnvironmentSelect: () => void;
  onLogout: () => void;
  onWorkspaceSelect: () => void;
  showEnvironment?: boolean;
  showWorkspace?: boolean;
  workspaceName: string;
};

const Header: FC<HeaderProps> = ({
  disableWorkspace = true,
  onEnvironmentSelect,
  onLogout,
  onWorkspaceSelect,
  showEnvironment = false,
  showWorkspace = false,
  workspaceName,
}) => {
  const { title } = useEnvironment();

  const handleEnvironmentSelect = () => {
    onEnvironmentSelect();
  };

  const handleWorkspaceSelect = () => {
    onWorkspaceSelect();
  };

  return (
    <Wrapper>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Brand to="/">
            <Typography component="span">BOM Support Console</Typography>
          </Brand>
          <Navbar>
            <Fragment>
              <Stack direction="row" spacing={2}>
                {showWorkspace && (
                  <Tooltip title={disableWorkspace ? '' : 'Select a project'}>
                    <span>
                      <Selector
                        color="inherit"
                        disabled={disableWorkspace}
                        endIcon={
                          disableWorkspace ? undefined : <ArrowDropDownIcon />
                        }
                        onClick={handleWorkspaceSelect}
                        size="small"
                        startIcon={<WorkspacesIcon />}
                        variant="text"
                      >
                        {workspaceName}
                      </Selector>
                    </span>
                  </Tooltip>
                )}
                {showEnvironment && (
                  <Tooltip title="Select an environment">
                    <span>
                      <Selector
                        color="inherit"
                        endIcon={<ArrowDropDownIcon />}
                        onClick={handleEnvironmentSelect}
                        size="small"
                        startIcon={<CloudIcon />}
                        variant="text"
                      >
                        {title}
                      </Selector>
                    </span>
                  </Tooltip>
                )}
              </Stack>
              <Info></Info>
            </Fragment>
          </Navbar>
          <Menu>
            <AccountMenu onLogout={onLogout} />
          </Menu>
        </Toolbar>
      </AppBar>
    </Wrapper>
  );
};

export { Header };
