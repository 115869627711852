import MuiCard from '@mui/material/Card';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const Card = styled(MuiCard)`
  max-width: 500px;
  width: 500px;

  & .MuiCardHeader-title {
    font-size: 0.8725rem;
  }
`;

export const CardSection = styled('div')`
  margin-bottom: 1.25rem;
`;

export const H3 = styled((props: TypographyProps) => (
  <Typography {...props} variant="h2" />
))`
  font-size: 0.8725rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;
