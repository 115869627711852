import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { format } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { UserUpdateDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  FormBox,
  FormField,
  FormText,
  FormActionsGroup,
  FormSpinner,
  ProtectedRouter,
  SwitchField,
  SwitchGroup,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import { useUserDetail2 } from '../../hooks';
import { CONFIG, PARENT_PATH } from '../../shared';
import { UserForm, validationSchema } from './config';

const FORM_FIELDS = { ...CONFIG.form.fields };

const General: FC = () => {
  const { id = '' } = useParams();
  const { groupId } = useResource();
  const navigate = useNavigate();
  const { data, isLoading, updateMutation } = useUserDetail2(id);
  const attributes = data?.attributes ?? undefined;
  const createdTimestamp = data?.createdTimestamp ?? '';
  const { isEditGeneralTab: canEdit } = useSelector(selectPermissionUserDetail);
  const disableField = !canEdit;
  // TODO: To implement as a helper function
  const isAdmin =
    attributes?.admin &&
    attributes.admin.length === 1 &&
    attributes.admin[0] === 'true'
      ? true
      : false;

  const initialValues: UserForm = {
    createdTimestamp: createdTimestamp
      ? format(new Date(createdTimestamp), 'dd.MM.yyyy | H:mm')
      : '',
    email: data?.email ?? '',
    enabled: data?.enabled ?? false,
    enabledSSO: data?.enabledSSO ?? false,
    firstName: data?.firstName ?? '',
    groupId,
    lastName: data?.lastName ?? '',
    username: data?.username ?? '',
  };

  const handleUpdate = (payload: UserUpdateDto | undefined) => {
    if (payload) {
      updateMutation.mutate({
        id,
        payload: {
          ...payload,
          attributes: data?.attributes,
        },
      });
    } else {
      // TODO: Implement a handler
    }
  };

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  return isLoading ? (
    <FormSpinner />
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(
        payload: UserForm,
        { setSubmitting }: FormikHelpers<UserForm>
      ) => {
        handleUpdate(payload);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, resetForm, values }) => {
        const { createdTimestamp, enabled, enabledSSO } = values;
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: updateMutation.isLoading,
          isValid,
        });
        const fieldProps = { disabled: disableField };

        return (
          <Form>
            <FormBox>
              <FormField>
                <InputLabel shrink>{FORM_FIELDS.id.label}</InputLabel>
                <FormText>{id}</FormText>
              </FormField>
              <FormField>
                <InputLabel shrink>
                  {FORM_FIELDS.createdTimestamp.label}
                </InputLabel>
                <FormText>{createdTimestamp}</FormText>
              </FormField>
              <FormField>
                <TextField
                  disabled={disableField || isAdmin}
                  label={FORM_FIELDS.username.label}
                  name={FORM_FIELDS.username.name}
                />
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.email.label}
                  name={FORM_FIELDS.email.name}
                />
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.firstName.label}
                  name={FORM_FIELDS.firstName.name}
                  required
                />
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.lastName.label}
                  name={FORM_FIELDS.lastName.name}
                  required
                />
              </FormField>
              <SwitchGroup>
                <Stack direction="row" spacing={4}>
                  <SwitchField
                    {...fieldProps}
                    checked={enabled}
                    label={FORM_FIELDS.enabled.label}
                    name={FORM_FIELDS.enabled.name}
                  />
                  <SwitchField
                    {...fieldProps}
                    checked={enabledSSO}
                    label={FORM_FIELDS.enabledSSO.label}
                    name={FORM_FIELDS.enabledSSO.name}
                  />
                </Stack>
              </SwitchGroup>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={updateMutation.isLoading}
              onCancel={handleCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const WrappedUserGeneralPage: FC = () => {
  const { canViewGeneralTab } = useSelector(selectPermissionUserDetail);

  return (
    <ProtectedRouter
      canView={canViewGeneralTab}
      feature={FeaturePage.USER}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <General />
    </ProtectedRouter>
  );
};
export { WrappedUserGeneralPage };
