import FormLabel from '@mui/material/FormLabel';
import { SwitchProps } from '@mui/material/Switch';
import { FieldHookConfig, useField } from 'formik';
import { FC } from 'react';
import { SwitchToggle } from '../SwitchToggle';
import { Wrapper } from './SwitchField.styled';

export type SwitchFieldProps = {
  label: string;
};

const SwitchField: FC<
  SwitchProps & FieldHookConfig<string> & SwitchFieldProps
> = (props) => {
  const [field] = useField(props);
  const {
    checked,
    defaultChecked,
    disabled,
    edge,
    inputProps,
    inputRef,
    label,
    sx,
    value,
  } = props;

  return (
    <Wrapper>
      <FormLabel component="legend">{label}</FormLabel>
      <SwitchToggle
        {...field}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        edge={edge}
        inputProps={inputProps}
        inputRef={inputRef}
        sx={sx}
        value={value}
      />
    </Wrapper>
  );
};

export { SwitchField };
