import {
  POLICY_TYPES,
  selectPermissionPolicyDetail,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  FormSpinner,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { usePolicyDetail } from '../../hooks';
import { PARENT_PATH } from '../../shared';
import {
  GeneralClient,
  GeneralGroup,
  GeneralRole,
  GeneralUser,
} from '../index';

const General: FC = () => {
  const { id = '', type = '' } = useParams();
  const resource = useResource();
  const { clientId } = resource;
  const navigate = useNavigate();
  const { data, handleUpdate, isLoading, isUpdating } = usePolicyDetail(
    clientId,
    id,
    type
  );

  const renderContent = () => {
    switch (type) {
      case POLICY_TYPES.role:
        return (
          <GeneralRole
            data={data}
            isUpdating={isUpdating}
            loading={false}
            onCancel={handleCancel}
            onSubmit={handleUpdate}
          />
        );
      case POLICY_TYPES.client:
        return (
          <GeneralClient
            data={data}
            isUpdating={isUpdating}
            loading={false}
            onCancel={handleCancel}
            onSubmit={handleUpdate}
          />
        );
      case POLICY_TYPES.group:
        return (
          <GeneralGroup
            data={data}
            isUpdating={isUpdating}
            loading={false}
            onCancel={handleCancel}
            onSubmit={handleUpdate}
          />
        );
      case POLICY_TYPES.user:
        return (
          <GeneralUser
            data={data}
            isUpdating={isUpdating}
            loading={false}
            onCancel={handleCancel}
            onSubmit={handleUpdate}
          />
        );
      default:
        return null;
    }
  };

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  return isLoading ? <FormSpinner /> : renderContent();
};

const WrappedPolicyGeneralPage: FC = () => {
  const { canViewGeneralTab } = useSelector(selectPermissionPolicyDetail);

  return (
    <ProtectedRouter
      canView={canViewGeneralTab}
      feature={FeaturePage.POLICY}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <General />
    </ProtectedRouter>
  );
};

export { WrappedPolicyGeneralPage };
