import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useEffect, useState } from 'react';
import { DialogPro } from '../DialogPro';

export type DialogAlertMessageProps = {
  message: string;
  onCloseDialog: () => void;
  typeStatus: string;
};

const DialogAlertMessage: FC<DialogAlertMessageProps> = ({
  message,
  onCloseDialog,
  typeStatus,
}) => {
  const [open, setOpen] = useState(false);

  const isError = typeStatus === 'error';

  const handleClose = () => {
    setOpen(false);
    onCloseDialog();
  };

  useEffect(() => {
    if (message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  return (
    <DialogPro aria-labelledby="alert-dialog" open={open} width={480}>
      <DialogTitle
        sx={{
          color: (theme) => {
            return isError
              ? theme.palette.error.main
              : theme.palette.success.main;
          },
        }}
      >
        {isError ? 'Oops...' : 'Success'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="secondary"
          data-test-id="btn-ok"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </DialogPro>
  );
};

export { DialogAlertMessage };
