import * as yup from 'yup';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';

export const validationSchema = yup.lazy((values) => {
  if (values.level === 2) {
    return yup.object({
      name: yup
        .string()
        .required('Group name is required')
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .matches(
          /^[aA-zZ0-9-_:() ]+$/,
          'Not allowed special characters for this field'
        ),
    });
  }

  return yup.object({
    name: yup
      .string()
      .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
      .required('Group name is required'),
  });
});
