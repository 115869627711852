import { styled } from '@mui/material/styles';

export const Body = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
`;

export const Main = styled('main')`
  display: flex;
  flex: 1 1 1px;
  flex-direction: column;
  margin: 0;
  min-height: 0;
  min-width: 0;
  overflow: visible;
  padding-top: 1.5rem;
  position: relative;
`;

export const Wrapper = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  position: relative;
  width: 100%;
`;
