import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ErrorMessage,
  fetchPermission,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { setAuthorized, setAuthzError, setGenericAuthzError } from '../reducer';

const useAuthorization = (enabled = false) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryKeys = [QUERY_KEY.PERMISSION_AND_USER, 'permission'];

  const { data, isLoading, isSuccess } = useQuery(
    queryKeys,
    () => fetchPermission(),
    {
      enabled,
      onError: (error: AxiosError<ErrorMessage>) => {
        if (error?.response?.data) {
          const { message, statusCode } = error.response.data;

          dispatch(setAuthzError({ message, statusCode }));
        } else {
          dispatch(setGenericAuthzError());
        }

        navigate('/unauthorized');
      },
      onSuccess: () => {
        dispatch(setAuthorized());
      },
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
  };
};

export { useAuthorization };
