import { FC } from 'react';
import { DeleteDialog } from '@bom-nextgen-keycloak/web/shared/ui';

type RoleDeleteDialogProps = {
  loading?: boolean;
  name?: string | null;
  onClose: () => void;
  onSubmit: () => void;
  open?: boolean;
};

const RoleDeleteDialog: FC<RoleDeleteDialogProps> = ({
  loading = false,
  name = 'unknown',
  onClose,
  onSubmit,
  open = false,
}) => (
  <DeleteDialog
    description={`You are about to permanently delete <b>${name}}</b>, its relationships, and all of its data. <b>This operation cannot be undone.</b>`}
    loading={loading}
    onClose={onClose}
    onDelete={onSubmit}
    open={open}
    title="Delete role?"
  />
);

export { RoleDeleteDialog };
