import { AlertColor } from '@mui/material/Alert';
import { createContext, FC, useState } from 'react';

type NotificationMessage = {
  duration?: number;
  message: string;
  title?: string;
  type?: AlertColor;
};

export type NotificationContextProps = {
  notification: NotificationMessage;
  setNotification: (notification: NotificationMessage) => void;
};

export const defaultNotification: NotificationMessage = {
  duration: 5000,
  message: '',
  title: '',
  type: undefined,
};

export const NotificationContext = createContext<NotificationContextProps>({
  notification: defaultNotification,
  setNotification: () => undefined,
});

export const NotificationContextProvider: FC = ({ children }) => {
  const [notification, setNotification] =
    useState<NotificationMessage>(defaultNotification);

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
