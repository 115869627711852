import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker, {
  DateRangePickerProps,
} from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { FC } from 'react';

type DateRangePickerProProps = Omit<
  DateRangePickerProps<Date>,
  'renderInput'
> & {
  endTextHelper?: string;
  startTextHelper?: string;
};

const DateRangePickerPro: FC<DateRangePickerProProps> = ({
  endText,
  endTextHelper,
  onChange,
  startText,
  startTextHelper,
  value,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        {...props}
        endText={endText}
        onChange={onChange}
        renderInput={(startProps, endProps) => (
          <Stack alignItems="center" direction="row">
            <TextField {...startProps} helperText={startTextHelper} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} helperText={endTextHelper} />
          </Stack>
        )}
        startText={startText}
        value={value}
      />
    </LocalizationProvider>
  );
};

export { DateRangePickerPro };
