import { AxiosError } from 'axios';
import { FC, Fragment } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  selectPermissionUserDetail,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchCredentials,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  FormSpinner,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { useUserDetail2 } from '../../hooks';
import { PARENT_PATH } from '../../shared';
import { CredentialForm } from './CredentialForm';
import { CredentialTable } from './CredentialTable';
import { H2, Section } from './Credential2.styled';

const Credential2: FC = () => {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertMessage } = useAlertMessage();
  const { isLoading } = useUserDetail2(id);
  const { isEditPasswordTab: canEdit } = useSelector(
    selectPermissionUserDetail
  );
  const queryKeys = [QUERY_KEY.USER_CREDENTIALS, id];

  const credentialQuery = useQuery(queryKeys, () => fetchCredentials(id), {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot get credentials';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
      navigate(PARENT_PATH);
    },
  });

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  const hasCredentials = credentialQuery.data && credentialQuery.data.length;
  const isInitializing = isLoading || credentialQuery.isLoading;
  const formTitle = hasCredentials ? 'Reset password' : 'Set password';

  return isInitializing ? (
    <FormSpinner />
  ) : (
    <Fragment>
      <Section>
        <H2>Manage passwords</H2>
        <CredentialTable
          data={credentialQuery.data || []}
          isLoading={credentialQuery.isLoading}
          onReload={handleReload}
          userId={id}
        />
      </Section>
      <Section>
        <H2>{formTitle}</H2>
        <CredentialForm
          canEdit={canEdit}
          onCancel={handleCancel}
          onReload={handleReload}
          userId={id}
        />
      </Section>
    </Fragment>
  );
};

const WrappedUserCredentialPage: FC = () => {
  const { canViewPasswordTab } = useSelector(selectPermissionUserDetail);

  return (
    <ProtectedRouter
      canView={canViewPasswordTab}
      feature={FeaturePage.USER}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <Credential2 />
    </ProtectedRouter>
  );
};
export { WrappedUserCredentialPage };
