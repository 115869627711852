import Typography from '@mui/material/Typography';
import { FC } from 'react';

type FormSpinnerType = {
  title?: string;
};

const FormSpinner: FC<FormSpinnerType> = ({ title = 'Loading...' }) => (
  <Typography>{title}</Typography>
);

export { FormSpinner };
