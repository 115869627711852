import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const Content = styled('div')`
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const H2 = styled((props: TypographyProps) => (
  <Typography {...props} variant="h2" />
))`
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const Section = styled('div')`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem 3rem 1rem 3rem;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 1.5rem;
  }
`;
