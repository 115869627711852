import {
  ClientRoleQueryDto,
  PolicyCreateDto,
} from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  createPolicy,
  ErrorMessage,
  fetchClientRole,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  DropdownField,
  StyledDialogTitle,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';
import {
  CircularProgress,
  MenuItem,
  TextField as MatTextField,
} from '@mui/material';
import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, Fragment, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';
import { RoleTableForm } from '../RoleTableForm';
import { StyledDialog } from './PolicyFormDialog.styled';

type PolicyFormDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

type PolicyForm = {
  name: string;
  description: string;
  type: string;
  logic: string;
  roles: AutoCompleteOption[];
};

const validationSchema = yup.object({
  name: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Name is required'),
  roles: yup.array().min(1, 'Roles is required'),
  logic: yup.string().required('Logic is required'),
});

const initialValues: PolicyForm = {
  name: '',
  description: '',
  roles: [],
  logic: 'POSITIVE',
  type: 'role',
};

const PolicyFormDialog: FC<PolicyFormDialogProps> = ({
  onClose,
  onSave,
  open,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const resource = useResource();

  const [params] = useState<ClientRoleQueryDto>({
    search: '',
    first: 0,
    max: 25,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQueryTerm = useDebounce(searchQuery, 700);

  const { isLoading, data: optionRoles = [] } = useQuery(
    [QUERY_KEY.CLIENT_ROLE_LIST, params, debouncedQueryTerm],
    async () => {
      const roles = await fetchClientRole(resource.clientId, {
        ...params,
        search: searchQuery,
      });
      return roles.items.map((role) => ({
        label: role.name,
        value: { ...role, required: false },
      }));
    }
  );

  const onInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setSearchQuery(value);
  };

  const createPolicyMutation = useMutation(createPolicy, {
    onSuccess: () => {
      setNotification({
        message: 'Role-based policy created',
        type: 'success',
      });
      onSave();
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot create policy';
      setAlertMessage({
        message:
          error.response?.status === 409
            ? 'Role name already exists. try another name'
            : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleChange = (
    checked: boolean,
    roleId: string,
    setFieldValue: (field: string, value: any) => void,
    roles: AutoCompleteOption[]
  ) => {
    const newRole = roles.map((val) =>
      val.value.id === roleId
        ? { ...val, value: { ...val.value, required: checked } }
        : val
    );
    setFieldValue('roles', newRole);
  };

  const handleDelateRole = (
    data: AutoCompleteOption,
    setFieldValue: (field: string, value: any) => void,
    roles: AutoCompleteOption[]
  ) => {
    if (data) {
      const newRole = roles.filter((val) => val.value.id !== data.value.id);
      setFieldValue('roles', newRole);
    }
  };

  return (
    <StyledDialog
      onClose={() => onClose()}
      aria-labelledby="dialog-title"
      open={open}
    >
      <StyledDialogTitle id="dialog-title">
        Add role-based policy
      </StyledDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: PolicyForm,
          { setSubmitting }: FormikHelpers<PolicyForm>
        ) => {
          const payload: PolicyCreateDto = {
            decisionStrategy: 'UNANIMOUS',
            logic: values.logic,
            name: values.name,
            description: values.description,
            type: values.type,
            roles: values.roles.map((val) => ({
              id: val.value.id,
              required: val.value.required,
            })),
          };
          createPolicyMutation.mutate({ clientId: resource.clientId, payload });
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
          values,
          getFieldMeta,
          setFieldTouched,
        }) => (
          <Form>
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <TextField name="name" label="Name" required />
              </Box>

              <Box sx={{ mb: 2 }}>
                <TextField name="description" label="Description" multiline />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Autocomplete
                  id="roles"
                  filterSelectedOptions
                  options={optionRoles}
                  multiple
                  getOptionLabel={(option) => option.label}
                  limitTags={3}
                  value={values.roles}
                  onChange={(e, value) => {
                    setFieldValue('roles', value || []);
                  }}
                  onInputChange={onInputChange}
                  loading={isLoading}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  renderInput={(inputParams) => (
                    <MatTextField
                      {...inputParams}
                      id="roles"
                      name="roles"
                      label="Roles"
                      fullWidth
                      placeholder="Enter role name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      required={values.roles.length === 0}
                      onBlur={() => {
                        setFieldTouched('roles');
                      }}
                      error={
                        getFieldMeta('roles').touched &&
                        !!getFieldMeta('roles').error
                      }
                      helperText={
                        getFieldMeta('roles').touched &&
                        getFieldMeta('roles').error
                      }
                      InputProps={{
                        ...inputParams.InputProps,
                        endAdornment: (
                          <Fragment>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {inputParams.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              {values.roles.length !== 0 && (
                <Box sx={{ mb: 2 }}>
                  <RoleTableForm
                    data={values.roles}
                    clientName={resource.clientName}
                    onChangeCheckBox={(checked: boolean, roleId: string) =>
                      handleChange(checked, roleId, setFieldValue, values.roles)
                    }
                    onClickDelate={(data) =>
                      handleDelateRole(data, setFieldValue, values.roles)
                    }
                  />
                </Box>
              )}

              <Box sx={{ mb: 2 }}>
                <DropdownField
                  name="logic"
                  label="Logic"
                  placeholder="please enter your logic"
                  required
                  variant="standard"
                >
                  <MenuItem value="POSITIVE">Positive</MenuItem>
                  <MenuItem value="NEGATIVE">Negative</MenuItem>
                </DropdownField>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                color="secondary"
                onClick={() => onClose()}
                variant="text"
              >
                Cancel
              </Button>
              <LoadingButton
                disabled={!(isValid && dirty) || isSubmitting}
                loading={createPolicyMutation.isLoading}
                type="submit"
                variant="text"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export { PolicyFormDialog };
