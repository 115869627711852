import { styled } from '@mui/material/styles';

export const Action = styled('div')``;

export const Form = styled('div')``;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
