import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FC } from 'react';
import { Control, Flexbox, Group, Info } from './Toolbar.styled';

type Component = FC & {
  Action: FC;
  Control: FC;
  Flexbox: FC<{ width?: number }>;
  Group: FC;
  Info: FC;
  Search: FC;
};

const Action: FC = ({ children, ...rest }) => (
  <Stack direction="row" justifyContent="flex-end" spacing={2} {...rest}>
    {children}
  </Stack>
);

const Search: FC = ({ children, ...rest }) => (
  <Stack direction="row" justifyContent="flex-start" spacing={2} {...rest}>
    {children}
  </Stack>
);

const Toolbar: Component = ({ children }) => {
  return <Box>{children}</Box>;
};

Toolbar.Action = Action;
Toolbar.Control = Control;
Toolbar.Flexbox = Flexbox;
Toolbar.Group = Group;
Toolbar.Info = Info;
Toolbar.Search = Search;

export { Toolbar };
