import { FC } from 'react';
import { Info, Text, Wrapper } from './PaginationLite.styled';

export type PaginationLiteProps = {
  loading?: boolean;
};

const PaginationLite: FC<PaginationLiteProps> = ({ loading = false }) => {
  const showPagination = loading;

  if (showPagination) {
    return (
      <Wrapper>
        <Info>
          <Text>Loading...</Text>
        </Info>
      </Wrapper>
    );
  }

  return null;
};

export { PaginationLite };
