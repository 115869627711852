import Button from '@mui/material/Button';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectPermissionGroupDetail } from '@bom-nextgen-keycloak/web/core';
import {
  GroupDeleteDialog as DeleteDialog,
  useGroup2,
} from '@bom-nextgen-keycloak/web/pages/feature-group';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ProtectedRouter,
  TabbedContent,
  TabList,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { useGroupDetail2 } from './hooks';
import { PARENT_PATH } from './shared';

const GroupDetail2: FC = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const {
    canDelete,
    isDeleteDialogOpen,
    isDeleting,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
  } = useGroup2({
    callback: () => navigate(PARENT_PATH),
    disableQuery: true,
  });
  const { data, isGroup, isLoading, isSubscription } = useGroupDetail2(id);
  const {
    canViewAttributesTab,
    canViewGeneralTab,
    canViewMemberTab,
    canViewRoleMappingTab,
  } = useSelector(selectPermissionGroupDetail);
  const parentPath = PARENT_PATH;
  const rootPath = `${parentPath}/${id}`;
  const showDeleteButton = canDelete;
  const disableDeleteButton = isLoading;

  const tabList: TabList = [
    {
      enabled: canViewGeneralTab,
      label: 'General',
      value: '',
    },
    {
      enabled: canViewAttributesTab,
      label: isSubscription ? 'Subscription' : 'Attributes',
      value: 'attributes',
    },
    {
      enabled: canViewMemberTab,
      label: 'Members in Group',
      value: 'members',
    },
    {
      enabled: canViewRoleMappingTab && !isSubscription && isGroup,
      label: 'Role Mappings',
      value: 'role-mappings',
    },
  ];

  const handleDelete = () => {
    if (data) {
      handleDeleteRequest(data);
    } else {
      // TODO: Implement a handler
    }
  };

  const renderActions = () => {
    return showDeleteButton ? (
      <Button
        color="error"
        disabled={disableDeleteButton}
        onClick={handleDelete}
        variant="text"
      >
        Delete
      </Button>
    ) : null;
  };

  return (
    <Fragment>
      <TabbedContent
        actions={renderActions()}
        breadcrumb={data?.name}
        feature="Groups"
        parentPath={parentPath}
        rootPath={rootPath}
        tabList={tabList}
      />
      <DeleteDialog
        loading={isDeleting}
        name={data?.name}
        onClose={handleDeleteCancel}
        onSubmit={handleDeleteAction}
        open={isDeleteDialogOpen}
      />
    </Fragment>
  );
};

const WrappedGroupDetailPage: FC = () => {
  const { canViewPage } = useSelector(selectPermissionGroupDetail);

  return (
    <ProtectedRouter
      canView={canViewPage}
      feature={FeaturePage.GROUP}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <GroupDetail2 />
    </ProtectedRouter>
  );
};

export { WrappedGroupDetailPage };
