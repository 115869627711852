import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FC } from 'react';
import { Header, Placeholder } from './SelectCombo.styled';

export type SelectComboItem = {
  id: string;
  name: string;
};

type SelectCombo = {
  checked: readonly SelectComboItem[];
  loading: boolean;
  onSelect?: (selected: SelectComboItem[]) => void;
  readonly?: boolean;
  subtitle?: string;
  title: React.ReactNode;
  value: readonly SelectComboItem[];
};

function not(a: readonly SelectComboItem[], b: readonly SelectComboItem[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(
  a: readonly SelectComboItem[],
  b: readonly SelectComboItem[]
) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly SelectComboItem[], b: readonly SelectComboItem[]) {
  return [...a, ...not(b, a)];
}

const SelectCombo: FC<SelectCombo> = ({
  checked = [],
  loading = false,
  onSelect,
  readonly = false,
  subtitle = '',
  title,
  value = [],
}) => {
  const listLength = value.length;

  const selectedCount = (selectItem: readonly SelectComboItem[]) =>
    intersection(checked, selectItem).length;

  const handleSelect = (value: SelectComboItem) => () => {
    const currentIndex = checked.indexOf(value);
    const selected = [...checked];

    if (currentIndex === -1) {
      selected.push(value);
    } else {
      selected.splice(currentIndex, 1);
    }

    if (onSelect) {
      onSelect(selected);
    }
  };

  const handleSelectAll = (value: readonly SelectComboItem[]) => () => {
    if (onSelect && selectedCount(value) === value.length) {
      onSelect(not(checked, value));
    } else if (onSelect) {
      onSelect(union(checked, value));
    }
  };

  return (
    <Card>
      {readonly ? (
        <Header subheader={subtitle || ''} title={title} />
      ) : (
        <Header
          avatar={
            <Checkbox
              checked={selectedCount(value) === listLength && listLength !== 0}
              disabled={listLength === 0 || loading}
              indeterminate={
                selectedCount(value) !== listLength &&
                selectedCount(value) !== 0
              }
              inputProps={{
                'aria-label': 'select all',
              }}
              onClick={handleSelectAll(value)}
            />
          }
          subheader={`${selectedCount(value)}/${listLength} selected`}
          title={title}
        />
      )}

      <Divider />

      {loading ? (
        <Placeholder>
          <CircularProgress size={24} />
        </Placeholder>
      ) : (
        <List
          component="div"
          dense
          role="list"
          sx={{
            height: 227,
            overflow: 'auto',
            width: 320,
          }}
        >
          {value.map((item) => {
            const { id, name } = item;
            const labelId = `transfer-list-all-item-${id}-label`;

            if (readonly) {
              return (
                <ListItem key={id}>
                  <ListItemText
                    id={labelId}
                    primary={name}
                    sx={{ marginBottom: '9px', paddingTop: '9px' }}
                  />
                </ListItem>
              );
            }

            return (
              <ListItemButton
                key={id}
                onClick={handleSelect(item)}
                role={undefined}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={name} />
              </ListItemButton>
            );
          })}
          <ListItem />
        </List>
      )}
    </Card>
  );
};

export { SelectCombo };
