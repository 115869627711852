import CloseIcon from '@mui/icons-material/Close';
import Alert, { AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar, {
  SnackbarOrigin,
  SnackbarProps,
} from '@mui/material/Snackbar';
import { FC, useEffect, useState } from 'react';

type NotificationProps = {
  duration?: number;
  message: string;
  onClose: () => void;
  title?: string;
  type?: AlertColor;
};

type TransitionProps = Omit<SlideProps, 'direction'>;

const Transition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};

const Notification: FC<NotificationProps> = ({
  duration = 5000,
  message,
  onClose,
  title,
  type,
}) => {
  const [open, setOpen] = useState(true);

  const isAlert = Boolean(type);
  const isToast = !isAlert;

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    if (message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  const renderAlert = () => (
    <Alert
      elevation={3}
      onClose={handleClose}
      severity={type}
      sx={{ maxWidth: '275px', minWidth: '225px' }}
      variant="filled"
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  );

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const anchorOrigin: SnackbarOrigin = {
    horizontal: isAlert ? 'left' : 'center',
    vertical: 'bottom',
  };

  const notificationProps: SnackbarProps = {
    anchorOrigin,
    autoHideDuration: duration,
    onClose: handleClose,
    open,
    TransitionComponent: Transition,
    ...(isToast && { action, message }),
    ...(isAlert && { children: renderAlert() }),
  };

  return <Snackbar {...notificationProps} />;
};

export { Notification };
