import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { AxiosError } from 'axios';
import { FC, Fragment, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ICompanyDto } from '@bom-nextgen-keycloak/models';
import { useAlertMessage } from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchCompanyCompactList,
  joinGroupUser,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { FormSpinner } from '@bom-nextgen-keycloak/web/shared/ui';
import { CONFIG } from '../../../shared';
import { CardContent, CardHeader, List, ListItem } from '../Group2.styled';
import { Card } from './GroupCompany.styled';

type GroupCompanyProps = {
  loading?: boolean;
  onReload: () => void;
  resourceGroupId: string;
  userId: string;
};

const GroupCompany: FC<GroupCompanyProps> = ({
  loading = false,
  onReload,
  resourceGroupId,
  userId,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const [selectedItem, setSelectedItem] = useState<ICompanyDto | null>(null);

  const defaultData = {
    items: [],
    totalCount: 0,
    totalRecord: 0,
  };
  const params = {
    first: CONFIG.pagination.offset,
    id: resourceGroupId,
    max: CONFIG.pagination.step,
  };
  const queryKeys = [QUERY_KEY.USER_GROUP_COMPANY, resourceGroupId, userId];

  const {
    data = defaultData,
    isFetching,
    isLoading,
  } = useQuery(
    queryKeys,
    () => fetchCompanyCompactList(resourceGroupId, params),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get companies';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
    }
  );

  const joinMutation = useMutation(joinGroupUser, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Unable to join a company';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },

    onSuccess: () => {
      onReload();
    },
  });

  const handleGroupSelect = (
    event: React.MouseEvent<HTMLElement>,
    data: ICompanyDto
  ) => {
    setSelectedItem(data);
  };

  const handleGroupJoin = () => {
    if (selectedItem) {
      joinMutation.mutate({
        groupIds: [selectedItem.id],
        userId,
      });
    } else {
      // TODO: Implement a handler
    }
  };

  const isInitializing = isFetching || isLoading;
  const hasCompanies = data.items.length;
  const disableJoinButton = isInitializing || loading || !selectedItem;

  const renderContent = () => {
    if (hasCompanies) {
      return (
        <List dense>
          {data.items.map((item) => (
            <ListItem
              key={item.id}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                handleGroupSelect(event, item)
              }
            >
              <ListItemButton selected={selectedItem === item}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      );
    }

    return <Typography>No companies found</Typography>;
  };

  return (
    <Card>
      <CardHeader
        action={
          <LoadingButton
            aria-label="join company"
            disabled={disableJoinButton}
            loading={
              joinMutation.isLoading || (loading && Boolean(selectedItem))
            }
            onClick={handleGroupJoin}
            variant="outlined"
          >
            Join
          </LoadingButton>
        }
        disableTypography
        title="Available companies"
      />
      <Divider />
      <CardContent>
        {isInitializing ? (
          <FormSpinner />
        ) : (
          <Fragment>{renderContent()}</Fragment>
        )}
      </CardContent>
    </Card>
  );
};

export { GroupCompany };
