import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GroupDto } from '@bom-nextgen-keycloak/models';
import {
  CONFIG,
  selectPermissionGroup,
  selectPermissionGroupDetail,
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  deleteGroup,
  ErrorMessage,
  fetchGroupById,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  SearchboxEmit,
  SearchboxOptions,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  generateHash,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';

type Arguments = {
  callback?: () => void;
  disableQuery?: boolean;
};

const useGroup2 = (options?: Arguments) => {
  const callback = options?.callback;
  const disableQuery = options?.disableQuery;
  const enableQuery = !disableQuery;
  const resource = useResource();
  const { groupId } = resource;
  const defaultData = {};
  const defaultSelection = {
    item: null,
    node: null,
  };
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const {
    canViewExport: canExport,
    canViewAdd: canAdd,
    canViewDelete: canDelete,
  } = useSelector(selectPermissionGroup);
  const { canViewPage: canEdit } = useSelector(selectPermissionGroupDetail);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<GroupDto | null>(
    defaultSelection.node
  );
  const [selectedNode, setSelectedNode] = useState<GroupDto | null>(
    defaultSelection.node
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [queryHash, setQueryHash] = useState(generateHash());
  const [isSearching, setIsSearching] = useState(false);
  const [wasSearch, setWasSearch] = useState(false);
  const debouncedSearchQuery = useDebounce(
    searchQuery,
    CONFIG.ui.debounceDelay
  );

  const id = selectedNode?.id ?? '';
  const isSearch = searchQuery !== '';
  const isSelected = Boolean(id);
  const queryKeys = [
    QUERY_KEY.GROUP_DETAIL,
    groupId,
    queryHash,
    debouncedSearchQuery,
  ];

  const {
    data = defaultData,
    isFetched,
    isFetching,
    isLoading,
  } = useQuery(
    queryKeys,
    () =>
      fetchGroupById(groupId, {
        search: searchQuery,
      }),
    {
      enabled: enableQuery,
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get groups';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
      onSettled: () => {
        setSelectedNode(defaultSelection.node);
      },
      onSuccess: () => {
        if (isSearch && isSearching) {
          setIsSearching(false);
        }

        if (wasSearch && !isSearch) {
          setWasSearch(false);
        }
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const deleteMutation = useMutation(deleteGroup, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot delete group';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: (data) => {
      setIsDeleteDialogOpen(false);
      setNotification({
        message: 'Group deleted',
        type: 'success',
      });

      if (enableQuery) {
        handleSelectionReset();
        handleReload();
      }

      callback && callback();
    },
  });

  const handleSelectionReset = () => {
    setSelectedItem(defaultSelection.item);
    setSelectedNode(defaultSelection.node);
  };

  const handleRefetch = () => {
    setQueryHash(generateHash());
  };

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const handleRefresh = () => {
    handleRefetch();
  };

  const handleCreateRequest = () => {
    setIsCreateDialogOpen(true);
  };

  const handleEditRequest = () => {
    if (selectedNode && selectedNode.id) {
      navigate(`/groups/${selectedNode.id}`);
    } else {
      // TODO: Implement a handler
    }
  };

  const handleDeleteRequest = (data: GroupDto) => {
    if (data) {
      setSelectedItem(data);
      setIsDeleteDialogOpen(true);
    } else {
      // TODO: Implement a handler
    }
  };

  const handleExportRequest = () => {
    setIsExportDialogOpen(true);
  };

  const handleCreateCancel = () => {
    setIsCreateDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
    setSelectedItem(defaultSelection.item);
  };

  const handleExportCancel = () => {
    setIsExportDialogOpen(false);
  };

  const handleCreateAction = () => {
    setIsCreateDialogOpen(false);
    handleReload();
  };

  const handleDeleteAction = () => {
    if (selectedItem?.id) {
      deleteMutation.mutate(selectedItem.id);
    } else {
      // TODO: Implement a handler
    }
  };

  const handleNodeSelect = (node: GroupDto | null) => {
    setSelectedNode(node);
  };

  const handleSearch = ({ event, isEnter = false }: SearchboxOptions) => {
    const { value } = event;
    const keyword = value.trim();
    const hasKeyword = keyword !== '';
    const hasChanged = keyword !== '' && keyword !== searchQuery;

    setIsSearching(hasChanged);

    if (isEnter && hasKeyword) {
      handleRefetch();
    } else {
      setSearchQuery((previous) => {
        setWasSearch(previous !== '' && !hasKeyword);

        return value;
      });
    }
  };

  const handleSearchChange = (event: SearchboxEmit) => {
    handleSearch({ event, isEnter: false });
  };

  const handleSearchEnter = (event: SearchboxEmit) => {
    handleSearch({ event, isEnter: true });
  };

  const handleSearchReset = () => {
    const value = '';

    setWasSearch(true);
    setSearchQuery(value);
  };

  const handleSearchClear = () => {
    const value = '';
    const hasKeyword = isSearch;

    if (hasKeyword) {
      handleSearchReset();
    } else {
      // NOTE: Clear whitespaces without making an API request
      setSearchQuery(value);
    }
  };

  return {
    canAdd,
    canDelete,
    canEdit,
    canExport,
    data,
    handleCreateAction,
    handleCreateCancel,
    handleCreateRequest,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    handleEditRequest,
    handleExportCancel,
    handleExportRequest,
    handleNodeSelect,
    handleRefresh,
    handleSearchChange,
    handleSearchClear,
    handleSearchEnter,
    handleSearchReset,
    isCreateDialogOpen,
    isDeleteDialogOpen,
    isDeleting: deleteMutation.isLoading,
    isExportDialogOpen,
    isFetched,
    isFetching,
    isLoading,
    isSearch,
    isSearching,
    isSelected,
    searchQuery,
    selectedNode,
    wasSearch,
  };
};

export { useGroup2 };
