import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { FC, Fragment, useEffect } from 'react';
import { Progress } from '../Progress';
import {
  Control,
  Info,
  Jumper,
  Mode,
  Nav,
  Navigator,
  Status,
  Step,
  Text,
  Wrapper,
} from './PaginationPro.styled';

interface Direction {
  first: number;
  last: number;
  next: number;
  prev: number;
}

export type PaginationProStepOptions = {
  label: string;
  value: number;
};

export type PaginationProProps = {
  isFetched?: boolean;
  isFetching?: boolean;
  isFilter?: boolean;
  isLoading?: boolean;
  isProcessing?: boolean;
  isSearch?: boolean;
  isSearching?: boolean;
  onPageChange?: (offset: number) => void;
  onStepChange?: (event: SelectChangeEvent<unknown>) => void;
  paginationOffset?: number;
  paginationStep?: number;
  stepOptions?: PaginationProStepOptions[];
  totalCount?: number | undefined;
  totalRecord: number;
  wasSearch?: boolean;
};

const defaultStepOptions: PaginationProStepOptions[] = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '75', value: 75 },
  { label: '100', value: 100 },
];

const PaginationPro: FC<PaginationProProps> = ({
  isFetched = false,
  isFetching = true,
  isFilter = false,
  isLoading = true,
  isProcessing = true,
  isSearch = false,
  isSearching = false,
  onPageChange,
  onStepChange,
  paginationOffset: pagiOffset = 0,
  paginationStep: pagiStep = 10,
  stepOptions = defaultStepOptions,
  totalCount = undefined,
  totalRecord = 0,
  wasSearch = false,
}) => {
  const absoluteRecord = totalRecord;
  const absoluteCount = totalCount === undefined ? 0 : totalCount;
  const pagiStart = pagiOffset + 1;
  const pagiEnd = pagiOffset + pagiStep;
  const pagiPage = pagiEnd / pagiStep;
  const pagiRemainder = absoluteCount % pagiStep > 0 ? 1 : 0;
  const pagiTotal = Math.floor(absoluteCount / pagiStep) + pagiRemainder;
  const pagiFirst = 0;
  const pagiPrev = pagiOffset - pagiStep;
  const pagiNext = pagiOffset + pagiStep;
  const pagiLast = (pagiTotal - 1) * pagiStep;
  const canNav =
    (absoluteRecord > 0 && absoluteCount > 0) ||
    (absoluteRecord > 0 && absoluteCount === 0) ||
    (absoluteRecord === 0 && pagiPage > 1 && absoluteCount === 0);
  const canNavPrev = pagiOffset > 0 && canNav;
  const canNavFirst = canNavPrev;
  const canNavNext =
    (absoluteRecord === pagiStep && pagiEnd < absoluteCount && canNav) ||
    (absoluteRecord === pagiStep && absoluteCount === 0 && canNav);
  const canNavLast = canNavNext && absoluteCount > 0;
  const isCalculating = isProcessing || wasSearch;
  const isPlural = absoluteRecord > 1;
  const showPagination =
    isCalculating ||
    (!isProcessing && absoluteRecord > 0) ||
    (!isProcessing && pagiPage > 1 && absoluteCount === 0);

  const handleNavigate = (direction: keyof Direction) => {
    const navigationMap = {
      first: pagiFirst,
      last: pagiLast,
      next: pagiNext,
      prev: pagiPrev,
    };

    onPageChange && onPageChange(navigationMap[direction]);
  };

  const isNavEnabled = (direction: boolean): boolean =>
    !direction || isCalculating;

  useEffect(() => {
    if (
      absoluteRecord === 0 &&
      absoluteCount > 0 &&
      pagiPage > 1 &&
      pagiPage > pagiTotal &&
      !isFetching
    ) {
      const offset = pagiOffset - pagiStep;

      // NOTE: navigate back 1 page
      onPageChange && onPageChange(offset);
    }
  }, [
    absoluteCount,
    absoluteRecord,
    isFetching,
    onPageChange,
    pagiOffset,
    pagiPage,
    pagiStep,
    pagiTotal,
  ]);

  if (showPagination) {
    return (
      <Wrapper>
        <Info>
          {isCalculating ? (
            <Status>
              <Text>
                <Progress />
              </Text>
            </Status>
          ) : (
            <Status>
              {isSearch && (
                <Mode>
                  <Chip
                    color="primary"
                    label={(isFilter ? 'Advanced ' : '') + 'Search Mode'}
                    size="small"
                    variant="outlined"
                  />
                </Mode>
              )}
              <Text>
                Showing&nbsp;
                <b>
                  {absoluteRecord} record{isPlural ? 's' : ''}
                </b>
              </Text>
              &nbsp;
              <Text>
                in&nbsp;
                <b>
                  page {pagiPage}
                  {!!absoluteCount && ` of ${pagiTotal}`}
                </b>
              </Text>
              &nbsp;
              <Text>
                (between {pagiStart} - {pagiEnd})
              </Text>
              {!!absoluteCount && (
                <Fragment>
                  &nbsp;
                  <Text>
                    from <b>{absoluteCount} records</b>
                  </Text>
                </Fragment>
              )}
            </Status>
          )}
        </Info>
        <Control>
          {onStepChange && !isProcessing && !wasSearch ? (
            <Step>
              <Jumper
                disabled={isCalculating}
                id="pagination"
                labelId="pagination-label"
                onChange={onStepChange}
                size="small"
                value={pagiStep}
              >
                {stepOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Jumper>
              <Text>records per page</Text>
            </Step>
          ) : (
            <div />
          )}
          <Navigator>
            <Nav
              disabled={isNavEnabled(canNavFirst)}
              onClick={() => handleNavigate('first')}
              title="First page"
            >
              <SkipPreviousIcon fontSize="small" />
            </Nav>
            <Nav
              disabled={isNavEnabled(canNavPrev)}
              onClick={() => handleNavigate('prev')}
              title={`Page ${pagiPage - 1}`}
            >
              <NavigateBeforeIcon fontSize="small" />
            </Nav>
            <Nav
              disabled={isNavEnabled(canNavNext)}
              onClick={() => handleNavigate('next')}
              title={`Page ${pagiPage + 1}`}
            >
              <NavigateNextIcon fontSize="small" />
            </Nav>
            <Nav
              disabled={isNavEnabled(canNavLast)}
              onClick={() => handleNavigate('last')}
              title={`Last page (${pagiTotal})`}
            >
              <SkipNextIcon fontSize="small" />
            </Nav>
          </Navigator>
        </Control>
      </Wrapper>
    );
  }

  return null;
};

export { PaginationPro };
