import { ThemeProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { FC, useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  AlertMessageContextProvider,
  NotificationContextProvider,
  resetAccount,
  setAuthenticated,
} from '@bom-nextgen-keycloak/web/core';
import { Spinner } from '@bom-nextgen-keycloak/web/shared/ui';
import { resetPreferedWorkspace } from '@bom-nextgen-keycloak/web/shared/util';
import { theme } from '@bom-nextgen-keycloak/web/theme';
import Router from './router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const Error = ({ error }: FallbackProps) => (
  <div role="alert">
    <h1>Something went wrong</h1>
    <p>
      <pre>{error.message}</pre>
    </p>
  </div>
);

const App2: FC = () => {
  const { initialized, keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const { authenticated } = keycloak;

  useEffect(() => {
    if (initialized) {
      if (authenticated) {
        dispatch(setAuthenticated());
      } else {
        dispatch(resetAccount());
        resetPreferedWorkspace();
      }
    }
  }, [authenticated, dispatch, initialized]);

  if (!initialized) {
    return <Spinner startup />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <AlertMessageContextProvider>
            <NotificationContextProvider>
              <QueryClientProvider client={queryClient}>
                <ErrorBoundary FallbackComponent={Error}>
                  <Router />
                </ErrorBoundary>
              </QueryClientProvider>
            </NotificationContextProvider>
          </AlertMessageContextProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App2;
