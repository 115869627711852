import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, Fragment } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  IRoleRepresentation,
  PolicyRepresentationDto,
  PolicyUpdateDto,
  RolePolicy,
} from '@bom-nextgen-keycloak/models';
import {
  DECISION_STRATEGIES,
  POLICY_LOGICS,
  POLICY_TYPES,
  selectPermissionPolicyDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { RoleTableForm2 } from '@bom-nextgen-keycloak/web/pages/feature-policy';
import {
  ErrorMessage,
  fetchClientRole,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  Capitalize,
  DropdownFieldPro,
  FormActionsGroup,
  FormBox,
  FormField,
  FormText,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getButtonStatus,
  getFieldError,
} from '@bom-nextgen-keycloak/web/shared/util';
import { CONFIG } from '../../shared';
import { RoleForm, validationSchema } from './config';

const FORM_FIELDS = { ...CONFIG.form.fields };

type GeneralRoleType = {
  data: PolicyRepresentationDto | null;
  isUpdating: boolean;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (payload: PolicyUpdateDto) => void;
};

const GeneralRole: FC<GeneralRoleType> = ({
  data,
  isUpdating,
  loading,
  onCancel,
  onSubmit,
}) => {
  const params = useParams();
  const { type = 'undefined' } = params;
  const { clientId, clientName } = useResource();
  const { isEditGeneralTab: canEdit } = useSelector(
    selectPermissionPolicyDetail
  );
  const { setAlertMessage } = useAlertMessage();

  const roles = data?.roles || undefined;
  const disableField = !canEdit;

  const queryKeys = [
    QUERY_KEY.CLIENT_ROLE_LIST,
    clientId,
    'POLICY_GENERAL_RBAC',
  ];

  const roleQuery = useQuery(
    queryKeys,
    async () => {
      const clientRoles = await fetchClientRole(clientId, {
        ...CONFIG.pagination,
        // FIXME: Low performance
        isAll: true,
      });

      return clientRoles.items;
    },
    {
      enabled: Boolean(roles),
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get client roles';

        setAlertMessage({ message, typeStatusMessage: 'error' });
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const mapAutocompleteOptions = (
    clientRoles: IRoleRepresentation[]
  ): AutoCompleteOption[] => {
    return clientRoles.map((item) => ({
      label: item.name,
      value: {
        ...item,
        required: false,
      },
    }));
  };

  const mapRequiredRoles = (
    roleDetail: IRoleRepresentation,
    mappedRoles: RolePolicy[] | undefined
  ) => {
    if (mappedRoles?.length) {
      const matchedRole = mappedRoles.find((item) => item.id === roleDetail.id);

      if (matchedRole) {
        return {
          ...roleDetail,
          required: matchedRole?.required || false,
        };
      } else {
        return roleDetail;
      }
    } else {
      return roleDetail;
    }
  };

  const mapSelectedRoles = (clientRoles: IRoleRepresentation[]) => {
    const roleIds = roles?.map((item) => item.id) || [];

    const selectedRoles: AutoCompleteOption[] = clientRoles
      .filter((item) => roleIds.includes(item.id))
      .map((item) => mapRequiredRoles(item, roles))
      .map((item) => ({
        label: item.name,
        value: item,
      }));

    return selectedRoles;
  };

  const handleRequiredRoleChange = (
    checked: boolean,
    roleId: string,
    roleList: AutoCompleteOption[],
    setFieldValue: (field: string, value: AutoCompleteOption[]) => void
  ) => {
    const output = roleList.map((item) =>
      item.value.id === roleId
        ? {
            ...item,
            value: {
              ...item.value,
              required: checked,
            },
          }
        : item
    );

    setFieldValue(FORM_FIELDS.roles.name, output);
  };

  const handleRoleRemove = (
    roleItem: AutoCompleteOption,
    roleList: AutoCompleteOption[],
    setFieldValue: (field: string, value: AutoCompleteOption[]) => void
  ) => {
    if (roleItem) {
      const output = roleList.filter(
        (item) => item.value.id !== roleItem.value.id
      );

      setFieldValue(FORM_FIELDS.roles.name, output);
    }
  };

  const renderAutocompleteSpinner = (isLoading: boolean) => {
    return isLoading ? <CircularProgress color="inherit" size={16} /> : null;
  };

  const initialValues: RoleForm = {
    description: data?.description || '',
    logic: data?.logic || '',
    name: data?.name || '',
    roles: mapSelectedRoles(roleQuery.data || []),
    type: POLICY_TYPES.role,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(
        payload: RoleForm,
        { setSubmitting }: FormikHelpers<RoleForm>
      ) => {
        const requestPayload: PolicyUpdateDto = {
          decisionStrategy: DECISION_STRATEGIES.unanimous.value,
          description: payload.description,
          id: data?.id || 'unknown',
          logic: payload.logic,
          name: payload.name,
          roles: payload.roles.map(({ value: { id, required } }) => ({
            id,
            required,
          })),
          type: payload.type,
        };

        onSubmit(requestPayload);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({
        dirty,
        getFieldMeta,
        isValid,
        resetForm,
        setFieldTouched,
        setFieldValue,
        values,
      }) => {
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: loading,
          isValid,
        });
        const { roles } = values;
        const showSelectedList = roles.length !== 0;
        const fieldProps = { disabled: disableField };
        const autocompleteInputProps: TextFieldProps = {
          fullWidth: true,
          InputLabelProps: {
            shrink: true,
          },
          variant: 'standard',
        };
        const fieldErrors = {
          role: getFieldError(getFieldMeta, FORM_FIELDS.roles.name),
        };
        const roleOptions = mapAutocompleteOptions(roleQuery.data || []);

        return (
          <Form>
            <FormBox>
              <FormField>
                <InputLabel shrink>{FORM_FIELDS.type.label}</InputLabel>
                <FormText>
                  <Capitalize>{type}</Capitalize>-based
                </FormText>
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.name.label}
                  name={FORM_FIELDS.name.name}
                  required
                />
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.description.label}
                  multiline
                  name={FORM_FIELDS.description.name}
                />
              </FormField>
              <FormField>
                <Autocomplete
                  {...fieldProps}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.label}
                  id={FORM_FIELDS.roles.name}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  limitTags={3}
                  loading={roleQuery.isLoading}
                  multiple
                  onChange={(event, value) => {
                    setFieldValue(FORM_FIELDS.roles.name, value || []);
                  }}
                  options={roleOptions}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      {...fieldProps}
                      {...autocompleteInputProps}
                      error={fieldErrors.role.isError}
                      helperText={fieldErrors.role.helperText}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {renderAutocompleteSpinner(roleQuery.isLoading)}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                      label={FORM_FIELDS.roles.label}
                      // name={FORM_FIELDS.roles.name}
                      onBlur={() => {
                        setFieldTouched(FORM_FIELDS.roles.name);
                      }}
                      placeholder={
                        roleQuery.isLoading
                          ? 'Loading...'
                          : FORM_FIELDS.roles.placeholder
                      }
                      required={roles.length === 0}
                    />
                  )}
                  value={roles}
                />
              </FormField>

              {showSelectedList && (
                <FormField>
                  <RoleTableForm2
                    clientName={clientName}
                    data={roles}
                    disabled={disableField}
                    onRemove={(item: AutoCompleteOption) =>
                      handleRoleRemove(item, roles, setFieldValue)
                    }
                    onSelect={(checked: boolean, id: string) =>
                      handleRequiredRoleChange(
                        checked,
                        id,
                        roles,
                        setFieldValue
                      )
                    }
                  />
                </FormField>
              )}

              <FormField>
                <DropdownFieldPro
                  {...fieldProps}
                  label={FORM_FIELDS.logic.label}
                  name={FORM_FIELDS.logic.name}
                  options={POLICY_LOGICS}
                  placeholder={FORM_FIELDS.logic.placeholder}
                  required
                />
              </FormField>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={isUpdating}
              onCancel={onCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export { GeneralRole };
