import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import { Progress } from '../Progress';
import { Content, Indicator, Title, Wrapper } from './Spinner.styled';

type SpinnerProps = {
  open?: boolean;
  startup?: boolean;
  title?: string;
};

const Spinner: FC<SpinnerProps> = ({ open = true, startup = false, title }) => (
  <Wrapper open>
    {!startup && (
      <Content>
        <Indicator>
          <CircularProgress size="32px" />
        </Indicator>
        {title && (
          <Title variant="h3">
            <Progress delay={5000} loadingText={title} />
          </Title>
        )}
      </Content>
    )}
  </Wrapper>
);

export { Spinner };
