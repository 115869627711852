import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const Text = styled('span')`
  display: inline;
`;

export const Wrapper = styled('div')`
  align-items: center;
  background-color: ${grey[50]};
  border-radius: 0 0 4px 4px;
  display: flex;
  font-size: 0.8125rem;
  justify-content: space-between;
  padding: 5px 1rem;
  position: relative;
`;
