import {
  IPermissionRepresentationList,
  PermissionCreate,
  PermissionDetail,
  PermissionQuery,
  PermissionUpdate,
  PolicyRepresentationDto,
} from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export type DeletePermissionParams = {
  clientId: string;
  permissionId: string;
  type: string;
};

export type CreatePermissionParams = {
  clientId: string;
  type: string;
  payload: PermissionCreate;
};

export type UpdatePermissionParams = {
  clientId: string;
  type: string;
  payload: PermissionUpdate;
};

export const fetchPermissionList = (
  clientId: string,
  params?: PermissionQuery
) =>
  axiosInstance
    .get(`/clients/${clientId}/permissions`, { params: { ...params } })
    .then(({ data }: AxiosResponse<IPermissionRepresentationList>) => data);

export const createPermission = ({
  clientId,
  type,
  payload,
}: CreatePermissionParams) =>
  axiosInstance
    .post<PolicyRepresentationDto>(
      `/clients/${clientId}/permissions/types/${type}`,
      payload
    )
    .then(({ data }: AxiosResponse<PolicyRepresentationDto>) => data);

export const deletePermission = ({
  clientId,
  type,
  permissionId,
}: DeletePermissionParams) =>
  axiosInstance.delete(
    `/clients/${clientId}/permissions/${permissionId}/types/${type}`
  );

export const fetchPermissionById = (
  clientId: string,
  permissionId: string,
  type: string
) =>
  axiosInstance
    .get(`/clients/${clientId}/permissions/${permissionId}/types/${type}`)
    .then(({ data }: AxiosResponse<PermissionDetail>) => data);

export const updatePermission = ({
  clientId,
  type,
  payload,
}: UpdatePermissionParams) =>
  axiosInstance.put(`/clients/${clientId}/permissions/types/${type}`, payload);
