import { GroupDto } from '@bom-nextgen-keycloak/models';
import {
  CloseSquare,
  CustomTreeItem,
  MinusSquare,
  PlusSquare,
} from '@bom-nextgen-keycloak/web/shared/ui';
import TreeView from '@mui/lab/TreeView';
import Box from '@mui/material/Box';
import React, { FC, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

type TreeViewGroupProps = {
  groupDetail: GroupDto;
  onNodeSelected: (node: GroupDto | null) => void;
};

const findGroupByNodeId = (
  group: GroupDto,
  nodeId: string
): GroupDto | null => {
  if (group?.id === nodeId) {
    return group;
  } else if (group?.subGroups && group?.subGroups.length) {
    for (const child of group.subGroups) {
      const nodeGroup = findGroupByNodeId(child, nodeId);
      if (nodeGroup) {
        return nodeGroup;
      }
    }
    return null;
  } else {
    return null;
  }
};

function flatten(groupDto: GroupDto[]) {
  const flat: GroupDto[] = [];
  groupDto.forEach((item) => {
    flat.push(item);
    if (Array.isArray(item?.subGroups) && item?.subGroups?.length > 0) {
      flat.push(...flatten(item?.subGroups));
    }
  });
  return flat;
}

const GroupList: FC<TreeViewGroupProps> = ({ groupDetail, onNodeSelected }) => {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const expandedIds = useMemo(() => {
    if (groupDetail?.subGroups) {
      const newArrayGroup = JSON.parse(JSON.stringify(groupDetail?.subGroups));
      return flatten(newArrayGroup).map((val) => val.id || '');
    } else {
      return [];
    }
  }, [groupDetail]);

  useEffect(() => {
    setExpanded([groupDetail?.id || '', ...expandedIds]);
  }, [expandedIds, groupDetail]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
    onNodeSelected(null);
  };

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string) => {
    const findGroupById = findGroupByNodeId(groupDetail, nodeIds);
    if (findGroupById) {
      onNodeSelected(findGroupById);
    } else {
      onNodeSelected(null);
    }
  };

  const renderTree = (nodes: GroupDto) => {
    return (
      <CustomTreeItem
        key={nodes?.id}
        nodeId={nodes?.id || uuidv4()}
        label={nodes?.name}
      >
        {Array.isArray(nodes.subGroups)
          ? nodes.subGroups.map((node) => renderTree(node))
          : null}
      </CustomTreeItem>
    );
  };

  return (
    <Box sx={{ maxHeight: 600, overflow: 'auto' }}>
      <TreeView
        expanded={expanded}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {renderTree(groupDetail)}
      </TreeView>
    </Box>
  );
};

export { GroupList };
