// TODO: Clean up
import { Authenticated } from '@bom-nextgen-keycloak/web/pages/feature-authenticated';
import {
  // WrappedEventLoginPage as LoginEvent,
  WrappedLoginEventPage as LoginEvent,
  // WrappedEventPermissionPage as PermissionEvent,
  WrappedPermissionEventPage as PermissionEvent,
  WrappedPermissionEventDetailPage,
  // WrappedPermissionEventDetailPage as PermissionEventDetail,
} from '@bom-nextgen-keycloak/web/pages/feature-events';
import { Login } from '@bom-nextgen-keycloak/web/pages/feature-login';
import { PageNotFound } from '@bom-nextgen-keycloak/web/pages/feature-page-not-found';
import { Unauthorized } from '@bom-nextgen-keycloak/web/pages/feature-unauthorized';
import { RouteType } from '@bom-nextgen-keycloak/web/shared/data-access';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

export const Home = lazy(
  () =>
    import(
      /* webpackChunkName: "Home" */ '@bom-nextgen-keycloak/web/pages/feature-home'
    )
);
export const About = lazy(
  () =>
    import(
      /* webpackChunkName: "About" */ '@bom-nextgen-keycloak/web/pages/feature-about'
    )
);
export const Dashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "Dashboard" */ '@bom-nextgen-keycloak/web/pages/feature-dashboard'
    )
);
export const User = lazy(
  () =>
    import(
      /* webpackChunkName: "User" */ '@bom-nextgen-keycloak/web/pages/feature-user'
    )
);
export const UserDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "UserDetail" */ '@bom-nextgen-keycloak/web/pages/feature-user-detail'
    )
);
export const Group = lazy(
  () =>
    import(
      /* webpackChunkName: "Group" */ '@bom-nextgen-keycloak/web/pages/feature-group'
    )
);

export const GroupDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "GroupDetail" */ '@bom-nextgen-keycloak/web/pages/feature-group-detail'
    )
);

export const Role = lazy(
  () =>
    import(
      /* webpackChunkName: "Role" */ '@bom-nextgen-keycloak/web/pages/feature-roles'
    )
);

export const RoleDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "RoleDetail" */ '@bom-nextgen-keycloak/web/pages/feature-role-detail'
    )
);

export const Scope = lazy(
  () =>
    import(
      /* webpackChunkName: "Scope" */ '@bom-nextgen-keycloak/web/pages/feature-scope'
    )
);

export const ScopeDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "ScopeDetail" */ '@bom-nextgen-keycloak/web/pages/feature-scope-detail'
    )
);

export const Resource = lazy(
  () =>
    import(
      /* webpackChunkName: "Resource" */ '@bom-nextgen-keycloak/web/pages/feature-resources'
    )
);

export const ResourceDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "ResourceDetail" */ '@bom-nextgen-keycloak/web/pages/feature-resource-detail'
    )
);

export const Policy = lazy(
  () =>
    import(
      /* webpackChunkName: "Policy" */ '@bom-nextgen-keycloak/web/pages/feature-policy'
    )
);

export const PolicyDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "PolicyDetail" */ '@bom-nextgen-keycloak/web/pages/feature-policy-detail'
    )
);

export const Permission = lazy(
  () =>
    import(
      /* webpackChunkName: "Permission" */ '@bom-nextgen-keycloak/web/pages/feature-permission'
    )
);

export const PermissionDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "PermissionDetail" */ '@bom-nextgen-keycloak/web/pages/feature-permission-detail'
    )
);

export const Company = lazy(
  () =>
    import(
      /* webpackChunkName: "Company" */ '@bom-nextgen-keycloak/web/pages/feature-company'
    )
);

export const Event = lazy(
  () =>
    import(
      /* webpackChunkName: "Event" */ '@bom-nextgen-keycloak/web/pages/feature-events'
    )
);

export const privateRoute: RouteType[] = [
  {
    path: '/',
    element: <Home />,
    title: 'Home',
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    title: 'Dashboard',
    pathName: 'Dashboard',
  },
  {
    path: '/about',
    element: <About />,
    title: 'About BOMSC',
    pathName: 'About BOMSC',
  },
  {
    path: '/companies',
    element: Company,
    title: 'Companies',
    pathName: 'companies',
  },
  {
    path: '/users',
    element: User,
    title: 'Users',
    pathName: 'User',
  },
  {
    path: '/users/:id',
    element: UserDetail,
    title: 'Users',
    backTo: '/users',
    children: [
      {
        path: '/users/:id/attributes',
        element: null,
        title: 'Users',
        backTo: '/users',
      },
      {
        path: '/users/:id/credentials',
        element: null,
        title: 'Users',
        backTo: '/users',
      },
      {
        path: '/users/:id/groups',
        element: null,
        title: 'Users',
        backTo: '/users',
      },
      {
        path: '/users/:id/role-mappings',
        element: null,
        title: 'Users',
        backTo: '/users',
      },
      {
        path: '/users/:id/sessions',
        element: null,
        title: 'Users',
        backTo: '/users',
      },
      {
        path: '/users/:id/identity-provider-links',
        element: null,
        title: 'Users',
        backTo: '/users',
      },
    ],
  },
  {
    path: '/groups',
    element: Group,
    title: 'Groups',
    backTo: '/groups',
  },
  {
    path: '/groups/:id',
    element: GroupDetail,
    title: 'Groups',
    backTo: '/groups',
    children: [
      {
        path: '/groups/:id/attributes',
        element: null,
        title: 'Groups',
        backTo: '/groups',
      },
      {
        path: '/groups/:id/members',
        element: null,
        title: 'Groups',
        backTo: '/groups',
      },

      {
        path: '/groups/:id/role-mappings',
        element: null,
        title: 'Groups',
        backTo: '/groups',
      },
    ],
  },
  {
    path: '/roles',
    element: Role,
    title: 'Roles',
    backTo: '/roles',
  },
  {
    path: '/roles/:id',
    element: RoleDetail,
    title: 'Roles',
    backTo: '/roles',
    children: [
      {
        path: '/roles/:id/attributes',
        element: null,
        title: 'Roles',
        backTo: '/roles',
      },
      {
        path: '/roles/:id/users',
        element: null,
        title: 'Roles',
        backTo: '/roles',
      },
    ],
  },
  {
    path: '/scopes',
    element: Scope,
    title: 'Scopes',
    backTo: '/scopes',
  },
  {
    path: '/scopes/:id',
    element: ScopeDetail,
    title: 'Scopes',
    backTo: '/scopes',
  },
  {
    path: '/resources',
    element: Resource,
    title: 'Resources',
    backTo: '/resources',
  },
  {
    path: '/resources/:id',
    element: ResourceDetail,
    title: 'Resources',
    backTo: '/resources',
    children: [
      {
        path: '/resources/:id/attributes',
        element: null,
        title: 'Resources',
        backTo: '/resources',
      },
    ],
  },
  {
    path: '/policies',
    element: Policy,
    title: 'Policies',
    backTo: '/policies',
  },
  {
    path: '/policies/:id/:type',
    element: PolicyDetail,
    title: 'Policies',
    backTo: '/policies',
  },
  {
    path: '/permissions',
    element: Permission,
    title: 'Permissions',
    backTo: '/permissions',
  },
  {
    path: '/permissions/:id/:type',
    element: PermissionDetail,
    title: 'Permissions',
    backTo: '/permissions',
  },
  {
    path: '/events',
    element: PermissionEvent,
    title: 'Events',
    backTo: '/events',
  },
  {
    path: '/events/permission',
    element: Event,
    title: 'Events',
    backTo: '/events',
  },
  {
    path: '/events/login',
    element: LoginEvent,
    title: 'Events',
    backTo: '/events',
  },
  {
    path: '/events/permission/:id',
    element: WrappedPermissionEventDetailPage,
    title: 'Events',
    backTo: '/events',
  },
  {
    path: '*',
    element: <PageNotFound />,
    title: 'Not found',
    backTo: '/dashboard',
  },
];

const rootRoutes: RouteType[] = [
  {
    path: '/login',
    element: <Login />,
    title: 'Login',
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
    title: 'Unauthorized',
  },
  {
    path: '/',
    element: Authenticated,
    title: '',
    children: privateRoute,
  },
];

export default rootRoutes;
