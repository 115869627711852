import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { LoggingDto } from '@bom-nextgen-keycloak/models';
import { TableCellHead } from '../General/General.styled';

const GeneralGroup: FC<Partial<LoggingDto>> = (props) => {
  const { groups = [] } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="summary table">
        <TableHead>
          <TableRow>
            <TableCellHead scope="column" sx={{ width: 50 }}>
              Level
            </TableCellHead>
            <TableCellHead scope="column">Name</TableCellHead>
            <TableCellHead scope="column">Path</TableCellHead>
          </TableRow>
        </TableHead>

        <TableBody>
          {groups.length ? (
            groups.map((group) => (
              <TableRow
                key={group.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{group.level}</TableCell>
                <TableCell>{group.groupName}</TableCell>
                <TableCell>{group.groupPath}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>Groups not found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { GeneralGroup };
