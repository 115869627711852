import { FC } from 'react';
import { Capital, Data, Head, Row, Table } from './Criteria.styled';

type CellProps = {
  capitalized?: boolean;
};

type Component = FC & {
  Cell: FC<CellProps>;
  Head: FC<{ width?: number }>;
  Row: FC;
};

const Cell: FC<CellProps> = ({ capitalized = false, children }) => (
  <Data>{capitalized ? <Capital>{children}</Capital> : children}</Data>
);

const Criteria: Component = ({ children }) => (
  <Table>
    <tbody>{children}</tbody>
  </Table>
);

Criteria.Cell = Cell;
Criteria.Head = Head;
Criteria.Row = Row;

export { Criteria };
