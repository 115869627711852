import MuiSkeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

export const Skeleton = styled(MuiSkeleton)`
  width: 100%;
`;

export const Wrapper = styled('div')`
  width: 100%;
`;
