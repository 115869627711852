import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React, { FC, Fragment, useState } from 'react';

export type SelectButtonProps = {
  action: string;
  label?: {
    button?: string;
    option?: string;
  };
  onClick: (event: string) => void;
  options: {
    label: string;
    value: string;
  }[];
  selected?: number;
  title?: {
    click?: string;
    toggle?: string;
  };
};

const SelectButton: FC<SelectButtonProps> = ({
  action,
  label = {
    button: 'button',
    option: 'select option',
  },
  onClick,
  options,
  selected = 0,
  title = {
    click: '',
    toggle: '',
  },
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(selected);

  const canMenuOpen = isMenuOpen && Boolean(anchorEl);
  const id = canMenuOpen ? 'transition-popper' : undefined;
  const prefix = action ? `${action} ` : '';
  const buttonText = `${prefix}${options[selectedIndex].label}`;

  const handleClick = () => {
    onClick(options[selectedIndex].value);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleSelect = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setIsMenuOpen(false);
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen((prevOpen) => !prevOpen);
  };

  return (
    <Fragment>
      <ButtonGroup aria-label={label.button} variant="contained">
        <Button
          onClick={handleClick}
          startIcon={<AddIcon />}
          title={title.click}
        >
          {buttonText}
        </Button>
        <Button
          aria-controls={isMenuOpen ? 'split-button-menu' : undefined}
          aria-describedby={id}
          aria-expanded={isMenuOpen ? 'true' : undefined}
          aria-haspopup="menu"
          aria-label={label.option}
          onClick={handleToggle}
          title={title.toggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorEl}
        disablePortal
        id={id}
        open={isMenuOpen}
        placement="bottom-end"
        role={undefined}
        style={{ zIndex: 99 }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) => handleSelect(event, index)}
                      selected={index === selectedIndex}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export { SelectButton };
