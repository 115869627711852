import { FC } from 'react';
import { Text, Wrapper } from './TableFormFooter.styled';

const TableFormFooter: FC = ({ children }) => {
  return (
    <Wrapper>
      <Text>{children}</Text>
    </Wrapper>
  );
};

export { TableFormFooter };
