import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import {
  Actions,
  Body,
  Head,
  Header,
  Title,
  Wrapper,
} from './SideSheet.styled';

type SideSheetProps = {
  onClose: () => void;
  open: boolean;
  title: string;
  width?: number;
};

const SideSheet: FC<SideSheetProps> = ({
  children,
  onClose,
  open,
  title,
  width = 640,
}) => (
  <Drawer anchor="right" onClose={onClose} open={open}>
    <Wrapper width={width}>
      <Header>
        <Head>
          <Actions>
            <Tooltip title="Close">
              <IconButton aria-label="close" onClick={onClose} size="small">
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Actions>
          <Title>{title}</Title>
        </Head>
      </Header>
      <Body>{children}</Body>
    </Wrapper>
  </Drawer>
);

export { SideSheet };
