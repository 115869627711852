import { PermissionCreate } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  createPermission,
  ErrorMessage,
  fetchOptionScopeByResource,
  fetchPolicy,
  fetchResources,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  DropdownField,
  StyledDialogTitle,
  SwitchToggleField,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';
import {
  CircularProgress,
  MenuItem,
  TextField as MatTextField,
} from '@mui/material';
import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, Fragment, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';
import { StyledDialog } from './PermissionFormDialog.styled';

type PermissionFormDialogProps = {
  open: boolean;
  type: string;
  onClose: () => void;
  onSave: () => void;
};

type PermissionForm = {
  name: string;
  description: string;
  resourceType: string;
  isResourceType: boolean;
  policies: AutoCompleteOption[];
  resources: AutoCompleteOption | null;
  scopes: AutoCompleteOption[];
  decisionStrategy: string;
  type: string;
};

const validationSchema = yup.lazy((values) => {
  if (values.type === 'resource' && !values.isResourceType) {
    return yup.object({
      name: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required('Name is required'),
      resources: yup.object().nullable().required('Resource is required'),
      decisionStrategy: yup.string().required('Decision strategy is required'),
      policies: yup.array().min(1, 'Policies is required'),
    });
  } else if (values.type === 'resource' && values.isResourceType) {
    return yup.object({
      name: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required('Name is required'),
      resourceType: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required('Resource type is required'),
      decisionStrategy: yup.string().required('Decision strategy is required'),
      policies: yup.array().min(1, 'Policies is required'),
    });
  } else {
    return yup.object({
      name: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required('Name is required'),
      resources: yup.object().nullable().required('Resource is required'),
      scopes: yup.array().min(1, 'Scopes is required'),
      decisionStrategy: yup.string().required('Decision strategy is required'),
      policies: yup.array().min(1, 'Policies is required'),
    });
  }
});

const PermissionFormDialog: FC<PermissionFormDialogProps> = ({
  onClose,
  onSave,
  open,
  type,
}) => {
  const initialValues: PermissionForm = {
    name: '',
    description: '',
    policies: [],
    resources: null,
    scopes: [],
    isResourceType: false,
    decisionStrategy: 'AFFIRMATIVE',
    type,
    resourceType: '',
  };

  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const resource = useResource();
  // resource
  const [searchResourceQuery, setSearchResourceQuery] = useState('');
  const debouncedQueryTermResource = useDebounce(searchResourceQuery, 700);
  // policy
  const [searchPolicyQuery, setSearchPolicyQuery] = useState('');
  const debouncedQueryTermPolicy = useDebounce(searchPolicyQuery, 700);

  const [selectedResource, setSelectedResource] = useState('');

  const resourceQuery = useQuery(
    [QUERY_KEY.RESOURCE_LIST, resource.clientId, debouncedQueryTermResource],
    async () => {
      const resources = await fetchResources(resource.clientId, {
        max: 25,
        first: 0,
        name: searchResourceQuery,
      });
      return resources.items.map((item) => ({
        label: item.name,
        value: { ...item, required: false },
      }));
    }
  );

  const policyQuery = useQuery(
    [QUERY_KEY.POLICY_LIST, resource.clientId, debouncedQueryTermPolicy],
    async () => {
      const policys = await fetchPolicy(resource.clientId, {
        max: 25,
        first: 0,
        name: searchPolicyQuery,
      });
      return policys.items.map((item) => ({
        label: item.name,
        value: { ...item, required: false },
      }));
    }
  );

  const scopeQuery = useQuery(
    [QUERY_KEY.PERMISSION_OPTIONS_SCOPE, resource.clientId, selectedResource],
    async () => {
      const options = await fetchOptionScopeByResource(
        resource.clientId,
        selectedResource
      );
      return options.map((item) => ({
        label: item.name,
        value: item,
      }));
    },
    { enabled: type === 'scope' }
  );

  const onInputChangeResource = (
    event: React.ChangeEvent<any>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setSearchResourceQuery(value);
  };

  const createPermissionMutation = useMutation(createPermission, {
    onSuccess: () => {
      setNotification({
        message: 'Permission created',
        type: 'success',
      });
      onSave();
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot create permission';
      setAlertMessage({
        message:
          error.response?.status === 409
            ? 'Permission name already exists. try another name'
            : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const onInputChangePolicy = (
    event: React.ChangeEvent<any>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setSearchPolicyQuery(value);
  };

  return (
    <StyledDialog
      onClose={() => onClose()}
      aria-labelledby="dialog-title"
      open={open}
    >
      <StyledDialogTitle id="dialog-title">Add permission</StyledDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: PermissionForm,
          { setSubmitting }: FormikHelpers<PermissionForm>
        ) => {
          const payload: PermissionCreate = {
            decisionStrategy: values.decisionStrategy,
            name: values.name,
            description: values.description,
            type: values.type,
            resourceType: values.resourceType,
            resources: values.resources?.value._id
              ? [values.resources?.value._id]
              : [],
            scopes: values.scopes.length
              ? values.scopes.map((val) => val.value.id)
              : [],
            policies: values.policies.length
              ? values.policies.map((val) => val.value.id)
              : [],
          };

          createPermissionMutation.mutate({
            clientId: resource.clientId,
            type,
            payload,
          });
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
          values,
          getFieldMeta,
          setFieldTouched,
        }) => {
          return (
            <Form>
              <DialogContent>
                <Box sx={{ mb: 3 }}>
                  <TextField name="name" label="Name" required />
                </Box>

                <Box sx={{ mb: 3 }}>
                  <TextField name="description" label="Description" multiline />
                </Box>

                {type !== 'scope' && (
                  <Box sx={{ mb: 3 }}>
                    <SwitchToggleField
                      label="Apply to resource type"
                      name="isResourceType"
                      checked={values.isResourceType}
                    />
                  </Box>
                )}

                {type !== 'scope' && values.isResourceType && (
                  <Box sx={{ mb: 3 }}>
                    <TextField
                      name="resourceType"
                      required
                      label="Resource type"
                    />
                  </Box>
                )}

                {!values.isResourceType && (
                  <Box sx={{ mb: 3 }}>
                    <Autocomplete
                      id="resources"
                      filterSelectedOptions
                      options={resourceQuery.data || []}
                      getOptionLabel={(option) => option.label}
                      value={values.resources}
                      onChange={(e, value) => {
                        if (!value && type === 'scope') {
                          setFieldValue('scopes', []);
                          setSelectedResource('');
                        } else if (type === 'scope') {
                          setSelectedResource(value?.value._id);
                        }
                        setFieldValue('resources', value);
                      }}
                      onInputChange={onInputChangeResource}
                      loading={resourceQuery.isLoading}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      renderInput={(params) => (
                        <MatTextField
                          {...params}
                          id="resources"
                          name="resources"
                          label="Resource"
                          fullWidth
                          placeholder="Enter resource name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          required
                          onBlur={() => {
                            setFieldTouched('resources');
                          }}
                          error={
                            getFieldMeta('resources').touched &&
                            !!getFieldMeta('resources').error
                          }
                          helperText={
                            getFieldMeta('resources').touched &&
                            getFieldMeta('resources').error
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {resourceQuery.isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                )}

                {type === 'scope' && (
                  <Box sx={{ mb: 3 }}>
                    <Autocomplete
                      id="scopes"
                      filterSelectedOptions
                      options={scopeQuery.data || []}
                      multiple
                      getOptionLabel={(option) => option.label}
                      limitTags={3}
                      value={values.scopes}
                      onChange={(e, value) => {
                        setFieldValue('scopes', value || []);
                      }}
                      loading={scopeQuery.isLoading}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      renderInput={(params) => (
                        <MatTextField
                          {...params}
                          id="scopes"
                          name="scopes"
                          label="Scopes"
                          fullWidth
                          placeholder="Enter scope name"
                          required={values.scopes.length === 0}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={() => {
                            setFieldTouched('scopes');
                          }}
                          variant="standard"
                          error={
                            getFieldMeta('scopes').touched &&
                            !!getFieldMeta('scopes').error
                          }
                          helperText={
                            getFieldMeta('scopes').touched &&
                            getFieldMeta('scopes').error
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {scopeQuery.isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                )}

                <Box sx={{ mb: 3 }}>
                  <Autocomplete
                    id="policies"
                    filterSelectedOptions
                    options={policyQuery.data || []}
                    multiple
                    getOptionLabel={(option) => option.label}
                    limitTags={3}
                    value={values.policies}
                    onChange={(e, value) => {
                      setFieldValue('policies', value || []);
                    }}
                    onInputChange={onInputChangePolicy}
                    loading={policyQuery.isLoading}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    renderInput={(params) => (
                      <MatTextField
                        {...params}
                        id="policies"
                        name="policies"
                        label="Policies"
                        fullWidth
                        placeholder="Enter policy name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={values.policies.length === 0}
                        variant="standard"
                        onBlur={() => {
                          setFieldTouched('policies');
                        }}
                        error={
                          getFieldMeta('policies').touched &&
                          !!getFieldMeta('policies').error
                        }
                        helperText={
                          getFieldMeta('policies').touched &&
                          getFieldMeta('policies').error
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {policyQuery.isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>

                <Box sx={{ mb: 3 }}>
                  <DropdownField
                    name="decisionStrategy"
                    label="Decision strategy"
                    placeholder="please enter your Decision Strategy"
                    required
                  >
                    <MenuItem value="AFFIRMATIVE">Affirmative</MenuItem>
                    <MenuItem value="UNANIMOUS">Unanimous</MenuItem>
                    <MenuItem value="CONSENSUS">Consensus</MenuItem>
                  </DropdownField>
                </Box>
              </DialogContent>

              <DialogActions>
                <Button
                  color="secondary"
                  onClick={() => onClose()}
                  variant="text"
                >
                  Cancel
                </Button>
                <LoadingButton
                  disabled={!(isValid && dirty) || isSubmitting}
                  loading={createPermissionMutation.isLoading}
                  type="submit"
                  variant="text"
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};

export { PermissionFormDialog };
