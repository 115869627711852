import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { RoleUpdate } from '@bom-nextgen-keycloak/models';
import { selectPermissionRoleDetail } from '@bom-nextgen-keycloak/web/core';
import {
  RoleForm,
  validationSchema,
} from '@bom-nextgen-keycloak/web/pages/feature-roles';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  FormActionsGroup,
  FormSpinner,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import { useRoleDetail2 } from '../../hooks';
import { PARENT_PATH } from '../../shared';

type Form = {
  description: string;
  name: string;
};

const General: FC = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, updateMutation } = useRoleDetail2(id);
  const { isEditGeneralTab: canEdit } = useSelector(selectPermissionRoleDetail);

  const initialValues: Form = {
    name: data?.name ?? '',
    description: data?.description ?? '',
  };

  const handleUpdate = (payload: RoleUpdate) => {
    updateMutation.mutate({
      roleId: id,
      payload: {
        ...payload,
        attributes: data?.attributes,
      },
    });
  };

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  return isLoading ? (
    <FormSpinner />
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(payload: Form, { setSubmitting }: FormikHelpers<Form>) => {
        handleUpdate({
          ...data,
          description: payload.description,
          id: data?.id ?? '',
          name: payload.name,
        });

        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, resetForm }) => {
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: updateMutation.isLoading,
          isValid,
        });

        return (
          <Form>
            <RoleForm isEdit />
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={updateMutation.isLoading}
              onCancel={handleCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const WrappedRoleGeneralPage: FC = () => {
  const { canViewGeneralTab } = useSelector(selectPermissionRoleDetail);

  return (
    <ProtectedRouter
      canView={canViewGeneralTab}
      feature={FeaturePage.ROLE}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <General />
    </ProtectedRouter>
  );
};

export { WrappedRoleGeneralPage };
