import { CompanySearchQuery, ICompanyList } from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export const fetchCompanyList = (groupId: string, params: CompanySearchQuery) =>
  axiosInstance
    .get(`/groups/${groupId}/companies`, { params: { ...params } })
    .then(({ data }: AxiosResponse<ICompanyList>) => data);

export const fetchCompanyCompactList = (
  groupId: string,
  params: CompanySearchQuery
) =>
  axiosInstance
    .get(`/groups/${groupId}/companies-compact`, { params: { ...params } })
    .then(({ data }: AxiosResponse<ICompanyList>) => data);
