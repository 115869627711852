import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPermissionEvent } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  FormSpinner,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { usePermissionEventDetail2 } from '../../../hooks';
import { PARENT_PATH } from '../../../shared';
import { GeneralGroup } from '../GeneralGroup';
import { GeneralSummary } from '../GeneralSummary';
import { GeneralUser } from '../GeneralUser';
import { Section, SectionHead } from './General.styled';

const General: FC = () => {
  const { id = '' } = useParams();
  const { data, isLoading } = usePermissionEventDetail2(id);

  return isLoading ? (
    <FormSpinner />
  ) : (
    <Fragment>
      <Section>
        <SectionHead>Summary</SectionHead>
        <GeneralSummary {...data} />
      </Section>
      <Section>
        <SectionHead>User</SectionHead>
        <GeneralUser {...data} />
      </Section>
      <Section>
        <SectionHead>Groups</SectionHead>
        <GeneralGroup {...data} />
      </Section>
    </Fragment>
  );
};

const WrappedPermissionEventGeneralPage: FC = () => {
  const { canViewPermission } = useSelector(selectPermissionEvent);

  return (
    <ProtectedRouter
      canView={canViewPermission}
      feature={FeaturePage.EVENT}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <General />
    </ProtectedRouter>
  );
};

export { WrappedPermissionEventGeneralPage };
