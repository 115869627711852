import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { IUserRepresentation } from '@bom-nextgen-keycloak/models';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  Loading,
  NotFound2,
  PaginationPro,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';

type UserTableSortKey = Pick<
  IUserRepresentation,
  | 'createdTimestamp'
  | 'email'
  | 'enabled'
  | 'enabledSSO'
  | 'firstName'
  | 'id'
  | 'username'
>;

type UserTableProps = {
  canDelete?: boolean;
  canEdit?: boolean;
  canRemove?: boolean;
  data: IUserRepresentation[];
  enabledDelete?: boolean;
  enabledEdit?: boolean;
  enabledRemove?: boolean;
  isFetched?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  isProcessing?: boolean;
  isSearch?: boolean;
  isSearching?: boolean;
  onDelete?: (user: IUserRepresentation) => void;
  onPageChange: (offset: number) => void;
  onRemove?: (user: IUserRepresentation) => void;
  onStepChange: (event: SelectChangeEvent<unknown>) => void;
  paginationOffset?: number;
  paginationStep?: number;
  totalCount?: number | undefined;
  totalRecord: number;
  wasSearch?: boolean;
};

const headCells: HeadCell<keyof UserTableSortKey>[] = [
  {
    disablePadding: false,
    id: 'firstName',
    label: 'Name',
    numeric: false,
    sortKey: 'firstName',
  },
  {
    disablePadding: false,
    id: 'username',
    label: 'Username',
    numeric: false,
    sortKey: 'username',
  },
  {
    disablePadding: false,
    id: 'email',
    label: 'Email',
    numeric: false,
    sortKey: 'email',
  },
  {
    disablePadding: false,
    id: 'createdTimestamp',
    label: 'Created on',
    numeric: false,
    sortKey: 'createdTimestamp',
  },
  {
    disablePadding: false,
    id: 'enabled',
    label: 'Status',
    numeric: false,
    sortKey: 'enabled',
  },
  {
    disablePadding: false,
    id: 'enabledSSO',
    label: 'SSO',
    numeric: false,
    sortKey: 'enabledSSO',
  },
  {
    disablePadding: false,
    id: 'actions',
    label: 'Actions',
    numeric: false,
    sortKey: 'id',
  },
];

const UserTable2: FC<UserTableProps> = ({
  canDelete = false,
  canEdit = false,
  canRemove = false,
  data,
  enabledDelete = true,
  enabledEdit = true,
  enabledRemove = true,
  isFetched = false,
  isFetching = true,
  isLoading = true,
  isProcessing = true,
  isSearch = false,
  isSearching = false,
  paginationOffset = 0,
  paginationStep = 10,
  onPageChange,
  onStepChange,
  onDelete,
  onRemove,
  totalCount = undefined,
  totalRecord = 0,
  wasSearch = false,
}) => {
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof UserTableSortKey>('createdTimestamp');

  const isEmpty = data.length === 0;
  const columnLength = headCells.length;
  const showSkeleton = isProcessing || wasSearch;
  const showHeader =
    isProcessing || wasSearch || (!isProcessing && totalRecord > 0);
  const showDeleteButton = canDelete && enabledDelete;
  const showEditButton = canEdit && enabledEdit;
  const showRemoveButton = canRemove && enabledRemove;

  const handleSort = (
    event: MouseEvent<unknown>,
    property: keyof UserTableSortKey
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      {isSearching ? (
        <Loading title="Searching..." />
      ) : (
        <Fragment>
          <TableContainer>
            <Table
              aria-label="user table"
              aria-labelledby="tableTitle"
              stickyHeader
            >
              {showHeader && (
                <EnhancedTableHead
                  headCells={headCells}
                  onRequestSort={handleSort}
                  order={order}
                  orderBy={orderBy}
                />
              )}
              <TableBody>
                <TableRowSkeleton
                  cellLength={columnLength}
                  loading={showSkeleton}
                >
                  {!isEmpty ? (
                    stableSort<IUserRepresentation>(
                      data,
                      getComparator(order, orderBy)
                    ).map((row) => {
                      const {
                        createdTimestamp,
                        email,
                        enabled,
                        enabledSSO,
                        firstName,
                        id,
                        lastName,
                        username,
                      } = row;
                      const name = `${firstName} ${lastName}`;

                      return (
                        <TableRow key={id}>
                          <TableCell title={name}>
                            <StyledTextEllipsis>{name}</StyledTextEllipsis>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {username || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell title={email}>
                            <StyledTextEllipsis>
                              {email || '-'}
                            </StyledTextEllipsis>
                          </TableCell>
                          <TableCell>
                            {format(
                              new Date(createdTimestamp || ''),
                              'MM/dd/yyyy'
                            )}
                          </TableCell>
                          <TableCell sx={{ width: 100 }}>
                            <Tooltip title={enabled ? 'Active' : 'Inactive'}>
                              {enabled ? (
                                <DoneIcon color="success" fontSize="small" />
                              ) : (
                                <HighlightOffIcon
                                  color="error"
                                  fontSize="small"
                                />
                              )}
                            </Tooltip>
                          </TableCell>
                          <TableCell sx={{ width: 100 }}>
                            <Tooltip
                              title={enabledSSO ? 'Enabled' : 'Disabled'}
                            >
                              {enabledSSO ? (
                                <DoneIcon color="success" fontSize="small" />
                              ) : (
                                <HighlightOffIcon
                                  color="error"
                                  fontSize="small"
                                />
                              )}
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            {showEditButton && (
                              <Link to={`/users/${id}`}>
                                <Tooltip title="Edit user">
                                  <IconButton
                                    aria-label="edit user"
                                    size="small"
                                  >
                                    <EditIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            )}
                            {showRemoveButton && onRemove && (
                              <Tooltip title="Remove user">
                                <IconButton
                                  aria-label="remove user"
                                  onClick={() => onRemove(row)}
                                  size="small"
                                >
                                  <PersonRemoveIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {showDeleteButton && onDelete && (
                              <Tooltip title="Delete user">
                                <IconButton
                                  aria-label="delete user"
                                  onClick={() => onDelete(row)}
                                  size="small"
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columnLength}>
                        <NotFound2 />
                      </TableCell>
                    </TableRow>
                  )}
                </TableRowSkeleton>
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationPro
            isFetched={isFetched}
            isFetching={isFetching}
            isLoading={isLoading}
            isProcessing={isProcessing}
            isSearch={isSearch}
            isSearching={isSearching}
            onPageChange={onPageChange}
            onStepChange={onStepChange}
            paginationOffset={paginationOffset}
            paginationStep={paginationStep}
            totalCount={totalCount}
            totalRecord={totalRecord}
            wasSearch={wasSearch}
          />
        </Fragment>
      )}
    </Paper>
  );
};

export { UserTable2 };
