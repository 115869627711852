import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { isEmpty } from 'lodash';
import { Fragment } from 'react';
import { useResource } from '@bom-nextgen-keycloak/web/core';
import {
  AddButton,
  DeleteButton,
  EditButton,
  ExportButton,
  FormSpinner,
  RefreshButton,
  ResetButton,
  Searchbox,
  Toolbar,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { checkProcessing } from '@bom-nextgen-keycloak/web/shared/util';
import {
  ExportFormDialog as ExportDialog,
  GroupDeleteDialog as DeleteDialog,
  GroupFormDialog as CreateDialog,
  GroupList,
} from './components';
import { useGroup2 } from './hooks';
import { Content, Info, Sublist, SublistItem } from './Group2.styled';

const Group2 = () => {
  const resource = useResource();
  const {
    canAdd,
    canDelete,
    canEdit,
    canExport,
    data,
    handleCreateAction,
    handleCreateCancel,
    handleCreateRequest,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    handleEditRequest,
    handleExportCancel,
    handleExportRequest,
    handleNodeSelect,
    handleRefresh,
    handleSearchChange,
    handleSearchClear,
    handleSearchEnter,
    handleSearchReset,
    isCreateDialogOpen,
    isDeleteDialogOpen,
    isDeleting,
    isExportDialogOpen,
    isFetched,
    isFetching,
    isLoading,
    isSearch,
    isSearching,
    isSelected,
    searchQuery,
    selectedNode,
    wasSearch,
  } = useGroup2();
  const id = selectedNode?.id ?? '';
  const level = selectedNode?.level ?? '';
  const name = selectedNode?.name ?? '';
  const path = selectedNode?.path ?? '';
  const children = selectedNode?.subGroups?.length ?? 0;
  const groupAttributes = selectedNode?.attributes ?? {};
  const groupRoles = selectedNode?.clientRoles ?? {};
  const subGroups = children
    ? selectedNode?.subGroups?.map((group) => group.name?.toLocaleLowerCase())
    : [];
  const showAddButton = canAdd;
  const showDeleteButton = canDelete;
  const showEditButton = canEdit;
  const showExportButton = canExport;
  const hasGroupDirectory = subGroups?.includes('groups');
  const hasSubscriptionDirectory = subGroups?.includes('subscriptions');
  const isCompleted = hasGroupDirectory && hasSubscriptionDirectory;
  const isApplication = level === 1;
  const isCompany = level === 2;
  const isDirectory = level === 3;
  const isEntity = level === 4;
  const isSubscriptionDirectory = isDirectory && name === 'Subscriptions';
  const isGroupDirectory = isDirectory && name === 'Groups';
  const isSubscriptionEntity = isEntity && path.includes('Subscriptions');
  const isGroupEntity = isEntity && path.includes('Groups');
  const hasAttributes = !isEmpty(groupAttributes);
  const hasRoles = !isEmpty(groupRoles);
  const hasSubscription = isSubscriptionEntity && hasAttributes;
  const disabledAdd = !isSelected || isEntity || isCompleted;
  const disabledExport = !isSelected;
  const disabledMutation = !isSelected || isApplication;
  const disabledEdit = disabledMutation;
  const disabledDelete = disabledMutation || !selectedNode;
  let type: string | null = null;

  switch (true) {
    case isSubscriptionEntity:
      type = 'Subscription';
      break;
    case isGroupEntity:
      type = 'Group';
      break;
    case isSubscriptionDirectory:
      type = 'Subscription directory';
      break;
    case isGroupDirectory:
      type = 'Group directory';
      break;
    case isCompany:
      type = 'Company';
      break;
    case isApplication:
      type = 'Application';
      break;
    case level === '' || name === '' || path === '':
    default:
      type = null;
  }

  const getSubscription = (name: string): string => {
    if (hasSubscription) {
      return groupAttributes[`${resource.clientName}.${name}`][0];
    }

    return '';
  };

  const getRoles = (): string[] => {
    if (hasRoles) {
      return groupRoles[`${resource.clientName}`];
    }

    return [];
  };

  const subscription = {
    demo: getSubscription('demo') === 'true',
    enabled: getSubscription('enabled') === 'true',
    endDate: getSubscription('endDate'),
    startDate: getSubscription('startDate'),
    trial: getSubscription('trial') === 'true',
    unlimited: getSubscription('unlimited') === 'true',
  };

  const handleDelete = () => {
    if (selectedNode) {
      handleDeleteRequest(selectedNode);
    } else {
      // TODO: Implement a handler
    }
  };

  const renderListItem = (
    condition: string | number | boolean | null,
    title: string,
    value: string | number | null
  ) => {
    if (condition) {
      return (
        <ListItem>
          <ListItemText primary={title} secondary={value} />
        </ListItem>
      );
    }

    return null;
  };

  const isProcessing = checkProcessing({ isFetched, isFetching, isLoading });
  const isBusy = isSearching || wasSearch || isProcessing;
  const showInfo = (id || level || name || path) && !isBusy;

  return (
    <Fragment>
      <Toolbar>
        <Toolbar.Control>
          <Toolbar.Search>
            <Searchbox
              autoFocus
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              onEnter={handleSearchEnter}
              placeholder="Search by name"
              value={searchQuery}
            />
            <ResetButton disabled={!isSearch} onClick={handleSearchReset}>
              Reset Search
            </ResetButton>
          </Toolbar.Search>
          <Toolbar.Action>
            <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>
            {showExportButton && (
              <ExportButton
                disabled={disabledExport}
                onClick={handleExportRequest}
              >
                Export
              </ExportButton>
            )}

            {showEditButton && (
              <EditButton disabled={disabledEdit} onClick={handleEditRequest}>
                Edit
              </EditButton>
            )}

            {showDeleteButton && (
              <DeleteButton disabled={disabledDelete} onClick={handleDelete}>
                Delete
              </DeleteButton>
            )}

            {showAddButton && (
              <AddButton disabled={disabledAdd} onClick={handleCreateRequest}>
                Add
              </AddButton>
            )}
          </Toolbar.Action>
        </Toolbar.Control>
      </Toolbar>

      <Content>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {isBusy ? (
              <FormSpinner title={isSearch ? 'Searching...' : 'Loading...'} />
            ) : (
              <GroupList groupDetail={data} onNodeSelected={handleNodeSelect} />
            )}
          </Grid>
          <Grid item xs={4}>
            {showInfo && (
              <Info>
                <List dense>
                  {renderListItem(id, 'ID', id)}
                  {renderListItem(level, 'Level', level)}
                  {renderListItem(type, 'Type', type)}
                  {renderListItem(isApplication, 'Companies', children)}
                  {renderListItem(
                    isSubscriptionDirectory,
                    'Subscriptions',
                    children
                  )}
                  {renderListItem(isGroupDirectory, 'Groups', children)}
                  {isGroupEntity && (
                    <ListItem
                      alignItems="flex-start"
                      sx={{ flexDirection: 'column' }}
                    >
                      <ListItemText primary="Roles" />
                      {hasRoles ? (
                        <Sublist>
                          {getRoles().map((role: string) => (
                            <SublistItem key={`role-${role}`}>
                              {role}
                            </SublistItem>
                          ))}
                        </Sublist>
                      ) : (
                        <Sublist>
                          <SublistItem>Undefined</SublistItem>
                        </Sublist>
                      )}
                    </ListItem>
                  )}
                  {isSubscriptionEntity && (
                    <ListItem
                      alignItems="flex-start"
                      sx={{ flexDirection: 'column' }}
                    >
                      <ListItemText primary="Subscription" />
                      {hasSubscription ? (
                        <Sublist>
                          <SublistItem>
                            {`${subscription.startDate}${
                              subscription.endDate && ' - '
                            }${subscription.endDate}`}
                          </SublistItem>
                          {subscription.demo && <SublistItem>Demo</SublistItem>}
                          {subscription.trial && (
                            <SublistItem>Trial</SublistItem>
                          )}
                          {subscription.unlimited && (
                            <SublistItem>Unlimited</SublistItem>
                          )}
                          <SublistItem>
                            {subscription.enabled ? 'Enabled' : 'Disabled'}
                          </SublistItem>
                        </Sublist>
                      ) : (
                        <Sublist>
                          <SublistItem>Undefined</SublistItem>
                        </Sublist>
                      )}
                    </ListItem>
                  )}
                </List>
              </Info>
            )}
          </Grid>
        </Grid>
      </Content>

      {selectedNode && (
        <CreateDialog
          onClose={handleCreateCancel}
          onSaveGroup={handleCreateAction}
          open={isCreateDialogOpen}
          selectedNode={selectedNode}
        />
      )}
      {selectedNode && (
        <ExportDialog
          groupId={id}
          onClose={handleExportCancel}
          open={isExportDialogOpen}
          titleForm={name}
        />
      )}
      <DeleteDialog
        loading={isDeleting}
        name={name}
        onClose={handleDeleteCancel}
        onSubmit={handleDeleteAction}
        open={isDeleteDialogOpen}
      />
    </Fragment>
  );
};

export { Group2 };
