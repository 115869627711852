import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const Content = styled('div')`
  margin-bottom: 1.25rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Heading = styled((props: TypographyProps) => (
  <Typography {...props} variant="h3" />
))`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const Wrapper = styled('div')``;
