import { FC, Fragment } from 'react';
import {
  RefreshButton,
  ResetButton,
  Searchbox,
  SelectButton,
  Toolbar,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { policyOptions } from './shared/constants';
import {
  AdvancedSearch,
  ClientFormDialog as CreateClientDialog,
  GroupFormDialog as CreateGroupDialog,
  PolicyDeleteDialog as DeleteDialog,
  PolicyFormDialog as CreateRoleDialog,
  PolicyTable2,
  UserFormDialog as CreateUserDialog,
} from './components/';
import { usePolicy2 } from './hooks/';

const Policy2: FC = () => {
  const {
    canAdd,
    canDelete,
    canEdit,
    data,
    handleAdvancedSearchChange,
    handleCreateAction,
    handleCreateCancel,
    handleCreateRequest,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    handlePageChange,
    handleRefresh,
    handleSearchChange,
    handleSearchClear,
    handleSearchEnter,
    handleSearchReset,
    handleStepChange,
    isCreateDialogOpen,
    isDeleteDialogOpen,
    isDeleting,
    isFetched,
    isFetching,
    isFilter,
    isLoading,
    isProcessing,
    isSearch,
    isSearching,
    paginationOffset: currentOffset,
    paginationStep: pageStep,
    params,
    searchQuery,
    selectedItem,
    selectedType,
    wasSearch,
  } = usePolicy2();
  const showAddButton = canAdd;

  return (
    <Fragment>
      <Toolbar>
        <Toolbar.Control>
          <Toolbar.Search>
            <Searchbox
              autoFocus
              name="name"
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              onEnter={handleSearchEnter}
              placeholder="Search by name"
              value={searchQuery}
            />
            <AdvancedSearch
              onApply={handleAdvancedSearchChange}
              query={params}
            />
            <ResetButton
              disabled={!isSearch && !isFilter}
              onClick={handleSearchReset}
            >
              Reset Search
            </ResetButton>
            <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>
          </Toolbar.Search>
          <Toolbar.Action>
            {showAddButton && (
              <Toolbar.Flexbox>
                <SelectButton
                  action="add"
                  label={{
                    button: 'add selectedItem button',
                    option: 'select selectedItem type',
                  }}
                  onClick={handleCreateRequest}
                  options={policyOptions}
                  title={{
                    click: 'Add selectedItem',
                    toggle: 'Select selectedItem type',
                  }}
                />
              </Toolbar.Flexbox>
            )}
          </Toolbar.Action>
        </Toolbar.Control>
      </Toolbar>
      <PolicyTable2
        canDelete={canDelete}
        canEdit={canEdit}
        data={data.items}
        isLoading={isLoading}
        isFetched={isFetched}
        isFetching={isFetching}
        isFilter={isFilter}
        isProcessing={isProcessing}
        isSearch={isSearch || isFilter}
        isSearching={isSearching}
        onDelete={handleDeleteRequest}
        onPageChange={handlePageChange}
        onStepChange={handleStepChange}
        paginationOffset={currentOffset}
        paginationStep={pageStep}
        totalCount={undefined}
        totalRecord={data.totalRecord}
        wasSearch={wasSearch}
      />
      <CreateClientDialog
        onClose={handleCreateCancel}
        onSave={handleCreateAction}
        open={isCreateDialogOpen && selectedType === 'client'}
      />
      <CreateGroupDialog
        onClose={handleCreateCancel}
        onSave={handleCreateAction}
        open={isCreateDialogOpen && selectedType === 'group'}
      />
      <CreateRoleDialog
        onClose={handleCreateCancel}
        onSave={handleCreateAction}
        open={isCreateDialogOpen && selectedType === 'role'}
      />
      <CreateUserDialog
        onClose={handleCreateCancel}
        onSave={handleCreateAction}
        open={isCreateDialogOpen && selectedType === 'user'}
      />
      <DeleteDialog
        loading={isDeleting}
        name={selectedItem?.name}
        onClose={handleDeleteCancel}
        onSubmit={handleDeleteAction}
        open={isDeleteDialogOpen}
      />
    </Fragment>
  );
};

export { Policy2 };
