import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { FC, Fragment } from 'react';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { Actions, Wrapper } from './TabbedContent.styled';

export type TabList = {
  enabled?: boolean;
  label: string;
  value: string;
}[];

type TabbedContentProps = {
  actions?: React.ReactNode;
  breadcrumb?: string;
  feature: string;
  parentPath: string;
  rootPath: string;
  tabList: TabList;
};

const TabbedContent: FC<TabbedContentProps> = ({
  actions,
  breadcrumb = '...',
  feature,
  parentPath,
  rootPath,
  tabList,
}) => {
  const { pathname } = useLocation();
  const selectedTab = pathname;

  const renderTab = (list: TabList, roothPath: string) =>
    list.map((item, index) => {
      const { enabled, label, value } = item;
      const tabValue = value ? `${rootPath}/${value}` : rootPath;

      return (
        <Tab
          component={RouterLink}
          disabled={!enabled}
          key={index}
          label={label}
          to={tabValue}
          value={tabValue}
        />
      );
    });

  return (
    <Fragment>
      <Wrapper>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Breadcrumbs>
            <Link color="inherit" component={RouterLink} to={parentPath}>
              {feature}
            </Link>
            <Typography color="primary">{breadcrumb}</Typography>
          </Breadcrumbs>
          <Actions>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={2}
            >
              {actions}
            </Stack>
          </Actions>
        </Stack>
      </Wrapper>
      <Box mb={3}>
        <AppBar position="static">
          <Tabs
            aria-label="user-tabs"
            indicatorColor="secondary"
            textColor="inherit"
            value={selectedTab}
          >
            {renderTab(tabList, rootPath)}
          </Tabs>
        </AppBar>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Fragment>
  );
};

export { TabbedContent };
