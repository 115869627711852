import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const Date = styled((props: TypographyProps) => (
  <Typography {...props} component="span" />
))`
  color: ${grey[600]};
`;

export const Description = styled('div')`
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 0.8125rem;
  height: 92px;
  padding: 1rem 3rem;
`;

export const H3 = styled((props: TypographyProps) => (
  <Typography {...props} variant="h3" />
))`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const Release = styled('div')``;

export const Remark = styled('div')``;

export const Section = styled('div')`
  padding: 1rem 3rem 1rem 3rem;

  &:last-of-type {
    margin-bottom: 1.5rem;
  }
`;

export const SectionContent = styled('div')`
  font-size: 0.8125rem;

  & ul {
    margin: 0 0 1rem 0.5rem;
    padding: 0 0 0 1rem;

    &:last-of-type {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`;

export const SectionHeader = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Version = styled((props: TypographyProps) => (
  <Typography {...props} variant="h2" />
))`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1rem;
  font-weight: 400;
`;
