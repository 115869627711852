import { styled } from '@mui/material/styles';

export const TreeViewActions = styled('div')`
  padding-top: 1rem;
`;

export const TreeViewContainer = styled('div')``;

export const TreeViewContent = styled('div')`
  height: 294px;
  overflow: auto;
`;
