import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { useResource } from '@bom-nextgen-keycloak/web/core';
import { DialogPro } from '@bom-nextgen-keycloak/web/shared/ui';

type UserAddDialogProps = {
  loading: boolean;
  onJoin: () => void;
  onClose: () => void;
  open: boolean;
};

const UserAddDialog: FC<UserAddDialogProps> = ({
  loading,
  onClose,
  onJoin,
  open,
}) => {
  const resource = useResource();

  return (
    <DialogPro
      aria-describedby="add-user-dialog-description"
      aria-labelledby="add-user-dialog-title"
      onClose={onClose}
      open={open}
      width={480}
    >
      <DialogTitle id="add-user-dialog-title">Add SSO user</DialogTitle>
      <DialogContent>
        <DialogContentText id="add-user-dialog-description">
          This user already exists in another product(s), would you like to add
          him/her to <b>{resource.displayName}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          No
        </Button>
        <LoadingButton autoFocus loading={loading} onClick={onJoin}>
          Yes
        </LoadingButton>
      </DialogActions>
    </DialogPro>
  );
};

export { UserAddDialog };
