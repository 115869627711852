import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import { Progress } from '../Progress';
import { Box, Indicator, Title, Wrapper } from './Loading.styled';

export type LoadingProps = {
  title?: string;
};

const Loading: FC<LoadingProps> = ({ title }) => {
  return (
    <Wrapper>
      <Box>
        <Indicator>
          <CircularProgress size="32px" />
        </Indicator>
        <Title>
          <Progress loadingText={title} />
        </Title>
      </Box>
    </Wrapper>
  );
};

export { Loading };
