import { AuthorizationDto } from '@bom-nextgen-keycloak/models';
import { gatewayAxiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';
import { UserProfile } from '../models';

export const fetchPermission = () =>
  gatewayAxiosInstance
    .get('/authorization/permissions')
    .then(({ data }: AxiosResponse<AuthorizationDto>) => data);

export const fetchProfileUser = () =>
  gatewayAxiosInstance
    .get('/authorization/me')
    .then(({ data }: AxiosResponse<UserProfile>) => data);
