import { GroupUpdateDto } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchGroupById,
  QUERY_KEY,
  updateGroup,
  UpdateGroupParams,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

export const useGroupDetail = (groupId: string) => {
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading, data: groupDetail = {} } = useQuery(
    [QUERY_KEY.USER_DETAIL, groupId],
    () => fetchGroupById(groupId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get group detail';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
        navigate('/groups');
      },
    }
  );

  const updateGroupMutation = useMutation(updateGroup, {
    onSuccess: (data) => {
      setNotification({
        message: 'Group updated',
        type: 'success',
      });
      queryClient.invalidateQueries([QUERY_KEY.USER_DETAIL, groupId]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot update group detail';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleUpdateGroup = (payloadGroup: GroupUpdateDto | undefined) => {
    if (payloadGroup) {
      const payload: UpdateGroupParams = {
        id: groupId,
        payload: { ...payloadGroup, attributes: groupDetail?.attributes },
      };
      updateGroupMutation.mutate(payload);
    }
  };

  const handleSaveAttribute = (attributes: any) => {
    if (groupDetail) {
      const payloadUser: GroupUpdateDto = {
        id: groupDetail.id,
        name: groupDetail.name || '',
        path: groupDetail.path,
        subGroups: groupDetail.subGroups || [],
        clientRoles: groupDetail.clientRoles || {},
        attributes: { ...attributes },
      };
      const payload: UpdateGroupParams = {
        id: groupId,
        payload: payloadUser,
      };
      updateGroupMutation.mutate(payload);
    }
  };

  const regex = new RegExp(`(/Subscriptions/${groupDetail?.name})`, 'g');
  const isSubscription = groupDetail.path?.match(regex)?.length === 1;

  return {
    isLoading,
    isLoadingSubmit: updateGroupMutation.isLoading,
    groupDetail,
    isSubscription,
    handleUpdateGroup,
    handleSaveAttribute,
  };
};
