import CardHeader from '@mui/material/CardHeader';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const Placeholder = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 227px;
  width: 320px;
`;

export const Header = styled(CardHeader)`
  &.MuiCardHeader-root {
    background-color: ${grey[50]};
    padding-bottom: 8px;
    padding-top: 8px;
  }

  & .MuiCardHeader-title {
    font-size: 0.8125rem;
  }

  & .MuiCardHeader-subheader {
    font-size: 0.75rem;
  }
`;
