import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile } from '@bom-nextgen-keycloak/web/shared/data-access';
import { RootState } from '../store';

type AccountState = {
  authenticated: boolean;
  authorized: boolean;
  profile: UserProfile | null;
};

const initialState: AccountState = {
  authenticated: false,
  authorized: false,
  profile: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetAccount: (state) => {
      state.authenticated = false;
      state.authorized = false;
      state.profile = null;
    },
    setAuthenticated: (state) => {
      state.authenticated = true;
    },
    setAuthorized: (state) => {
      state.authorized = true;
    },
    setProfile: (state, action: PayloadAction<UserProfile>) => {
      state.profile = action.payload;
    },
    setUnauthenticated: (state) => {
      state.authenticated = false;
    },
    setUnauthorized: (state) => {
      state.authorized = false;
    },
  },
});

export const {
  resetAccount,
  setAuthenticated,
  setAuthorized,
  setProfile,
  setUnauthenticated,
  setUnauthorized,
} = accountSlice.actions;

export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;
