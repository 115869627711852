import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)`
  & div.MuiDialog-paper {
    width: 50rem;
    max-width: 50rem;
  }
`;

export const LoaddingContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerGroup = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled('label')`
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 1rem;
`;
