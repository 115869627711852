import { styled } from '@mui/material/styles';

export const Capital = styled('span')`
  text-transform: capitalize;
`;

export const Data = styled('td')`
  padding-bottom: 5px;
  text-align: left;
`;

export const Head = styled('th')<{ width?: number }>`
  font-weight: normal;
  padding-bottom: 5px;
  text-align: left;
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
`;

export const Row = styled('tr')``;

export const Table = styled('table')`
  border-spacing: 0;
  font-size: 0.875rem;
`;
