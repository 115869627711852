import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { EventType } from '@bom-nextgen-keycloak/models';
import { loginTypeOptions } from '../../shared/constants';
import { FilterButton } from './Filter.styled';

type FilterType = {
  onChange: (value: EventType) => void;
  value?: EventType;
};

const Filter: FC<FilterType> = ({
  onChange,
  value = loginTypeOptions.success.value,
}) => {
  const [selectedItem, setSelectedItem] = useState<EventType>(
    value as EventType
  );
  const buttonStyle = { padding: '5px 15px' };

  useEffect(() => {
    setSelectedItem(value as EventType);
  }, [value]);

  const handleOptionChange = (
    event: MouseEvent<HTMLElement>,
    value: EventType
  ) => {
    if (value !== null) {
      setSelectedItem(value);
      onChange(value);
    }
  };

  return (
    <ToggleButtonGroup
      aria-label="login status"
      exclusive
      onChange={handleOptionChange}
      size="small"
      value={selectedItem}
    >
      <FilterButton
        aria-label="success"
        color="success"
        sx={{ ...buttonStyle }}
        value={loginTypeOptions.success.value}
      >
        {loginTypeOptions.success.label}
      </FilterButton>
      <FilterButton
        aria-label="error"
        color="error"
        sx={{ ...buttonStyle }}
        value={loginTypeOptions.error.value}
      >
        {loginTypeOptions.error.label}
      </FilterButton>
    </ToggleButtonGroup>
  );
};

export { Filter };
