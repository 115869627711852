import Button from '@mui/material/Button';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectPermissionRoleDetail } from '@bom-nextgen-keycloak/web/core';
import {
  RoleDeleteDialog as DeleteDialog,
  useRole2,
} from '@bom-nextgen-keycloak/web/pages/feature-roles';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ProtectedRouter,
  TabbedContent,
  TabList,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { useRoleDetail2 } from './hooks';
import { PARENT_PATH } from './shared';

const RoleDetail2: FC = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const {
    canDelete,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    isDeleteDialogOpen,
    isDeleting,
    selectedItem,
  } = useRole2({
    callback: () => navigate(PARENT_PATH),
    disableQuery: true,
  });
  const { data, isLoading } = useRoleDetail2(id);
  const name = data?.name ?? '';
  const { canViewAttributesTab, canViewGeneralTab, canViewUserTab } =
    useSelector(selectPermissionRoleDetail);
  const parentPath = PARENT_PATH;
  const rootPath = `${parentPath}/${id}`;
  const showDeleteButton = canDelete;
  const disableDeleteButton = isLoading;

  const tabList: TabList = [
    {
      enabled: canViewGeneralTab,
      label: 'General',
      value: '',
    },
    {
      enabled: canViewAttributesTab,
      label: 'Attributes',
      value: 'attributes',
    },
    {
      enabled: canViewUserTab,
      label: 'Users in Role',
      value: 'users',
    },
  ];

  const handleDelete = () => {
    if (name) {
      handleDeleteRequest(name);
    }
  };

  const renderActions = () => {
    return showDeleteButton ? (
      <Button
        color="error"
        disabled={disableDeleteButton}
        onClick={handleDelete}
        variant="text"
      >
        Delete
      </Button>
    ) : null;
  };

  return (
    <Fragment>
      <TabbedContent
        actions={renderActions()}
        breadcrumb={data?.name}
        feature="Roles"
        parentPath={parentPath}
        rootPath={rootPath}
        tabList={tabList}
      />
      <DeleteDialog
        loading={isDeleting}
        name={selectedItem}
        onClose={handleDeleteCancel}
        onSubmit={handleDeleteAction}
        open={isDeleteDialogOpen}
      />
    </Fragment>
  );
};

const WrappedRoleDetailPage: FC = () => {
  const { canViewPage } = useSelector(selectPermissionRoleDetail);

  return (
    <ProtectedRouter
      canView={canViewPage}
      feature={FeaturePage.ROLE}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <RoleDetail2 />
    </ProtectedRouter>
  );
};

export { WrappedRoleDetailPage };
