import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

type Component = FC & {
  Criteria: FC;
  Result: FC;
};

const Criteria: FC = ({ children }) => (
  <Box mb={2}>
    <Typography mb={1.5} variant="subtitle2">
      Criteria
    </Typography>
    {children}
  </Box>
);

const Result: FC = ({ children }) => (
  <Box paddingTop={2}>
    <Typography mb={1.5} variant="subtitle2">
      Result
    </Typography>
    {children}
  </Box>
);

const SearchInfo: Component = ({ children }) => {
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="search-info"
        expandIcon={<ExpandMoreIcon />}
        id="search-info"
      >
        <Typography color="primary" variant="body2">
          Search Summary
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

SearchInfo.Criteria = Criteria;
SearchInfo.Result = Result;

export { SearchInfo };
