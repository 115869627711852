import Stack from '@mui/material/Stack';
import { FC } from 'react';

const FormActions: FC = ({ children }) => (
  <Stack direction="row" justifyContent="space-between">
    {children}
  </Stack>
);

export { FormActions };
