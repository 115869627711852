import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Actions = styled('div')``;

export const Description = styled('div')`
  align-items: bottom;
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 1rem;
`;

export const Header = styled('header')`
  margin-bottom: 1rem;
`;

export const Help = styled('div')`
  margin-bottom: 1.5rem;
`;

export const Main = styled('main')``;

export const Support = styled('div')`
  margin-bottom: 1.5rem;
`;

export const Title = styled(Typography)`
  font-size: 2rem;
  margin-bottom: 1.5rem;
`;

export const Wrapper = styled('div')`
  padding-top: 2rem;
`;
