import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns-tz';
import { FC } from 'react';
import { LoggingDto } from '@bom-nextgen-keycloak/models';
import { Capitalize, TableCellHead } from '../General/General.styled';

const GeneralSummary: FC<Partial<LoggingDto>> = (props) => (
  <TableContainer component={Paper}>
    <Table aria-label="permission event summary table">
      <TableBody>
        <TableRow>
          <TableCellHead sx={{ width: 150 }}>Event ID</TableCellHead>
          <TableCell>{props?.id || ''}</TableCell>
        </TableRow>
        <TableRow>
          <TableCellHead>Time</TableCellHead>
          <TableCell>
            {props?.createdAt
              ? format(new Date(props.createdAt), 'MM/dd/yyyy HH:mm:ss', {
                  timeZone: 'Asia/Bangkok',
                })
              : ''}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHead>Session state</TableCellHead>
          <TableCell>{props?.sessionState || '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCellHead>Status</TableCellHead>
          <TableCell>
            <Capitalize>{props?.status || '-'}</Capitalize>
          </TableCell>
        </TableRow>
        {props?.type && (
          <TableRow>
            <TableCellHead>Type</TableCellHead>
            <TableCell>{props.type}</TableCell>
          </TableRow>
        )}
        {props?.summary && (
          <TableRow>
            <TableCellHead>Description</TableCellHead>
            <TableCell title={props.summary}>{props.summary}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export { GeneralSummary };
