import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FC } from 'react';

const FormBox: FC = ({ children }) => {
  return (
    <Box mb={3}>
      <Stack direction="column" justifyContent="flex-start" spacing={2}>
        {children}
      </Stack>
    </Box>
  );
};

export { FormBox };
