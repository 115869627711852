import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const Capitalize = styled('span')`
  text-transform: capitalize;
`;

export const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[50],
  },
}));

export const StyledTableCellLight = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: none;
  }
`;
