import { FC, useEffect, useState } from 'react';

export type ProgressProps = {
  delay?: number;
  isSuffix?: boolean;
  loadingText?: string;
  waitingText?: string;
};

const PROGRESS_TEXT = {
  loading: 'Loading',
  suffix: '...',
  waiting: 'One moment',
};

const Progress: FC<ProgressProps> = ({
  delay = 3000,
  loadingText = PROGRESS_TEXT.loading,
  isSuffix = true,
  waitingText = PROGRESS_TEXT.waiting,
}) => {
  const [progressText, setProgressText] = useState(loadingText);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgressText(PROGRESS_TEXT.waiting);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay, loadingText, progressText, waitingText]);

  return (
    <span>
      {progressText}
      {isSuffix && PROGRESS_TEXT.suffix}
    </span>
  );
};

export { Progress };
