import { ResourceClientRegister } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  createResource,
  ErrorMessage,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  StyledDialogTitle,
} from '@bom-nextgen-keycloak/web/shared/ui';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { ResourceForm2, validationSchema2 } from '../ResourceForm2';
import { StyledDialog } from './ResourceFormDialog.styled';

type ResourceFormDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

type TypeResourceForm = {
  name: string;
  displayName: string;
  type: string;
  scopes: AutoCompleteOption[];
};

const initialValues: TypeResourceForm = {
  name: '',
  displayName: '',
  type: '',
  scopes: [],
};

const ResourceFormDialog: FC<ResourceFormDialogProps> = ({
  onClose,
  onSave,
  open,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const resource = useResource();

  const createReourceMutation = useMutation(createResource, {
    onSuccess: (data) => {
      setNotification({
        message: 'Resource created',
        type: 'success',
      });
      onSave();
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot create user';
      setAlertMessage({
        message:
          error.response?.status === 409
            ? 'Resource name already exists. try another name'
            : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  return (
    <StyledDialog
      onClose={() => onClose()}
      aria-labelledby="dialog-title"
      open={open}
    >
      <StyledDialogTitle id="dialog-title">Add resource</StyledDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: TypeResourceForm,
          { setSubmitting }: FormikHelpers<TypeResourceForm>
        ) => {
          const payload: ResourceClientRegister = {
            ...values,
            scopes: values.scopes.map((val) => ({
              id: val.value.id,
              name: val.value.name,
            })),
          };
          createReourceMutation.mutate({
            clientId: resource.clientId,
            payload: payload,
          });
          setSubmitting(false);
        }}
        validationSchema={validationSchema2}
        enableReinitialize
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          getFieldMeta,
        }) => (
          <Form>
            <DialogContent>
              <ResourceForm2
                setFieldTouched={setFieldTouched}
                getFieldMeta={getFieldMeta}
                setFieldValue={setFieldValue}
              />
            </DialogContent>

            <DialogActions>
              <Button color="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <LoadingButton
                data-test-id="btn-save-resource"
                disabled={!(isValid && dirty) || isSubmitting}
                loading={createReourceMutation.isLoading}
                type="submit"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export { ResourceFormDialog };
