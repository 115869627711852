import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Contacts, SideSheet } from '@bom-nextgen-keycloak/web/shared/ui';
import { mailTo } from '@bom-nextgen-keycloak/web/shared/util';
import { Content, H2, Section } from './Support.styled';

type SupportProps = {
  onClose: () => void;
  open: boolean;
};

const Support: FC<SupportProps> = ({ onClose, open }) => {
  const mailLink = mailTo({ subject: '[BOMSC] Feedback' });

  return (
    <SideSheet onClose={onClose} open={open} title="Support" width={480}>
      <Section>
        <H2>Contacts</H2>
        <Contacts />
      </Section>
      <Section>
        <H2>Send feedback</H2>
        <Content>
          <Typography mb={1}>
            Have feedback? We'd love to hear it, but please don't share
            sensitive information.
          </Typography>
          <Box>
            <Typography>
              <Link href={mailLink}>Send</Link>
            </Typography>
          </Box>
        </Content>
      </Section>
    </SideSheet>
  );
};

export { Support };
