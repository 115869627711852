export const errorTypeOptions = [
  { label: 'Company', value: 'company' },
  { label: 'Keycloak', value: 'keycloak' },
  { label: 'Subscription', value: 'subscription' },
  { label: 'User status', value: 'userEnabled' },
];

export const loginTypeOptions = {
  error: {
    label: 'Error',
    value: 'LOGIN_ERROR',
  },
  success: {
    label: 'Success',
    value: 'LOGIN',
  },
};

export const statusOptions = [
  { label: 'Error', value: 'error' },
  { label: 'Success', value: 'success' },
];
