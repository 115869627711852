import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  PolicyRepresentationDto,
  PolicyUpdateDto,
} from '@bom-nextgen-keycloak/models';
import {
  DECISION_STRATEGIES,
  POLICY_LOGICS,
  POLICY_TYPES,
  selectPermissionPolicyDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { UserFormTable } from '@bom-nextgen-keycloak/web/pages/feature-policy';
import {
  ErrorMessage,
  fetchUser,
  fetchUserDetail,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  Capitalize,
  DropdownFieldPro,
  FormActionsGroup,
  FormBox,
  FormField,
  FormText,
  sortAutocompleteOption,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getButtonStatus,
  getFieldError,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';
import { CONFIG } from '../../shared';
import { UserForm } from './config';

const FORM_FIELDS = { ...CONFIG.form.fields };

type GeneralUserType = {
  data: PolicyRepresentationDto | null;
  isUpdating: boolean;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (payload: PolicyUpdateDto) => void;
};

const GeneralUser: FC<GeneralUserType> = ({
  data,
  isUpdating,
  loading,
  onCancel,
  onSubmit,
}) => {
  const params = useParams();
  const { type = 'undefined' } = params;
  const { groupId } = useResource();
  const { isEditGeneralTab: canEdit } = useSelector(
    selectPermissionPolicyDetail
  );
  const { setAlertMessage } = useAlertMessage();
  const [selectedUsers, setSelectedUsers] = useState<AutoCompleteOption[]>([]);
  const [lookupUsers, setLookupUsers] = useState<AutoCompleteOption[]>([]);
  const [currentUserList, setCurrentUserList] = useState<AutoCompleteOption[]>(
    []
  );
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [autocompletePlaceholder, setAutocompletePlaceholder] = useState(
    FORM_FIELDS.users.placeholder
  );
  const debouncedSearchQuery = useDebounce(
    searchQuery,
    CONFIG.ui.debounceDelay
  );
  const disableField = !canEdit;
  const users = data?.users || [];
  const hasUsers = Boolean(users.length);
  const hasCurrentSearchTerm = currentSearchTerm !== '';
  const hasCurrentUserList = currentUserList.length;
  const hasUserList = hasCurrentSearchTerm && hasCurrentUserList;
  const queryKeys = {
    userDetail: [QUERY_KEY.USER_DETAIL, 'POLICY_GENERAL_UBAC', users],
    userList: [QUERY_KEY.USER, 'POLICY_GENERAL_UBAC', debouncedSearchQuery],
  };

  const userDetailQuery = useQuery(
    queryKeys.userDetail,
    async () => {
      return Promise.all(
        users.map(async (item) => {
          const user = await fetchUserDetail(item, groupId);
          const { email = '', firstName = '', id, lastName = '' } = user;
          const name = `${firstName}${firstName ? ' ' : ''}${lastName}`;

          const payload: AutoCompleteOption = {
            label: user?.username ?? 'undefined',
            value: {
              email,
              id,
              name,
            },
          };

          return payload;
        })
      );
    },
    {
      enabled: hasUsers,
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get user detail';

        setAlertMessage({ message, typeStatusMessage: 'error' });
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const userListQuery = useQuery(
    queryKeys.userList,
    async () => {
      const users = await fetchUser({
        ...CONFIG.pagination,
        search: debouncedSearchQuery,
      });

      const payload = users.items.map((item) => ({
        label: item?.username ?? 'undefined',
        value: {
          id: item?.id ?? 'undefined',
        },
      }));

      return payload;
    },
    {
      enabled: debouncedSearchQuery !== '',
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get resources';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
      onSettled: () => {
        setCurrentSearchTerm(searchQuery);
      },
      onSuccess: (data) => {
        if (data.length) {
          const selectedIds: string[] = selectedUsers.map(
            (item) => item.value.id || 'undefinded'
          );

          const filteredUsers = data.filter((item) => {
            return !selectedIds.includes(item.value.id);
          });

          const mergedUsers: AutoCompleteOption[] = [
            ...filteredUsers,
            ...selectedUsers,
          ];

          setCurrentUserList(sortAutocompleteOption(filteredUsers));
          setLookupUsers(sortAutocompleteOption(mergedUsers));
        } else {
          setCurrentUserList([]);
          setLookupUsers(sortAutocompleteOption(selectedUsers));
        }
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const resetAutocompletePlaceholder = () => {
    setAutocompletePlaceholder(FORM_FIELDS.users.placeholder);
  };

  const resetAutocompleteQuery = () => {
    setCurrentSearchTerm('');
    resetAutocompletePlaceholder();
  };

  const handleAutocompleteBlur = () => {
    const prefix = FORM_FIELDS.users.placeholder;
    const keyword = currentSearchTerm;

    if (hasCurrentSearchTerm && !hasCurrentUserList) {
      resetAutocompleteQuery();
    }

    if (hasUserList) {
      setAutocompletePlaceholder(
        `${prefix} or click to view options for "${keyword}"`
      );
    }
  };

  const handleAutocompleteClose = () => {
    if (hasUserList) {
      resetAutocompletePlaceholder();
    }
  };

  const handleAutocompleteOpen = () => {
    const prefix = FORM_FIELDS.users.placeholder;
    const keyword = currentSearchTerm;

    if (hasUserList) {
      setAutocompletePlaceholder(
        `${prefix} or select from the available options for "${keyword}"`
      );
    }
  };

  const handleAutocompleteOptionRemove = (
    callback: (field: string, value: AutoCompleteOption[]) => void,
    fieldName: string,
    optionItem: AutoCompleteOption,
    optionList: AutoCompleteOption[]
  ) => {
    if (optionItem) {
      const payload = optionList.filter(
        (item) => item.value.id !== optionItem.value.id
      );

      callback(fieldName, payload);
      setSelectedUsers(payload);
    }
  };

  const handleAutocompleteOptionChange = (value: AutoCompleteOption[]) => {
    setSelectedUsers(value);
  };

  const handleAutocompleteInputChange = (
    event: React.SyntheticEvent,
    value: string
  ) => {
    setSearchQuery(value);
  };

  const handleSubmit = (value: PolicyUpdateDto) => {
    resetAutocompleteQuery();
    onSubmit(value);
  };

  const renderAutocompleteSpinner = (isLoading: boolean) => {
    return isLoading ? <CircularProgress color="inherit" size={16} /> : null;
  };

  useEffect(() => {
    if (userDetailQuery.isSuccess && userDetailQuery.data) {
      const users = sortAutocompleteOption(userDetailQuery.data);

      setSelectedUsers(users);
      setLookupUsers(users);
    }
  }, [userDetailQuery.isSuccess, userDetailQuery.data]);

  const initialValues: UserForm = {
    description: data?.description || CONFIG.form.fields.description.value,
    logic: data?.logic || CONFIG.form.fields.logic.value,
    name: data?.name || CONFIG.form.fields.name.value,
    type: POLICY_TYPES.user,
    users: sortAutocompleteOption(userDetailQuery.data),
  };

  const userOptions = lookupUsers || [];
  const isUserOptionsLoading =
    userDetailQuery.isLoading || userListQuery.isLoading;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(
        payload: UserForm,
        { setSubmitting }: FormikHelpers<UserForm>
      ) => {
        const requestPayload: PolicyUpdateDto = {
          decisionStrategy: DECISION_STRATEGIES.unanimous.value,
          description: payload.description,
          id: data?.id || 'undefined',
          logic: payload.logic,
          name: payload.name,
          type: payload.type,
          users: payload.users.map((item) => item.value?.id || 'undefined'),
        };

        handleSubmit(requestPayload);
        setSubmitting(false);
      }}
    >
      {({
        dirty,
        getFieldMeta,
        isValid,
        resetForm,
        setFieldTouched,
        setFieldValue,
        values,
      }) => {
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: loading,
          isValid,
        });
        const { users } = values;
        const showSelectedList = users.length !== 0;
        const fieldProps = { disabled: disableField };
        const autocompleteInputProps: TextFieldProps = {
          fullWidth: true,
          InputLabelProps: {
            shrink: true,
          },
          variant: 'standard',
        };
        const fieldErrors = {
          user: getFieldError(getFieldMeta, FORM_FIELDS.roles.name),
        };

        return (
          <Form>
            <FormBox>
              <FormField>
                <InputLabel shrink>{FORM_FIELDS.type.label}</InputLabel>
                <FormText>
                  <Capitalize>{type}</Capitalize>-based
                </FormText>
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.name.label}
                  name={FORM_FIELDS.name.name}
                  required
                />
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.description.label}
                  name={FORM_FIELDS.description.name}
                  required
                />
              </FormField>
              <FormField>
                <Autocomplete
                  {...fieldProps}
                  ChipProps={{ size: 'small' }}
                  clearOnBlur
                  disableClearable
                  filterSelectedOptions
                  getOptionLabel={(option) => option.label}
                  id={FORM_FIELDS.users.name}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  limitTags={3}
                  loading={isUserOptionsLoading}
                  multiple
                  onBlur={handleAutocompleteBlur}
                  onChange={(event, value) => {
                    setFieldValue(FORM_FIELDS.users.name, value || []);
                    handleAutocompleteOptionChange(value);
                  }}
                  onClose={handleAutocompleteClose}
                  onInputChange={handleAutocompleteInputChange}
                  onOpen={handleAutocompleteOpen}
                  options={userOptions}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      {...fieldProps}
                      {...autocompleteInputProps}
                      error={fieldErrors.user.isError}
                      helperText={fieldErrors.user.helperText}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {renderAutocompleteSpinner(isUserOptionsLoading)}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                      label={FORM_FIELDS.users.label}
                      onBlur={() => {
                        setFieldTouched(FORM_FIELDS.users.name);
                      }}
                      placeholder={
                        isUserOptionsLoading
                          ? 'Loading...'
                          : autocompletePlaceholder
                      }
                      required={users.length === 0}
                    />
                  )}
                  value={users}
                />
              </FormField>

              {showSelectedList && (
                <FormField>
                  <UserFormTable
                    data={users}
                    disabled={disableField}
                    onRemove={(item: AutoCompleteOption) =>
                      handleAutocompleteOptionRemove(
                        setFieldValue,
                        FORM_FIELDS.users.name,
                        item,
                        users
                      )
                    }
                  />
                </FormField>
              )}

              <FormField>
                <DropdownFieldPro
                  {...fieldProps}
                  label={FORM_FIELDS.logic.label}
                  name={FORM_FIELDS.logic.name}
                  options={POLICY_LOGICS}
                  placeholder={FORM_FIELDS.logic.placeholder}
                  required
                />
              </FormField>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={isUpdating}
              onCancel={onCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export { GeneralUser };
