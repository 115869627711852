import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { FC, Fragment } from 'react';
import { SideSheet } from '@bom-nextgen-keycloak/web/shared/ui';
import RELEASE_NOTES from './release-notes.json';
import {
  Date,
  Description,
  H3,
  Release,
  Remark,
  Section,
  SectionContent,
  SectionHeader,
  Version,
} from './ReleaseNotes.stlyed';

type ReleaseNotesContent = {
  header: string;
  list: string[];
};

type ReleaseNotesRelease = {
  date: string;
  pre?: boolean;
  version: string;
};

type ReleaseNotesData = {
  content: ReleaseNotesContent[];
  release: ReleaseNotesRelease;
};

type ReleaseNotesProps = {
  onClose: () => void;
  open: boolean;
};

const Pre: FC = () => (
  <Chip label="Pre-release" size="small" variant="outlined" />
);

const Latest: FC = () => (
  <Chip color="success" label="Latest" size="small" variant="outlined" />
);

const ReleaseNotes: FC<ReleaseNotesProps> = ({ onClose, open }) => {
  const data: ReleaseNotesData[] = RELEASE_NOTES;

  const renderList = (data: string[]) => {
    if (data.length) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }

    return <Typography mb={2}>No content</Typography>;
  };

  const renderContent = (data: ReleaseNotesContent[]) => {
    if (data.length) {
      return data.map((item, index) => (
        <Fragment key={index}>
          <H3>{item.header}</H3>
          {renderList(item.list)}
        </Fragment>
      ));
    }

    return <Typography>No content</Typography>;
  };

  const renderBody = () => {
    if (data.length) {
      return data.map((item, index) => {
        const { content, release } = item;

        return (
          <Section key={index}>
            <SectionHeader>
              <Release>
                <Version>{release.version}</Version>
                <Date>{release.date}</Date>
              </Release>
              <Remark>
                {index === 0 && <Latest />} {release?.pre && <Pre />}
              </Remark>
            </SectionHeader>
            <SectionContent>
              <Card>
                <CardContent>{renderContent(content)}</CardContent>
              </Card>
            </SectionContent>
          </Section>
        );
      });
    }

    return (
      <Section>
        <SectionContent>
          <Typography>No content</Typography>
        </SectionContent>
      </Section>
    );
  };

  return (
    <SideSheet onClose={onClose} open={open} title="BOM Release Notes">
      <Description>
        <Typography>
          This page documents production updates to{' '}
          <b>Back Office Management</b>. You can periodically check this page
          for announcements about new or updated features, bug fixes, known
          issues, and deprecated functionality.
        </Typography>
      </Description>
      {renderBody()}
    </SideSheet>
  );
};

export { ReleaseNotes };
