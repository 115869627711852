import MuiCardContent from '@mui/material/CardContent';
import MuiCardHeader from '@mui/material/CardHeader';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

export const CardContent = styled(MuiCardContent)`
  height: 25rem;
  overflow-y: auto;
`;

export const CardHeader = styled(MuiCardHeader)`
  .MuiCardHeader-action {
    margin-right: 0;
  }
`;

export const Instruction = styled('div')`
  margin-bottom: 1.5rem;
`;

export const List = styled(MuiList)`
  padding-bottom: 0;
  padding-top: 0;
`;

export const ListItem = styled(MuiListItem)`
  padding-left: 0;
  padding-right: 0;

  &.Mui-selected {
    background-color: rgb(8 103 222 / 8%);

    &:hover {
      background-color: rgb(8 103 222 / 8%);
    }
  }
`;

export const Step = styled('span')`
  padding-right: 8px;
`;
