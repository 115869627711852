import React, { createContext, FC, ReactNode, useState } from 'react';

export type ErrorAlertMessage = {
  message: string | ReactNode;
  statusCode?: number;
  typeStatusMessage: string;
};

export type AlertMessageContextProps = {
  errorAlertMessage: ErrorAlertMessage;
  setAlertMessage: (errorAlertMessage: ErrorAlertMessage) => void;
};

export const AlertMessageContext = createContext<AlertMessageContextProps>({
  errorAlertMessage: {
    message: '',
    statusCode: undefined,
    typeStatusMessage: '',
  },
  setAlertMessage: () => undefined,
});

export const AlertMessageContextProvider: FC = ({ children }) => {
  const [errorAlertMessage, setAlertMessage] = useState<ErrorAlertMessage>({
    message: '',
    statusCode: undefined,
    typeStatusMessage: '',
  });

  return (
    <AlertMessageContext.Provider
      value={{ errorAlertMessage, setAlertMessage }}
    >
      {children}
    </AlertMessageContext.Provider>
  );
};
