import {
  AutoCompleteOption,
  IconButtonDelete,
} from '@bom-nextgen-keycloak/web/shared/ui';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableContainer as Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';

type TableFormProps = {
  data: AutoCompleteOption[];
  columnName: string;
  tooltipTitle: string;
  isDisabled?: boolean;
  onClickDelate: (data: AutoCompleteOption) => void;
};

export const TableContainer = styled(Container)`
  max-height: 250px;
`;

const TableForm: FC<TableFormProps> = ({
  data,
  columnName = 'Name',
  onClickDelate,
  tooltipTitle = 'Remove',
  isDisabled = false,
}) => {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{columnName}</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.value.id}>
              <TableCell>{item.label || ''}</TableCell>
              <TableCell>
                <Tooltip title={tooltipTitle}>
                  <IconButtonDelete
                    disabled={isDisabled}
                    aria-label="delete"
                    onClick={() => onClickDelate(item)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButtonDelete>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TableForm };
