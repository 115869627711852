import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { FC, Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GroupDto, UserGroupMemeberDto } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchGroupById,
  joinGroupUser,
  QUERY_KEY,
  removeUserGroup,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { DeleteDialog, FormSpinner } from '@bom-nextgen-keycloak/web/shared/ui';
import { TreeViewUserGroup } from '../../TreeViewUserGroup/TreeViewUserGroup';
import { CardContent, CardHeader, List, ListItem } from '../Group2.styled';
import { Card, CardSection, H3 } from './GroupOption.styled';

type EffectiveGroupList = string[];

type GroupOptionProps = {
  companyId: string;
  effectiveGroups?: UserGroupMemeberDto[];
  loading?: boolean;
  onReload: () => void;
  resourceGroupId: string;
  userId: string;
};

const findNodeById = (
  rootGroup: GroupDto | undefined,
  nodeId: string | undefined
): GroupDto | void => {
  const rootId = rootGroup?.id || '';
  const subGroups = rootGroup?.subGroups || [];

  if (nodeId === rootId) {
    return rootGroup;
  }

  if (subGroups && subGroups.length) {
    for (const child of subGroups) {
      const result = findNodeById(child, nodeId);

      if (result) {
        return result;
      }
    }
  }
};

const checkEffectiveNode = (
  effectiveList: EffectiveGroupList,
  nodeId: string
): boolean => {
  return effectiveList.includes(nodeId);
};

const checkSubscriptionNode = (
  level: number | undefined,
  path: string | undefined
): boolean => {
  const regex = /\/Subscriptions/;

  if (level && path) {
    return level === 4 && path.search(regex) !== -1;
  }

  return false;
};

const GroupOption: FC<GroupOptionProps> = ({
  companyId,
  effectiveGroups = [],
  loading = false,
  onReload,
  resourceGroupId,
  userId,
}) => {
  const queryClient = useQueryClient();
  const { clientId } = useResource();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const [selectedEffectiveNode, setSelectedEffectiveNode] =
    useState<UserGroupMemeberDto | null>(null);
  const [selectedAvailableNode, setSelectedAvailableNode] =
    useState<GroupDto | null>(null);
  const [canJoin, setCanJoin] = useState(false);
  const [canLeave, setCanLeave] = useState(false);
  const [actionMessage, setActionMessage] = useState('');
  const [isLeaveCompanyDialogOpen, setIsLeaveCompanyDialogOpen] =
    useState(false);

  const defaultData: GroupDto = {};
  const queryKeys = [QUERY_KEY.USER_GROUP_DETAIL, companyId, userId];

  const {
    data = defaultData,
    isFetching,
    isLoading,
  } = useQuery(queryKeys, () => fetchGroupById(resourceGroupId), {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot get group detail';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
  });

  const isInitializing = isFetching || isLoading;
  const effectiveGroupIds = effectiveGroups.map((item) => item.id);

  const effectiveNodes = effectiveGroups.filter((group) => {
    return group?.level === 4;
  });

  const effectiveGroupNodes = effectiveGroups.filter((group) => {
    return (
      group?.level === 4 && !checkSubscriptionNode(group?.level, group?.path)
    );
  });

  const effectiveSubscriptionNodes = effectiveGroups.filter((group) => {
    return checkSubscriptionNode(group?.level, group?.path);
  });

  const hasEffectiveNodes = effectiveNodes.length;
  const allCompanies = data?.subGroups || [];

  const activeCompanies = allCompanies.filter(
    (group) => group.id === companyId
  );

  const filteredGroupDetail: GroupDto = isEmpty(data)
    ? defaultData
    : {
        ...data,
        subGroups: [...activeCompanies],
      };

  const joinMutation = useMutation(joinGroupUser, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot join user to a group';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: () => {
      setNotification({
        message: 'User joined',
        type: 'success',
      });
      handleReset();
    },
  });

  const leaveMutation = useMutation(removeUserGroup, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot remove user from a group';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: () => {
      if (isLeaveCompanyDialogOpen) {
        onReload();
      } else {
        setNotification({
          message: 'User left',
          type: 'success',
        });
        handleReset();
        handleReload();
      }
    },
  });

  const handleReset = () => {
    onReload();
    handleSelectionReset();
    setActionMessage('');
    setIsLeaveCompanyDialogOpen(false);
  };

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const handleSelectionReset = () => {
    setCanJoin(false);
    setCanLeave(false);
    setSelectedEffectiveNode(null);
    setSelectedAvailableNode(null);
  };

  const handleGroupJoin = () => {
    if (selectedAvailableNode) {
      const groupIds: string[] = [];

      groupIds.push(companyId);
      groupIds.push(selectedAvailableNode?.parentsId ?? '');
      groupIds.push(selectedAvailableNode?.id ?? '');

      joinMutation.mutate({ userId, groupIds });
    } else {
      // TODO: Implement a handler
    }
  };

  const handleEffectiveGroupSelect = (
    event: React.MouseEvent<HTMLElement>,
    data: UserGroupMemeberDto
  ) => {
    setSelectedEffectiveNode(data);
    setCanLeave(true);
  };

  const handleCompanyLeaveRequest = () => {
    setIsLeaveCompanyDialogOpen(true);
  };

  const handleCompanyLeaveCancel = () => {
    setIsLeaveCompanyDialogOpen(false);
  };

  const handleCompanyLeaveAction = () => {
    if (companyId) {
      leaveMutation.mutate({
        clientId,
        groupId: companyId,
        userId,
      });
    } else {
      // TODO: Implement a handler
    }
  };

  const handleGroupLeave = () => {
    if (selectedEffectiveNode) {
      leaveMutation.mutate({
        clientId,
        groupId: selectedEffectiveNode.id,
        userId,
      });
    } else {
      // TODO: Implement a handler
    }
  };

  const handleNodeSelect = (node: GroupDto | null) => {
    const parentNode = findNodeById(data, node?.parentsId);
    const effectiveSubgroupOfParentNode = parentNode?.subGroups?.find(
      (subgroup) => {
        return checkEffectiveNode(effectiveGroupIds, subgroup?.id ?? '');
      }
    );

    const isL1 = node?.level === 1;
    const isL2 = node?.level === 2;
    const isL3 = node?.level === 3;
    const isL4 = node?.level === 4;
    const isEffectiveNode = checkEffectiveNode(
      effectiveGroupIds,
      node?.id ?? ''
    );
    const isSubscriptionNode = checkSubscriptionNode(node?.level, node?.path);
    const isGroupNode = isL4 && !isSubscriptionNode;

    const hasSubscription =
      effectiveSubgroupOfParentNode &&
      checkSubscriptionNode(
        effectiveSubgroupOfParentNode?.level,
        effectiveSubgroupOfParentNode?.path
      );

    if (node) {
      setSelectedAvailableNode(node);

      if (
        isL1 ||
        isL2 ||
        isL3 ||
        (isL4 && isEffectiveNode) ||
        hasSubscription
      ) {
        setCanJoin(false);
      } else {
        setCanJoin(true);
      }

      if (isGroupNode && isEffectiveNode) {
        setActionMessage('Cannot select an effective group');
      } else if (isSubscriptionNode && isEffectiveNode) {
        setActionMessage('Cannot select an effective subscription');
      } else if (hasSubscription) {
        setActionMessage('Cannot select multiple subscriptions');
      } else if (isL1) {
        setActionMessage('Cannot select an application level');
      } else if (isL2) {
        setActionMessage('Cannot select a company level');
      } else if (isL3) {
        setActionMessage('Cannot select a directory level');
      } else {
        setActionMessage('');
      }
    } else {
      setSelectedAvailableNode(null);
    }
  };

  const disableLeaveCompanyButton =
    isInitializing || loading || leaveMutation.isLoading;
  const disableLeaveGroupButton =
    isInitializing || loading || leaveMutation.isLoading || !canLeave;
  const disableJoinGroupButton =
    isInitializing || joinMutation.isLoading || !canJoin;

  const renderEffectiveGroups = () => {
    if (hasEffectiveNodes) {
      return (
        <div>
          <CardSection>
            <H3>Groups</H3>
            {effectiveGroupNodes.length ? (
              <List dense>
                {effectiveGroupNodes.map((item) => (
                  <ListItem
                    key={item.id}
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      handleEffectiveGroupSelect(event, item)
                    }
                  >
                    <ListItemButton selected={selectedEffectiveNode === item}>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No groups found</Typography>
            )}
          </CardSection>
          <CardSection>
            <H3>Subscriptions</H3>
            {effectiveSubscriptionNodes.length ? (
              <List dense>
                {effectiveSubscriptionNodes.map((item) => (
                  <ListItem
                    key={item.id}
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      handleEffectiveGroupSelect(event, item)
                    }
                  >
                    <ListItemButton selected={selectedEffectiveNode === item}>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No subscriptions found</Typography>
            )}
          </CardSection>
        </div>
      );
    }

    return <Typography>No groups or subscriptions found</Typography>;
  };

  const renderAvailableGroups = () => {
    return (
      <TreeViewUserGroup
        groupDetail={filteredGroupDetail}
        memberIds={effectiveGroupIds}
        onNodeSelected={handleNodeSelect}
      />
    );
  };

  return (
    <Fragment>
      <Stack direction="row" justifyContent="flex-start" spacing={5}>
        <Card>
          <CardHeader
            action={
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Button
                  aria-label="change company"
                  color="error"
                  disabled={disableLeaveCompanyButton}
                  onClick={handleCompanyLeaveRequest}
                  variant="outlined"
                >
                  Change Company
                </Button>
                <LoadingButton
                  aria-label="leave group"
                  disabled={disableLeaveGroupButton}
                  loading={
                    leaveMutation.isLoading &&
                    selectedEffectiveNode?.level === 4
                  }
                  onClick={handleGroupLeave}
                  variant="outlined"
                >
                  Leave
                </LoadingButton>
              </Stack>
            }
            // disableTypography
            title="Effective Groups & Subscriptions"
          />
          <Divider />
          <CardContent>
            {isInitializing || (loading && !isLeaveCompanyDialogOpen) ? (
              <FormSpinner />
            ) : (
              <Fragment>{renderEffectiveGroups()}</Fragment>
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            action={
              actionMessage ? (
                <Chip label={actionMessage} />
              ) : (
                <LoadingButton
                  aria-label="join group"
                  disabled={disableJoinGroupButton}
                  loading={joinMutation.isLoading}
                  onClick={handleGroupJoin}
                  variant="outlined"
                >
                  Join
                </LoadingButton>
              )
            }
            title="Available Options"
          />
          <Divider />
          <CardContent>
            {isLoading ? (
              <FormSpinner />
            ) : (
              <Fragment>{renderAvailableGroups()}</Fragment>
            )}
          </CardContent>
        </Card>
      </Stack>
      <DeleteDialog
        description="You are about to change a company."
        loading={leaveMutation.isLoading || loading}
        name="change-company"
        onClose={handleCompanyLeaveCancel}
        onDelete={handleCompanyLeaveAction}
        open={isLeaveCompanyDialogOpen}
        title="Change company?"
        titleBtn="Change"
      />
    </Fragment>
  );
};

export { GroupOption };
