import { ClientRoleRegister } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  createClientRole,
  ErrorMessage,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { StyledDialogTitle } from '@bom-nextgen-keycloak/web/shared/ui';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { RoleForm, validationSchema } from '../RoleForm';
import { StyledDialog } from './RoleFormDialog.styled';

type RoleFormDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

const RoleFormDialog: FC<RoleFormDialogProps> = ({ onClose, onSave, open }) => {
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const resource = useResource();

  const createClientRoleMutation = useMutation(createClientRole, {
    onSuccess: (data) => {
      setNotification({
        message: 'Role created',
        type: 'success',
      });
      onSave();
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot create user';
      setAlertMessage({
        message:
          error.response?.status === 409
            ? 'Role name already exists. try another name'
            : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const initialValues: ClientRoleRegister = {
    name: '',
    description: '',
  };

  return (
    <StyledDialog
      onClose={() => onClose()}
      aria-labelledby="dialog-title"
      open={open}
    >
      <StyledDialogTitle id="dialog-title">Add role</StyledDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: ClientRoleRegister,
          { setSubmitting }: FormikHelpers<ClientRoleRegister>
        ) => {
          createClientRoleMutation.mutate({
            clientId: resource.clientId,
            payload: values,
          });
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <DialogContent>
              <RoleForm />
            </DialogContent>

            <DialogActions>
              <Button color="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!(isValid && dirty) || isSubmitting}
                id="btn-save"
                loading={createClientRoleMutation.isLoading}
                type="submit"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export { RoleFormDialog };
