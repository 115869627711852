import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { FC, Fragment, useState } from 'react';
import { useMutation } from 'react-query';
import { UserCredentials } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
} from '@bom-nextgen-keycloak/web/core';
import {
  deleteCredential,
  ErrorMessage,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  DeleteDialog,
  NotFound2,
  PaginationLite,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';

type CredentialTableProps = {
  data: UserCredentials[];
  isLoading?: boolean;
  onReload: () => void;
  userId: string;
};

const CredentialTable: FC<CredentialTableProps> = ({
  data,
  isLoading = false,
  onReload,
  userId,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const [selectedItem, setSelectedItem] = useState('');
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const isEmpty = data?.length === 0;
  const columnLength = 3;
  const showSkeleton = isLoading;
  const showHeader = !isEmpty;

  const deleteMutation = useMutation(deleteCredential, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot delete user credential';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSettled: () => {
      setDeleteDialogOpen(false);
    },
    onSuccess: () => {
      setNotification({
        message: 'Credential deleted',
        type: 'success',
      });
      onReload();
    },
  });

  const handleSelectionReset = () => {
    setSelectedItem('');
  };

  const handleCredentialDeleteRequest = (credentialId: string) => {
    setSelectedItem(credentialId);
    setDeleteDialogOpen(true);
  };

  const handleCredentialDeleteCancel = () => {
    setDeleteDialogOpen(false);
    handleSelectionReset();
  };

  const handleCredentialDeleteAction = () => {
    setDeleteDialogOpen(false);
    deleteMutation.mutate({ credentialId: selectedItem, userId });
  };

  return (
    <Fragment>
      <Paper>
        <Fragment>
          <TableContainer>
            <Table
              aria-label="credential table"
              aria-labelledby="tableTitle"
              stickyHeader
            >
              {showHeader && (
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell sx={{ width: '155px' }}>Created date</TableCell>
                    <TableCell align="center" sx={{ width: '85px' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                <TableRowSkeleton
                  cellLength={columnLength}
                  loading={showSkeleton}
                >
                  {!isEmpty ? (
                    data.map((row) => {
                      const { createdDate, id, type } = row;

                      return (
                        <TableRow key={`${type}-${id}`}>
                          <TableCell>{type}</TableCell>
                          <TableCell>
                            {format(new Date(createdDate), 'dd.MM.yyyy | H:mm')}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Delete credential">
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleCredentialDeleteRequest(id)
                                }
                                size="small"
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columnLength}>
                        <NotFound2 />
                      </TableCell>
                    </TableRow>
                  )}
                </TableRowSkeleton>
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationLite loading={isLoading} />
        </Fragment>
      </Paper>
      <DeleteDialog
        description={`You are about to permanently delete user credential. <b>This operation cannot be undone</b>.`}
        loading={deleteMutation.isLoading}
        name="delete-credential"
        onClose={handleCredentialDeleteCancel}
        onDelete={handleCredentialDeleteAction}
        open={isDeleteDialogOpen}
        title="Delete credential?"
        titleBtn="Delete"
      />
    </Fragment>
  );
};

export { CredentialTable };
