type Argument = {
  enabled?: boolean;
  isDirty?: boolean;
  isLoading?: boolean;
  isValid?: boolean;
};

const useForm = ({
  enabled = true,
  isDirty = true,
  isLoading = false,
  isValid = true,
}: Argument) => {
  const disableCancel = isLoading;
  const disableReset = !isDirty || isLoading || !enabled;
  const disableSave = !(isDirty && isValid) || isLoading || !enabled;

  return {
    disableCancel,
    disableReset,
    disableSave,
  };
};

export { useForm };
