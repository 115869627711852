import * as yup from 'yup';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';

export const validationSchema = yup.object({
  industry: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Industry is required'),
});
