import { AxiosError } from 'axios';
import { FC, Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IUserSessions } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchUserSession,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ProtectedRouter,
  RefreshButton,
  Toolbar,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  checkProcessing,
  generateHash,
} from '@bom-nextgen-keycloak/web/shared/util';
import { PARENT_PATH } from '../../shared';
import { Session2Table } from './Session2Table';

const Session2: FC = () => {
  const { setAlertMessage } = useAlertMessage();
  const { id = '' } = useParams();
  const [queryHash, setQueryHash] = useState(generateHash());

  const defaultData: IUserSessions[] = [];
  const queryKeys = [QUERY_KEY.USER_SESSION, id, queryHash];

  const {
    data = defaultData,
    isFetched,
    isFetching,
    isLoading,
  } = useQuery(queryKeys, () => fetchUserSession(id), {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot get user sessions';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
  });

  const handleRefetch = () => {
    setQueryHash(generateHash());
  };

  const handleRefresh = () => {
    handleRefetch();
  };

  // NOTE: Admin Service does not return pagination info
  const totalRecord = data.length;
  const isProcessing = checkProcessing({ isFetched, isFetching, isLoading });

  return (
    <Fragment>
      <Toolbar>
        <Toolbar.Control>
          <Toolbar.Search />
          <Toolbar.Action>
            <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>
          </Toolbar.Action>
        </Toolbar.Control>
      </Toolbar>
      <Session2Table
        data={data}
        isFetched={isFetched}
        isFetching={isFetching}
        isLoading={isLoading}
        isProcessing={isProcessing}
        totalCount={undefined}
        totalRecord={totalRecord}
      />
    </Fragment>
  );
};

const WrappedUserSessionPage: FC = () => {
  const { canViewSessionTab } = useSelector(selectPermissionUserDetail);

  return (
    <ProtectedRouter
      canView={canViewSessionTab}
      feature={FeaturePage.USER}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <Session2 />
    </ProtectedRouter>
  );
};

export { WrappedUserSessionPage };
