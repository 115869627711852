type EnvironmentColor =
  | 'error'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning';

export type EnvironmentEntity = {
  debug: boolean;
  description: string;
  enabled: boolean;
  name: string;
  note?: string;
  title: string;
  url: string;
};

type EnvironmentList = EnvironmentEntity[];

type EnvironmentMode = 'development' | 'production';

type EnvironmentName = {
  dev: string;
  localhost: string;
  production: string;
  sandbox_dev: string;
  sandbox_uat: string;
};

type EnvironmentTitle =
  | 'Development'
  | 'localhost'
  | 'Production'
  | 'Sandbox - DEV'
  | 'Sandbox - UAT'
  | 'Unknown';

const ENV_LIST: EnvironmentEntity[] = [
  {
    debug: true,
    description: 'Local development environment for BOM.',
    enabled: false,
    name: 'localhost',
    note: 'BOM is not stable',
    title: 'localhost',
    url: 'http://localhost:4200',
  },
  {
    debug: true,
    description: 'Development environment for BOM.',
    enabled: false,
    name: 'dev',
    note: 'BOM is not stable',
    title: 'Development',
    url: 'https://bomsc.dev.tdg-analytics.io',
  },
  {
    debug: false,
    description: 'SIT environment for BOM / Development environment for apps.',
    enabled: true,
    name: 'sandbox_dev',
    note: 'BOM is not stable',
    title: 'Sandbox - DEV',
    url: 'https://bomsc.sandbox.dev.tdg-analytics.io',
  },
  {
    debug: false,
    description: 'UAT environment for apps.',
    enabled: true,
    name: 'sandbox_uat',
    note: 'BOM is stable',
    title: 'Sandbox - UAT',
    url: 'https://bomsc.sandbox.uat.tdg-analytics.io',
  },
  {
    debug: false,
    description: 'Production environment for BOM and apps.',
    enabled: true,
    name: 'production',
    title: 'Production',
    url: 'https://bomsc.tdg-analytics.io',
  },
];

const useEnvironment = () => {
  const ENV_MODE = process.env.NODE_ENV as EnvironmentMode;
  const ENV_NAME = process.env.NX_ENV_NAME as keyof EnvironmentName;

  const getColor = (): EnvironmentColor => {
    const envSet: EnvironmentName = {
      dev: 'secondary',
      localhost: 'secondary',
      production: 'error',
      sandbox_dev: 'success',
      sandbox_uat: 'warning',
    };

    if (ENV_NAME === undefined) {
      return 'primary';
    }

    return envSet[ENV_NAME] as EnvironmentColor;
  };

  const getDescription = (): string => {
    if (ENV_NAME === undefined) {
      return 'Unknown';
    }

    const environment = ENV_LIST.find((item) => item.name === ENV_NAME);

    return environment?.description || 'Unkonwn';
  };

  const getList = (): EnvironmentList => ENV_LIST;

  const getMode = (): string => {
    return ENV_MODE;
  };

  const getName = (): string => {
    return ENV_NAME;
  };

  const getNote = (): string => {
    if (ENV_NAME === undefined) {
      return 'Unknown';
    }

    const environment = ENV_LIST.find((item) => item.name === ENV_NAME);

    return environment?.note || 'Unkonwn';
  };

  const getTitle = (): EnvironmentTitle => {
    const envSet: EnvironmentName = {
      dev: 'Development',
      localhost: 'localhost',
      production: 'Production',
      sandbox_dev: 'Sandbox - DEV',
      sandbox_uat: 'Sandbox - UAT',
    };

    if (ENV_NAME === undefined) {
      return 'Unknown';
    }

    return envSet[ENV_NAME] as EnvironmentTitle;
  };

  const getUrl = (): string => {
    const envSet = {
      dev: 'https://bomsc.dev.tdg-analytics.io',
      localhost: 'http://localhost:4200',
      sandbox_dev: 'https://bomsc.sandbox.dev.tdg-analytics.io',
      sandbox_uat: 'https://bomsc.sandbox.uat.tdg-analytics.io',
      production: 'https://bomsc.tdg-analytics.io',
    };

    if (ENV_NAME === undefined) {
      return 'https://bomsc.tdg-analytics.io';
    }

    return envSet[ENV_NAME];
  };

  const color = getColor();
  const description = getDescription();
  const list = getList();
  const mode = getMode();
  const name = getName();
  const note = getNote();
  const title = getTitle();
  const url = getUrl();

  return {
    color,
    description,
    isDevelopment: name === 'dev',
    isLocalhost: name === 'localhost',
    isProduction: name === 'production',
    isSandboxDev: name === 'sandbox_dev',
    isSandboxUat: name === 'sandbox_uat',
    list,
    mode,
    name,
    note,
    onCloud: mode === 'production',
    onLocal: mode === 'development',
    title,
    url,
  };
};

export { useEnvironment };
