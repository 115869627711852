import { ReactKeycloakProvider } from '@react-keycloak/web';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from '@bom-nextgen-keycloak/web/core';
import { keycloak } from '@bom-nextgen-keycloak/web/shared/util';
import App2 from './app2';
import './main.css';

const eventLogger = (event: unknown, error: unknown) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') {
    console.log('onKeycloakEvent', event, error);
  }
};

const tokenLogger = (tokens: unknown) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') {
    console.log('onKeycloakTokens', tokens);
  }
};

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      pkceMethod: 'S256',
    }}
    onEvent={eventLogger}
    onTokens={tokenLogger}
  >
    <BrowserRouter>
      <Provider store={store}>
        <App2 />
      </Provider>
    </BrowserRouter>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);
