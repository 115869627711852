import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Action = styled('div')`
  display: block;
  padding-left: 4px;
`;

export const Box = styled(Paper)<{ width: number | 'auto' }>`
  align-items: center;
  display: flex;
  min-height: 30.75px;
  padding: 0 4px 0 1rem;
  width: ${(props) => {
    const { width } = props;

    return width === 'auto' ? width : width + 'px';
  }};
`;

export const Button = styled(IconButton)`
  padding: 3px;
`;

export const Input = styled(InputBase)`
  flex: 1;
  font-size: 0.8125rem;
  top: 1px;

  & .MuiInputBase-input {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
