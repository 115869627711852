import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Theme } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableCellHead } from '../Styled';
import { createStyles, makeStyles } from '@mui/styles';

type EnhancedTableProps<T extends any> = {
  headCells: HeadCell<keyof T>[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: OrderTable;
  orderBy: keyof T;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

const EnhancedTableHead = <T extends any>(props: EnhancedTableProps<T>) => {
  const { order, orderBy, onRequestSort, headCells } = props;
  const classes = useStyles();

  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      if (property) {
        onRequestSort(event, property);
      }
    };

  const sortName = order === 'desc' ? 'sorted descending' : 'sorted ascending';
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.label === 'Actions' ? 120 : 'auto'}
          >
            <TableSortLabel
              active={orderBy === headCell.sortKey}
              direction={orderBy === headCell.sortKey ? order : 'asc'}
              onClick={createSortHandler(headCell.sortKey)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{sortName}</span>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { EnhancedTableHead };
