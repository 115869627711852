export enum UserEvent {
  ADD = 'USER_ADD',
  EDIT = 'USER_EDIT',
  DELETE = 'USER_DELETE',
  EXPORT = 'USER_EXPORT',
  ENABLED = 'USER_ENABLED',
  USER_DETAIL = 'USER_DETAIL',
  USER_GENERAL = 'USER_GENERAL',
  USER_ATTRIBUTE = 'USER_ATTRIBUTE',
  USER_PASSWORD = 'USER_PASSWORD',
  USER_GROUP = 'USER_GROUP',
  USER_ROLE = 'USER_ROLE',
  USER_SESSION = 'USER_SESSION',
  USER_DEVELOPER = 'USER_DEVELOPER',
  USER_IDP = 'USER_IDP',
  USER_REMOVE = 'USER_REMOVE',
  EDIT_USERNAME = 'USER_USERNAME',
}

export enum GroupEvent {
  ADD = 'GROUP_ADD',
  DELETE = 'GROUP_DELETE',
  EXPORT = 'GROUP_EXPORT',
  DETAIL = 'GROUP_DETAIL',
  GENERAL = 'GROUP_GENERAL',
  ATTRIBUTE = 'GROUP_ATTRIBUTE',
  MEMBER = 'GROUP_MEMBER',
  ROLE = 'GROUP_ROLE',
}

export enum CompanyEvent {
  EDIT = 'COMPANY_EDIT',
  DELETE = 'COMPANY_DELETE',
}

export enum RoleEvent {
  ADD = 'ROLE_ADD',
  DELETE = 'ROLE_DELETE',
  DETAIL = 'ROLE_DETAIL',
  GENERAL = 'ROLE_GENERAL',
  ATTRIBUTE = 'ROLE_ATTRIBUTE',
  USER = 'ROLE_USER',
}

export enum ScopeEvent {
  ADD = 'SCOPE_ADD',
  DELETE = 'SCOPE_DELETE',
  DETAIL = 'SCOPE_DETAIL',
  GENERAL = 'SCOPE_GENERAL',
}

export enum ResourceEvent {
  ADD = 'RESOURCE_ADD',
  DELETE = 'RESOURCE_DELETE',
  DETAIL = 'RESOURCE_DETAIL',
  GENERAL = 'RESOURCE_GENERAL',
  ATTRIBUTE = 'RESOURCE_ATTRIBUTE',
}

export enum PolicyEvent {
  ADD = 'POLICY_ADD',
  DELETE = 'POLICY_DELETE',
  DETAIL = 'POLICY_DETAIL',
  GENERAL = 'POLICY_GENERAL',
}

export enum PermissionEvent {
  ADD = 'PERMISSION_ADD',
  DELETE = 'PERMISSION_DELETE',
  DETAIL = 'PERMISSION_DETAIL',
  GENERAL = 'PERMISSION_GENERAL',
}

export enum EventPage {
  EVENT_LOGIN = 'EVENT_LOGIN',
  EVENT_PERMISSION = 'EVENT_PERMISSION',
}

export enum EnvironmentEvent {
  CHANGE = 'ENVIRONMENT_CHANGE',
  DEBUG = 'ENVIRONMENT_DEBUG',
  VIEW = 'ENVIRONMENT_VIEW',
}
