import Stack from '@mui/material/Stack';
import { FC } from 'react';

type ButtonStackProps = {
  justify?: 'end' | 'start';
  spacing?: number;
};

const ButtonStack: FC<ButtonStackProps> = ({
  children,
  justify = 'start',
  spacing = 1.5,
}) => (
  <Stack direction="row" justifyContent={`flex-${justify}`} spacing={spacing}>
    {children}
  </Stack>
);

export { ButtonStack };
