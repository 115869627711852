import { useKeycloak } from '@react-keycloak/web';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes, RouteMatch } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  defaultNotification,
  mapResourceApplication,
  selectAccount,
  // selectPermissionEnvironment,
  selectResourceList,
  setActiveResource,
  setFeature,
  setPermissionPage,
  setResourceList,
  useAlertMessage,
  useAuthorization,
  useNotification,
  useProfile,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { RouteType } from '@bom-nextgen-keycloak/web/shared/data-access';
import { Notification, Spinner } from '@bom-nextgen-keycloak/web/shared/ui';
import { setPreferedWorkspace } from '@bom-nextgen-keycloak/web/shared/util';
import {
  AlertDialog,
  Content,
  Environment,
  Header,
  MainMenu,
  ReleaseNotes,
  Sidebar,
  SupplementaryMenu,
  Support,
  Workspace,
} from './components';
import { getAppMenuItems } from './services/menu.service';
import { Body, Main, Wrapper } from './Authenticated.styled';

type AuthenticatedProps = {
  privateRoute: RouteType[];
};

const Authenticated: FC<AuthenticatedProps> = ({ privateRoute }) => {
  const { keycloak } = useKeycloak();
  const { authenticated, logout } = keycloak;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorAlertMessage, setAlertMessage } = useAlertMessage();
  const { notification, setNotification } = useNotification();
  const resource = useResource();
  const { displayName, resourceId } = resource;
  const { authorized } = useSelector(selectAccount);
  const workspaces = useSelector(selectResourceList);
  // const { canChange: canChangeEnvironment, canView: canViewEvironment } =
  //   useSelector(selectPermissionEnvironment);
  const authorization = useAuthorization(authenticated && !authorized);
  const profile = useProfile(authenticated && authorized);

  const [menuList, setMenuList] = useState<any[]>([]);
  const [isWorkspaceLoading, setIsWorkspaceLoading] = useState(false);
  const [isWorkspaceOpen, setIsWorkspaceOpen] = useState(false);
  const [isEnvironmentOpen, setIsEnvironmentOpen] = useState(false);
  const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  const {
    data: permissionData,
    isLoading: isPermissionLoading,
    isSuccess: isPermissionLoaded,
  } = authorization;
  const { isLoading: isProfileLoading, isSuccess: isProfileLoaded } = profile;
  const isLoading = isProfileLoading && isPermissionLoaded;
  const isReady = isPermissionLoaded && isProfileLoaded;
  const isProcessing = isPermissionLoading || isWorkspaceLoading;
  const spinnerTitle = isWorkspaceLoading ? 'Loading project...' : '';
  const showWorkspace = Boolean(resourceId);
  // TODO: Implement environment permission
  // const showEnvironment = canViewEvironment && canChangeEnvironment;
  const showEnvironment = false;
  const showAlertDialog = errorAlertMessage?.statusCode !== 401;
  const disableWorkspace = workspaces.length <= 1;

  const handleLogout = () => {
    logout();
  };

  const handleAlertClose = () => {
    setAlertMessage({
      message: '',
      statusCode: errorAlertMessage.statusCode,
      typeStatusMessage: errorAlertMessage.typeStatusMessage,
    });
  };

  const handleNotificationClose = () => {
    setNotification({
      duration: defaultNotification.duration,
      message: defaultNotification.message,
      title: defaultNotification.title,
      type: notification.type,
    });
  };

  const handleWorkspaceChange = (resourceId: string) => {
    const activeWorkspace = workspaces.find(
      (element) => element.resourceId === resourceId
    );

    if (activeWorkspace) {
      dispatch(setActiveResource(activeWorkspace));
      setPreferedWorkspace(activeWorkspace);
      setIsWorkspaceLoading(true);

      setTimeout(() => {
        setIsWorkspaceLoading(false);
        setNotification({
          duration: 5 * 1000,
          message: `Now viewing project "${activeWorkspace.displayName}"`,
        });
      }, 1 * 1000);
    } else {
      // TODO: Implement a handler
    }
  };

  const handleWorkspaceToggle = () => {
    setIsWorkspaceOpen((previous) => !previous);
  };

  const handleEnvironmentChange = (url: string) => {
    window.location.replace(url);
  };

  const handleEnvironmentToggle = () => {
    setIsEnvironmentOpen((previous) => !previous);
  };

  const handleReleaseNotesToggle = () => {
    setIsReleaseNotesOpen((previous) => !previous);
  };

  const handleSupportToggle = () => {
    setIsSupportOpen((previous) => !previous);
  };

  useEffect(() => {
    if (!authenticated) {
      navigate('/login', {
        state: { message: 'Goodbye and have a good day!' },
      });
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    // TODO: Refactor
    if (isReady && permissionData?.resources.length) {
      const { resources } = permissionData;

      const productPermissions = resources.filter(
        (item) => item.type === 'product'
      );

      const featurePermissions = resources.filter(
        (item) => item.type === 'feature'
      );

      const features = mapResourceApplication(featurePermissions);

      dispatch(setResourceList(productPermissions));
      dispatch(setFeature(featurePermissions));
      dispatch(setPermissionPage(resources));
      setMenuList(getAppMenuItems(features));
    } else {
      // TODO: Implement a handler
    }
  }, [dispatch, isPermissionLoaded, isReady, permissionData]);

  const renderContent = () => {
    const routerPath = privateRoute.length ? privateRoute : [];
    const matchedRoute: RouteMatch[] | null = matchRoutes(
      routerPath,
      window.location.pathname
    );
    const matchUrl = matchedRoute ? matchedRoute[0].pathname : '';

    return (
      <Wrapper>
        <Header
          disableWorkspace={disableWorkspace}
          onEnvironmentSelect={handleEnvironmentToggle}
          onLogout={handleLogout}
          onWorkspaceSelect={handleWorkspaceToggle}
          showEnvironment={showEnvironment}
          showWorkspace={showWorkspace}
          workspaceName={displayName}
        />
        <Body>
          <Sidebar>
            <MainMenu data={menuList} loading={isLoading} matchUrl={matchUrl} />
            <SupplementaryMenu
              loading={isLoading}
              onReleaseNotesOpen={handleReleaseNotesToggle}
              onSupportOpen={handleSupportToggle}
            />
          </Sidebar>
          <Main>
            <Content isReady={isReady} />
          </Main>
        </Body>

        {showWorkspace && (
          <Workspace
            onChange={handleWorkspaceChange}
            onClose={handleWorkspaceToggle}
            open={isWorkspaceOpen}
            selectedId={resourceId}
            workspaces={workspaces}
          />
        )}

        {showEnvironment && (
          <Environment
            onChange={handleEnvironmentChange}
            onClose={handleEnvironmentToggle}
            open={isEnvironmentOpen}
          />
        )}

        {showAlertDialog && (
          <AlertDialog
            message={errorAlertMessage.message}
            onClose={handleAlertClose}
            type={errorAlertMessage.typeStatusMessage}
          />
        )}

        <Notification
          duration={notification.duration}
          message={notification.message}
          onClose={handleNotificationClose}
          title={notification.title}
          type={notification.type}
        />

        <ReleaseNotes
          onClose={handleReleaseNotesToggle}
          open={isReleaseNotesOpen}
        />

        <Support onClose={handleSupportToggle} open={isSupportOpen} />
      </Wrapper>
    );
  };

  const renderSpinner = (title?: string) => <Spinner title={title} />;

  return isProcessing ? renderSpinner(spinnerTitle) : renderContent();
};

export { Authenticated };
