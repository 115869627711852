import { DateRange } from '@mui/lab/DateRangePicker';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { LoggingQuery } from '@bom-nextgen-keycloak/models';
import {
  DateRangePickerPro,
  SearchButton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  convertLocalToUTCDate,
  convertUTCToLocalDate,
} from '@bom-nextgen-keycloak/web/shared/util';
import { errorTypeOptions, statusOptions } from '../../shared/constants';
import { Action, Form, Wrapper } from './AdvancedSearch.styled';

type Datepicker = Date | null;

type Select = string | null;

export type AdvancedSearchCriteria = {
  endDate: Datepicker;
  startDate: Datepicker;
  status?: Select;
  type?: Select;
};

type AdvancedSearchProps = {
  onApply: (data: AdvancedSearchCriteria) => void;
  params: LoggingQuery;
};

const AdvancedSearch: FC<AdvancedSearchProps> = ({ onApply, params }) => {
  const defaultCriteria = useMemo(
    () => ({
      endDate: null,
      startDate: null,
      status: null,
      type: null,
    }),
    []
  );

  const initialCriteria = useMemo(
    () => ({
      endDate: params?.endDate || defaultCriteria.endDate,
      startDate: params?.startDate || defaultCriteria.startDate,
      status: params?.status || defaultCriteria.status,
      type: params?.type || defaultCriteria.type,
    }),
    [defaultCriteria, params]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [criteria, setCriteria] =
    useState<AdvancedSearchCriteria>(initialCriteria);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const canAdvancedSearchOpen = isPopoverOpen && Boolean(anchorEl);
  const id = canAdvancedSearchOpen ? 'advanced-search-popover' : undefined;
  const isFilter =
    !!criteria.endDate ||
    !!criteria.startDate ||
    !!criteria.status ||
    !!criteria.type;

  useEffect(() => {
    setCriteria(initialCriteria);
  }, [initialCriteria]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setCriteria((previous) => {
      const { value } = event.target;

      return {
        ...previous,
        status: value === 'all' ? null : value,
        type: value === 'success' || value === 'all' ? null : previous.type,
      };
    });
  };

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setCriteria((previous) => {
      const { value } = event.target;

      return {
        ...previous,
        type: value === 'all' ? null : value,
      };
    });
  };

  const handleDateChange = (event: DateRange<Datepicker | null>) => {
    const [startDate, endDate] = event;

    if (startDate && endDate) {
      setCriteria((previous) => ({
        ...previous,
        endDate: convertLocalToUTCDate(endDate),
        startDate: convertLocalToUTCDate(startDate),
      }));
    } else {
      // TODO: Implement a handler
    }
  };

  const handleClear = () => {
    setCriteria(defaultCriteria);
  };

  const handleRestore = () => {
    setCriteria(initialCriteria);
  };

  const handleSearch = () => {
    handleClose();
    onApply(criteria);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);

    setCriteria((previous) => ({
      ...previous,
      ...initialCriteria,
    }));
  };

  return (
    <Fragment>
      <SearchButton aria-describedby={id} onClick={handleOpen}>
        Advanced Search
      </SearchButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id={id}
        onClose={handleClose}
        open={isPopoverOpen}
      >
        <Wrapper>
          <Form>
            <Box mb={2.5}>
              <FormControl fullWidth>
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  id="status-select"
                  label="Status"
                  labelId="status-select-label"
                  name="status"
                  onChange={handleStatusChange}
                  value={criteria.status || 'all'}
                >
                  <MenuItem value="all">All</MenuItem>
                  {statusOptions.map((option) => {
                    const { label, value } = option;

                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel id="error-type-select-label">Error type</InputLabel>
                <Select
                  disabled={criteria.status === 'success' || !criteria.status}
                  id="error-type-select"
                  label="Error type"
                  labelId="error-type-select-label"
                  name="type"
                  onChange={handleTypeChange}
                  value={criteria.type || 'all'}
                >
                  <MenuItem value="all">All</MenuItem>
                  {errorTypeOptions.map((option) => {
                    const { label, value } = option;

                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box mb={2.5}>
              <DateRangePickerPro
                endText="End date"
                onChange={handleDateChange}
                startText="Start date"
                value={[
                  convertUTCToLocalDate(criteria.startDate),
                  convertUTCToLocalDate(criteria.endDate),
                ]}
              />
            </Box>
          </Form>
          <Action>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button onClick={handleClose} variant="outlined">
                Close
              </Button>
              <Button onClick={handleRestore} variant="outlined">
                Restore
              </Button>
              <Button
                disabled={!isFilter}
                onClick={handleClear}
                variant="outlined"
              >
                Clear
              </Button>
              <Button onClick={handleSearch} variant="contained">
                Apply
              </Button>
            </Stack>
          </Action>
        </Wrapper>
      </Popover>
    </Fragment>
  );
};

export { AdvancedSearch };
