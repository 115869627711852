import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ErrorMessage,
  fetchProfileUser,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  setAuthzError,
  setGenericAuthzError,
  setProfile,
  setUnauthorized,
} from '../reducer';

const useProfile = (enabled = false) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryKeys = [QUERY_KEY.PERMISSION_AND_USER, 'profile'];

  const { data, isLoading, isSuccess } = useQuery(
    queryKeys,
    () => fetchProfileUser(),
    {
      enabled,
      onSuccess: (data) => {
        if (data) {
          dispatch(setProfile(data));
        } else {
          // TODO: Implement a handler
        }
      },
      onError: (error: AxiosError<ErrorMessage>) => {
        if (error?.response?.data) {
          const { message, statusCode } = error.response.data;

          dispatch(setUnauthorized());
          dispatch(setAuthzError({ message, statusCode }));
        } else {
          dispatch(setGenericAuthzError());
        }

        navigate('/unauthorized');
      },
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
  };
};

export { useProfile };
