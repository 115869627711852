import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useKeycloak } from '@react-keycloak/web';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectAccount,
  selectError,
  useAuthorization,
} from '@bom-nextgen-keycloak/web/core';
import { Contacts, Spinner } from '@bom-nextgen-keycloak/web/shared/ui';
import { mailTo } from '@bom-nextgen-keycloak/web/shared/util';
import {
  Actions,
  Description,
  Header,
  Help,
  Main,
  Support,
  Title,
  Wrapper,
} from './Unauthorized.styled';

const Unauthorized: FC = () => {
  const { keycloak } = useKeycloak();
  const { authenticated, logout } = keycloak;
  const navigate = useNavigate();
  const account = useSelector(selectAccount);
  const { authorized } = account;
  const error = useSelector(selectError);
  const { message, statusCode } = error.authorization;
  const isUnauthorized = authenticated && !authorized;
  const isError = message !== null && statusCode !== null;
  const { isLoading } = useAuthorization(isUnauthorized && !isError);

  const isForbidden = message !== null && statusCode === 403;
  const isPermitted = authenticated && authorized;
  const alertMessage =
    message === 'Forbidden'
      ? "You don't have permission to access BOMSC"
      : message;
  const alertSeverity = isForbidden ? 'warning' : 'error';
  const alertTitle = isForbidden ? 'Forbidden' : 'Error';
  const mailSubject =
    `[BOMSC][${statusCode}] ` +
    (isForbidden
      ? 'Request for permission to access'
      : 'Server encountered an unexpected condition');
  const mailLink = mailTo({ subject: mailSubject });

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (!authenticated) {
      navigate('/login');
    }

    if (isPermitted) {
      navigate('/');
    }
  }, [authenticated, isPermitted, navigate]);

  return isLoading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Container maxWidth="sm">
        <Header>
          <Title variant="h1">Access Restricted</Title>
          <Description>
            <Alert severity={alertSeverity} variant="outlined">
              <AlertTitle>{alertTitle}</AlertTitle>
              {alertMessage}
            </Alert>
          </Description>
        </Header>
        <Main>
          <Support>
            <Card>
              <CardContent>
                <Help>
                  {isForbidden ? (
                    <Typography>
                      <Link href={mailLink}>Request access</Link> from the
                      administrator.
                    </Typography>
                  ) : (
                    <Typography>
                      <Link href={mailLink}>Contact the administrator</Link> for
                      help.
                    </Typography>
                  )}
                </Help>
                <Contacts />
              </CardContent>
            </Card>
          </Support>
          <Actions>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                component={Link}
                href="/"
                startIcon={<LockResetIcon />}
                variant="outlined"
              >
                Refresh
              </Button>
              <Button
                onClick={handleLogout}
                startIcon={<LogoutIcon />}
                variant="outlined"
              >
                Sign out
              </Button>
            </Stack>
          </Actions>
        </Main>
      </Container>
    </Wrapper>
  );
};

export { Unauthorized };
