import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ResourceClientUpdate } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionResourceDetail,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ResourceForm2,
  validationSchema2,
} from '@bom-nextgen-keycloak/web/pages/feature-resources';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  FormActionsGroup,
  FormSpinner,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import { useResourceDetail2 } from '../../hooks';
import { PARENT_PATH } from '../../shared';

type Form = {
  displayName: string;
  name: string;
  scopes: AutoCompleteOption[];
  type: string;
};

const General: FC = () => {
  const { id = '' } = useParams();
  const { clientId } = useResource();
  const navigate = useNavigate();
  const { data, isLoading, updateMutation } = useResourceDetail2(id);
  const { isEditGeneralTab: canEdit } = useSelector(
    selectPermissionResourceDetail
  );

  const initialValues: Form = {
    displayName: data?.displayName ?? '',
    name: data?.name ?? '',
    scopes:
      data?.scopes?.map((item) => ({
        label: item?.name ?? '',
        value: item,
      })) ?? [],
    type: data?.type ?? '',
  };

  const handleUpdate = (payload: ResourceClientUpdate) => {
    updateMutation.mutate({
      clientId,
      payload: {
        ...payload,
        attributes: data?.attributes,
      },
    });
  };

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  return isLoading ? (
    <FormSpinner />
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(payload: Form, { setSubmitting }: FormikHelpers<Form>) => {
        if (data) {
          const { displayName, name, scopes, type } = payload;

          handleUpdate({
            ...data,
            _id: data?._id || '',
            displayName,
            name,
            scopes: scopes.map(({ value }) => ({
              id: value.id,
              name: value.name,
            })),
            type,
          });
          setSubmitting(false);
        } else {
          // TODO: Implement a handler
        }
      }}
      validationSchema={validationSchema2}
    >
      {({
        dirty,
        getFieldMeta,
        isValid,
        resetForm,
        setFieldTouched,
        setFieldValue,
        values,
      }) => {
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: updateMutation.isLoading,
          isValid,
        });

        return (
          <Form>
            <ResourceForm2
              getFieldMeta={getFieldMeta}
              isEdit
              setFieldValue={setFieldValue}
              scopes={values.scopes}
              setFieldTouched={setFieldTouched}
            />
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={updateMutation.isLoading}
              onCancel={handleCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const WrappedResourceGeneralPage: FC = () => {
  const { canViewGeneralTab } = useSelector(selectPermissionResourceDetail);

  return (
    <ProtectedRouter
      canView={canViewGeneralTab}
      feature={FeaturePage.RESOURCE}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <General />
    </ProtectedRouter>
  );
};

export { WrappedResourceGeneralPage };
