import { ResourceClientUpdate } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchResourceById,
  QUERY_KEY,
  updateResource,
  UpdateResourceParams,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

export const useResourceDetail = (resourceId: string) => {
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading, data: resourceDetail } = useQuery(
    [QUERY_KEY.RESOURCE_DETAIL, resourceId],
    () => fetchResourceById(resource.clientId, resourceId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get role detail';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
        navigate('/resources');
      },
    }
  );

  const updateResourceMutation = useMutation(updateResource, {
    onSuccess: (data) => {
      setNotification({
        message: 'Resource updated',
        type: 'success',
      });
      queryClient.invalidateQueries([QUERY_KEY.RESOURCE_DETAIL, resourceId]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot update role';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleUpdate = (payload: ResourceClientUpdate) => {
    const payloadUpdateRole: UpdateResourceParams = {
      clientId: resource.clientId,
      payload: { ...payload, attributes: resourceDetail?.attributes },
    };
    updateResourceMutation.mutate(payloadUpdateRole);
  };

  const handleSaveAttribute = (attributes: any) => {
    if (resourceDetail) {
      const payloadClientRole: ResourceClientUpdate = {
        ...resourceDetail,
        _id: resourceDetail._id || '',
        name: resourceDetail.name,
        attributes: { ...attributes },
      };
      const payload: UpdateResourceParams = {
        clientId: resource.clientId,
        payload: payloadClientRole,
      };
      updateResourceMutation.mutate(payload);
    }
  };

  return {
    isLoading,
    isLoadingSubmit: updateResourceMutation.isLoading,
    resourceDetail,
    handleUpdate,
    handleSaveAttribute,
  };
};
