import { DateRange } from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  DateRangePickerPro,
  SearchButton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  convertLocalToUTCDate,
  convertUTCToLocalDate,
} from '@bom-nextgen-keycloak/web/shared/util';
import { Action, Form, Wrapper } from './AdvancedSearch.styled';

type Datepicker = Date | null;

export type AdvancedSearchCriteria = {
  endDate: Datepicker;
  startDate: Datepicker;
};

type AdvancedSearchProps = {
  onApply: (data: AdvancedSearchCriteria) => void;
  params: AdvancedSearchCriteria;
};

const AdvancedSearch: FC<AdvancedSearchProps> = ({ onApply, params }) => {
  const defaultCriteria = useMemo(
    () => ({
      endDate: null,
      startDate: null,
    }),
    []
  );

  const initialCriteria = useMemo(
    () => ({
      endDate: params?.endDate || defaultCriteria.endDate,
      startDate: params?.startDate || defaultCriteria.startDate,
    }),
    [defaultCriteria, params]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [criteria, setCriteria] =
    useState<AdvancedSearchCriteria>(initialCriteria);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const canAdvancedSearchOpen = isPopoverOpen && Boolean(anchorEl);
  const id = canAdvancedSearchOpen ? 'advanced-search-popover' : undefined;
  const isFilter = !!criteria.endDate || !!criteria.startDate;

  useEffect(() => {
    setCriteria(initialCriteria);
  }, [initialCriteria]);

  const handleDateChange = (event: DateRange<Datepicker | null>) => {
    const [startDate, endDate] = event;

    if (startDate && endDate) {
      setCriteria((previous) => ({
        ...previous,
        endDate: convertLocalToUTCDate(endDate),
        startDate: convertLocalToUTCDate(startDate),
      }));
    } else {
      // TODO: Implement a handler
    }
  };

  const handleClear = () => {
    setCriteria(defaultCriteria);
  };

  const handleRestore = () => {
    setCriteria(initialCriteria);
  };

  const handleSearch = () => {
    handleClose();
    onApply(criteria);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);

    setCriteria((previous) => ({
      ...previous,
      ...initialCriteria,
    }));
  };

  return (
    <Fragment>
      <SearchButton aria-describedby={id} onClick={handleOpen}>
        Advanced Search
      </SearchButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id={id}
        onClose={handleClose}
        open={isPopoverOpen}
      >
        <Wrapper>
          <Form>
            <Box mb={2.5}>
              <DateRangePickerPro
                endText="End date"
                endTextHelper="Select date 1 day after"
                onChange={handleDateChange}
                startText="Start date"
                startTextHelper="Select date 1 day before"
                value={[
                  convertUTCToLocalDate(criteria.startDate),
                  convertUTCToLocalDate(criteria.endDate),
                ]}
              />
            </Box>
          </Form>
          <Action>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button onClick={handleClose} variant="outlined">
                Close
              </Button>
              <Button onClick={handleRestore} variant="outlined">
                Restore
              </Button>
              <Button
                disabled={!isFilter}
                onClick={handleClear}
                variant="outlined"
              >
                Clear
              </Button>
              <Button onClick={handleSearch} variant="contained">
                Apply
              </Button>
            </Stack>
          </Action>
        </Wrapper>
      </Popover>
    </Fragment>
  );
};

export { AdvancedSearch };
