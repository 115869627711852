import * as yup from 'yup';
import { GroupPolicy } from '@bom-nextgen-keycloak/models';
import { CONFIG, PolicyForm, VALIDATION_SCHEMA } from '../../shared/config';

export const validationSchema = yup.object({
  ...VALIDATION_SCHEMA,
  roles: yup.array().min(1, CONFIG.form.fields.groups.message.validation),
});

export type GroupForm = PolicyForm & {
  groupClaim: string;
  groups: GroupPolicy[];
};
