import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { useAlertMessage, useResource } from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  validateEmail,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { DialogPro, TextField } from '@bom-nextgen-keycloak/web/shared/ui';
import {
  EMAIL_REGEX,
  ENGLISH_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';

type UserValidateDialogProps = {
  open: boolean;
  onClose: (email: string) => void;
  onValidEmailAndGroup: (userId: string) => void;
};

const validationEmailSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email address')
    .matches(EMAIL_REGEX, 'Invalid email address')
    .required('Email is required'),
});

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: '',
};

const UserValidateDialog: FC<UserValidateDialogProps> = ({
  onClose,
  onValidEmailAndGroup,
  open,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const { displayName, groupId } = useResource();

  const validateEmailMutation = useMutation(validateEmail, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot validate SSO account';

      setAlertMessage({
        message:
          error.response?.status === 422 ? 'Invalid email address' : message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: (data, payload) => {
      const { isValidEmail, isValidGroup, userId } = data;
      const { email } = payload;
      const alertMessage = `User with email ${email} and ID ${userId} already exists`;

      if (isValidEmail && !isValidGroup) {
        onValidEmailAndGroup(userId);
      } else if (isValidGroup && isValidEmail) {
        setAlertMessage({
          message: `${alertMessage} and is a member of <b>${displayName}</b>.`,
          typeStatusMessage: 'error',
        });
      } else if (isValidEmail) {
        setAlertMessage({
          message: alertMessage,
          typeStatusMessage: 'error',
        });
      } else {
        onClose(email);
      }
    },
  });

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    validateEmailMutation.mutate({
      email: values.email.toLowerCase(),
      groupId: groupId,
    });
    setSubmitting(false);
  };

  const handleCancel = () => {
    onClose('');
  };

  const handleClose = (
    event: unknown,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (validateEmailMutation.isLoading && reason === 'backdropClick') {
      return;
    }

    handleCancel();
  };

  return (
    <DialogPro
      aria-describedby="validate-account-dialog-description"
      aria-labelledby="validate-account-dialog-title"
      onClose={handleClose}
      open={open}
      width={480}
    >
      <DialogTitle id="validate-account-dialog-title">
        Check account availability
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationEmailSchema}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue }) => (
          <Form>
            <DialogContent>
              <TextField
                autoFocus
                label="Email"
                name="email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.value;
                  value = value.replace(ENGLISH_REGEX, '');

                  setFieldValue('email', value);
                }}
                placeholder="Enter valid email address"
                required
                type="email"
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!(isValid && dirty) || isSubmitting}
                loading={validateEmailMutation.isLoading}
                type="submit"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogPro>
  );
};

export { UserValidateDialog };
