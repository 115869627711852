import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { PermissionUpdate } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchPermissionById,
  QUERY_KEY,
  updatePermission,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { CONFIG, PARENT_PATH } from '../shared';

export const usePermissionDetail = (
  clientId: string,
  id: string,
  type: string
) => {
  const defaultData = null;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();

  const queryKeys = [QUERY_KEY.PERMISSION_DETAIL, id, type];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    () => fetchPermissionById(clientId, id, type),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get permission detail';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
        navigate(PARENT_PATH);
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const updateMutation = useMutation(updatePermission, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot update permission';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: () => {
      setNotification({
        message: 'Permission updated',
        type: 'success',
      });
      handleReload();
    },
  });

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const handleUpdate = (payload: PermissionUpdate) => {
    updateMutation.mutate({
      clientId,
      payload,
      type,
    });
  };

  return {
    data,
    isLoading,
    isUpdating: updateMutation.isLoading,
    handleUpdate,
  };
};
