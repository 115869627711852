import * as yup from 'yup';
import {
  CONFIG as DEFAULT_CONFIG,
  PERMISSION_TYPES,
} from '@bom-nextgen-keycloak/web/core';
import { AutoCompleteOption } from '@bom-nextgen-keycloak/web/shared/ui';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';

export const CONFIG = {
  ...DEFAULT_CONFIG,
  form: {
    fields: {
      decisionStrategy: {
        message: {
          validation: 'Decision strategy is required',
        },
        name: 'decisionStrategy',
        label: 'Decision strategy',
        placeholder: 'Select decision strategy',
      },
      description: {
        name: 'description',
        label: 'Description',
      },
      isResourceType: {
        name: 'isResourceType',
        label: 'Apply to resource type',
      },
      name: {
        message: {
          validation: 'Name is required',
        },
        name: 'name',
        label: 'Name',
      },
      policies: {
        name: 'policies',
        label: 'Policies',
        placeholder: 'Enter policy name',
      },
      resources: {
        message: {
          validation: 'Resource is required',
        },
        name: 'resources',
        label: 'Resource',
        placeholder: 'Enter resource name',
      },
      resourceType: {
        message: {
          validation: 'Resource type is required',
        },
        name: 'resourceType',
        label: 'Resource type',
      },
      scopes: {
        message: {
          validation: 'Scopes is required',
        },
        name: 'scopes',
        label: 'Scopes',
        placeholder: 'Enter scope name',
      },
      type: {
        name: 'type',
        label: 'Permission type',
      },
    },
  },
  pagination: {
    ...DEFAULT_CONFIG.pagination,
    first: DEFAULT_CONFIG.pagination.offset,
    max: DEFAULT_CONFIG.pagination.step,
  },
};

export const PARENT_PATH = '/permissions';

export const validationSchema = yup.lazy(({ isResourceType, type }) => {
  const isResource = type === PERMISSION_TYPES.resource;

  if (isResource && !isResourceType) {
    return yup.object({
      name: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required(CONFIG.form.fields.name.message.validation),
      resources: yup
        .object()
        .nullable()
        .required(CONFIG.form.fields.resources.message.validation),
      policies: yup.array(),
      decisionStrategy: yup
        .string()
        .required(CONFIG.form.fields.decisionStrategy.message.validation),
    });
  } else if (isResource && isResourceType) {
    return yup.object({
      name: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required(CONFIG.form.fields.name.message.validation),
      resourceType: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required(CONFIG.form.fields.resourceType.message.validation),
      policies: yup.array(),
      decisionStrategy: yup
        .string()
        .required(CONFIG.form.fields.decisionStrategy.message.validation),
    });
  } else {
    return yup.object({
      name: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required(CONFIG.form.fields.name.message.validation),
      resources: yup
        .object()
        .nullable()
        .required(CONFIG.form.fields.resources.message.validation),
      scopes: yup.array().min(1, CONFIG.form.fields.scopes.message.validation),
      decisionStrategy: yup
        .string()
        .required(CONFIG.form.fields.decisionStrategy.message.validation),
      policies: yup.array(),
    });
  }
});

export type FormType = {
  decisionStrategy: string;
  description: string;
  isResourceType: boolean;
  name: string;
  policies: AutoCompleteOption[];
  resources: AutoCompleteOption | null;
  resourceType: string;
  scopes: AutoCompleteOption[];
  type: string;
};
