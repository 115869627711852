import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const Actions = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 3rem;
`;

export const Body = styled('div')`
  background-color: ${grey[50]};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 48px);
  margin-top: 48px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const Head = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: flex-start;
`;

export const Header = styled('div')`
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
`;

export const Title = styled((props: TypographyProps) => (
  <Typography {...props} variant="h1" />
))`
  display: flex;
  flex-grow: 1;
  font-size: 1rem;
`;

export const Wrapper = styled('div')<{ width: number | undefined }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: ${(props) => (props.width ? props.width + 'px' : '640px')};
`;
