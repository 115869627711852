import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { SearchButton } from '@bom-nextgen-keycloak/web/shared/ui';
import { policyOptions } from '../../shared/constants';
import { Action, Form, Wrapper } from './AdvancedSearch.styled';

export type AdvancedSearchCriteria = {
  type: string;
};

type PolicyQuery = {
  type?: string;
};

type AdvancedSearchProps = {
  onApply: (data: AdvancedSearchCriteria) => void;
  query: PolicyQuery;
};

const AdvancedSearch: FC<AdvancedSearchProps> = ({ onApply, query }) => {
  const defaultCriteria = useMemo(
    () => ({
      type: 'all',
    }),
    []
  );

  const initialCriteria = useMemo(
    () => ({
      type: query?.type || defaultCriteria.type,
    }),
    [defaultCriteria, query]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [criteria, setCriteria] =
    useState<AdvancedSearchCriteria>(initialCriteria);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const canAdvancedSearchOpen = isPopoverOpen && Boolean(anchorEl);
  const id = canAdvancedSearchOpen ? 'advanced-search-popover' : undefined;

  useEffect(() => {
    setCriteria(initialCriteria);
  }, [initialCriteria]);

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setCriteria((previous) => ({
      ...previous,
      type: event.target.value,
    }));
  };

  const handleClear = () => {
    setCriteria(defaultCriteria);
  };

  const handleRestore = () => {
    setCriteria(initialCriteria);
  };

  const handleSearch = () => {
    handleClose();
    onApply(criteria);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);

    setCriteria((previous) => ({
      ...previous,
      ...initialCriteria,
    }));
  };

  return (
    <Fragment>
      <SearchButton aria-describedby={id} onClick={handleOpen}>
        Advanced Search
      </SearchButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id={id}
        onClose={handleClose}
        open={isPopoverOpen}
      >
        <Wrapper>
          <Form>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel id="policy-type-select-label">Type</InputLabel>
                <Select
                  defaultValue="role"
                  id="policy-type-select"
                  label="Type"
                  labelId="policy-type-select-label"
                  name="type"
                  onChange={handleTypeChange}
                  value={criteria.type}
                >
                  <MenuItem key="all" value="all">
                    All
                  </MenuItem>
                  {policyOptions.map((option) => {
                    const { label, value } = option;

                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Form>
          <Action>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button onClick={handleClose} variant="outlined">
                Close
              </Button>
              <Button onClick={handleRestore} variant="outlined">
                Restore
              </Button>
              <Button onClick={handleClear} variant="outlined">
                Clear
              </Button>
              <Button onClick={handleSearch} variant="contained">
                Apply
              </Button>
            </Stack>
          </Action>
        </Wrapper>
      </Popover>
    </Fragment>
  );
};

export { AdvancedSearch, policyOptions };
