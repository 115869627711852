import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  EnvironmentEntity,
  selectPermissionEnvironment,
  useEnvironment,
} from '@bom-nextgen-keycloak/web/core';
import {
  Description,
  Dialog,
  FormControlLabel,
  Note,
  Radio,
} from './Environment.styled';

type EnvironmentActive = Pick<
  EnvironmentEntity,
  'description' | 'name' | 'note' | 'url'
>;

type EnvironmentProps = {
  onChange: (url: string) => void;
  onClose: () => void;
  open: boolean;
};

const Environment: FC<EnvironmentProps> = ({ onChange, onClose, open }) => {
  const { description, list, name, note, onCloud, url } = useEnvironment();
  const activeEnvironment = useMemo(
    () => ({ description, name, note, url }),
    [description, name, note, url]
  );
  const { canDebug } = useSelector(selectPermissionEnvironment);
  const [selectedItem, setSelectedItem] =
    useState<EnvironmentActive>(activeEnvironment);

  const isClean = selectedItem.name === activeEnvironment.name;
  const environmentList = list.map((item) => {
    if (!item.debug) {
      return item;
    }

    return {
      ...item,
      enabled: canDebug,
    };
  });

  if (onCloud) {
    environmentList.shift();
  }

  const handleClose = () => {
    setSelectedItem(activeEnvironment);
    onClose();
  };

  const handleChange = () => {
    onChange(`${selectedItem.url}/login`);
    onClose();
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const environment =
      environmentList.find((item) => item.name === value) || activeEnvironment;

    setSelectedItem(environment);
  };

  useEffect(() => {
    setSelectedItem(activeEnvironment);
  }, [activeEnvironment]);

  return (
    <Dialog
      aria-describedby="environment-dialog-description"
      aria-labelledby="environment-dialog-title"
      onClose={handleClose}
      open={open}
      scroll="paper"
    >
      <DialogTitle id="environment-dialog-title">
        Select an environment
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl>
              <RadioGroup
                onChange={handleSelect}
                name="environment-list"
                value={selectedItem.name}
              >
                {environmentList.map((item, index) => {
                  const { enabled, name, title } = item;
                  const isActive = name === activeEnvironment.name;
                  const disableOption = !enabled;

                  return (
                    <FormControlLabel
                      className={isActive ? 'active' : ''}
                      control={<Radio />}
                      disabled={disableOption}
                      key={`${index}-${name}`}
                      label={title}
                      value={name}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Description>
              <Typography>{selectedItem.description}</Typography>
            </Description>
            {selectedItem.note && (
              <Note>
                <Typography>{selectedItem.note}</Typography>
              </Note>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} variant="text">
          Close
        </Button>
        <Button
          autoFocus
          disabled={isClean}
          onClick={handleChange}
          variant="text"
        >
          Open
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { Environment };
