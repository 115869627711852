import EditIcon from '@mui/icons-material/Edit';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC } from 'react';

const EditButton: FC<ButtonProps> = ({ children = 'Edit', ...rest }) => {
  return (
    <Button
      color="primary"
      startIcon={<EditIcon />}
      variant="outlined"
      {...rest}
    >
      {children}
    </Button>
  );
};

export { EditButton };
