import Box from '@mui/material/Box';
import { FC } from 'react';
import { Skeleton, Wrapper } from './Placeholder.styled';

type PlaceholderProps = {
  amount?: number;
  spacing?: number;
};

const Placeholder: FC<PlaceholderProps> = ({ amount = 1, spacing = 0.5 }) => {
  if (amount === 1) {
    return <Skeleton />;
  }

  const count = [];

  for (let i = 0; i < amount; i++) {
    count.push(i);
  }

  return (
    <Wrapper>
      {count.map((value, index) => (
        <Box key={`${value}-${index}`} mb={spacing}>
          <Skeleton />
        </Box>
      ))}
    </Wrapper>
  );
};

export { Placeholder };
