// TODO: Clean up
import { Authenticated } from '@bom-nextgen-keycloak/web/pages/feature-authenticated';
import {
  // WrappedLoginEventPage as LoginEvent,
  WrappedLoginEventPage as LoginEvent,
  // WrappedEventPermissionPage as PermissionEvent,
  WrappedPermissionEventPage as PermissionEvent,
  // WrappedEventPermissionDetailPage,
  WrappedPermissionEventDetailPage,
  // WrappedPermissionEventDetailPage as PermissionEventDetail,
  WrappedPermissionEventGeneralPage as PermissionEventGeneral,
} from '@bom-nextgen-keycloak/web/pages/feature-events';
import {
  // WrappedGroupAttributePage as GroupAttribute,
  WrappedGroupAttributePage2 as GroupAttribute,
  // WrappedGroupDetailPage as GroupDetailFrom,
  WrappedGroupGeneralPage as GroupGeneral,
  // WrappedGroupMemeberPage as GroupMemeber,
  WrappedGroupMemberPage2 as GroupMember,
  // WrappedGroupRoleMappingPage as GroupRoleMapping,
  WrappedRoleMappingPage as GroupRoleMapping,
} from '@bom-nextgen-keycloak/web/pages/feature-group-detail';
import { WrappedPermissionGeneralPage as PermissionGeneral } from '@bom-nextgen-keycloak/web/pages/feature-permission-detail';
import { WrappedPolicyGeneralPage as PolicyGeneral } from '@bom-nextgen-keycloak/web/pages/feature-policy-detail';
import { Login } from '@bom-nextgen-keycloak/web/pages/feature-login';
import { PageNotFound } from '@bom-nextgen-keycloak/web/pages/feature-page-not-found';
import {
  WrappedResourceAttributePage as ResourceAttribute,
  // WrappedResourceDetailFormPage as ResourceDetailForm,
  WrappedResourceGeneralPage as ResourceGeneral,
} from '@bom-nextgen-keycloak/web/pages/feature-resource-detail';
import {
  // WrappedRoleAttributePage as RoleAttribute,
  WrappedRoleAttributePage2 as RoleAttribute,
  // WrappedRoleDetailFormPage as RoleDetailForm,
  WrappedRoleGeneralPage as RoleGeneral,
  // WrappedUserInRolePage as UserInRole,
  WrappedUserInRolePage2 as UserInRole,
} from '@bom-nextgen-keycloak/web/pages/feature-role-detail';
import { Unauthorized } from '@bom-nextgen-keycloak/web/pages/feature-unauthorized';
import {
  // UserAttribute,
  // UserDetailForm,
  // WrappedCredentialPage,
  // WrappedGroupPage as UserGroup,
  // WrappedIdentityProviderPage,
  WrappedRoleMappingPage,
  WrappedUserAttributePage,
  WrappedUserIdpPage,
  WrappedUserCredentialPage,
  WrappedUserGeneralPage,
  WrappedUserGroupPage,
  WrappedUserSessionPage,
} from '@bom-nextgen-keycloak/web/pages/feature-user-detail';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import { WrappedScopeGeneralPage as ScopeGeneral } from '@bom-nextgen-keycloak/web/pages/feature-scope-detail';
// import { WrappedPermissionGeneralPage as PermissionGeneral } from '@bom-nextgen-keycloak/web/pages/feature-permission-detail';
import { ProtectedRouter } from '@bom-nextgen-keycloak/web/shared/ui';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import {
  About,
  Dashboard,
  Company,
  Event,
  Group,
  GroupDetail,
  Home,
  Permission,
  PermissionDetail,
  Policy,
  PolicyDetail,
  privateRoute,
  Resource,
  ResourceDetail,
  Role,
  RoleDetail,
  Scope,
  ScopeDetail,
  User,
  UserDetail,
} from './routes';

const Router: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Authenticated privateRoute={privateRoute} />}>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/companies"
          element={
            <ProtectedRouter feature={FeaturePage.COMPANY}>
              <Company />
            </ProtectedRouter>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRouter feature={FeaturePage.USER}>
              <User />
            </ProtectedRouter>
          }
        />
        <Route path="/users/:id" element={<UserDetail />}>
          <Route index element={<WrappedUserGeneralPage />} />
          {/* <Route index element={<UserDetailForm />} /> */}
          <Route path="attributes" element={<WrappedUserAttributePage />} />
          <Route path="credentials" element={<WrappedUserCredentialPage />} />
          <Route path="groups" element={<WrappedUserGroupPage />} />
          <Route path="role-mappings" element={<WrappedRoleMappingPage />} />
          <Route path="sessions" element={<WrappedUserSessionPage />} />
          <Route
            path="identity-provider-links"
            element={<WrappedUserIdpPage />}
          />
        </Route>
        <Route
          path="/groups"
          element={
            <ProtectedRouter feature={FeaturePage.GROUP}>
              <Group />
            </ProtectedRouter>
          }
        />
        <Route path="/groups/:id" element={<GroupDetail />}>
          <Route index element={<GroupGeneral />} />
          <Route path="attributes" element={<GroupAttribute />} />
          <Route path="members" element={<GroupMember />} />
          <Route path="role-mappings" element={<GroupRoleMapping />} />
        </Route>
        <Route
          path="/roles"
          element={
            <ProtectedRouter feature={FeaturePage.ROLE}>
              <Role />
            </ProtectedRouter>
          }
        />
        <Route path="/roles/:id" element={<RoleDetail />}>
          <Route index element={<RoleGeneral />} />
          <Route path="attributes" element={<RoleAttribute />} />
          <Route path="users" element={<UserInRole />} />
        </Route>
        <Route
          path="/scopes"
          element={
            <ProtectedRouter feature={FeaturePage.SCOPE}>
              <Scope />
            </ProtectedRouter>
          }
        />
        <Route path="/scopes/:id" element={<ScopeDetail />}>
          <Route index element={<ScopeGeneral />} />
        </Route>
        <Route
          path="/resources"
          element={
            <ProtectedRouter feature={FeaturePage.RESOURCE}>
              <Resource />
            </ProtectedRouter>
          }
        />
        <Route path="/resources/:id" element={<ResourceDetail />}>
          <Route index element={<ResourceGeneral />} />
          <Route path="attributes" element={<ResourceAttribute />} />
        </Route>
        <Route
          path="/policies"
          element={
            <ProtectedRouter feature={FeaturePage.POLICY}>
              <Policy />
            </ProtectedRouter>
          }
        />
        {/* <Route path="/policies/:id/:type" element={<PolicyDetail />} /> */}
        <Route path="/policies/:id/:type" element={<PolicyDetail />}>
          <Route index element={<PolicyGeneral />} />
        </Route>

        <Route
          path="/permissions"
          element={
            <ProtectedRouter feature={FeaturePage.PERMISSION}>
              <Permission />
            </ProtectedRouter>
          }
        />
        {/* TODO */}
        <Route path="/permissions/:id/:type" element={<PermissionDetail />}>
          <Route index element={<PermissionGeneral />} />
        </Route>

        <Route
          path="/events"
          element={
            <ProtectedRouter feature={FeaturePage.EVENT}>
              <Event />
            </ProtectedRouter>
          }
        >
          <Route path="permission" element={<PermissionEvent />} />
          <Route path="login" element={<LoginEvent />} />
          <Route index element={<Navigate to="/events/permission" />} />
        </Route>

        {/* TODO */}
        <Route
          path="/events/permission/:id"
          element={<WrappedPermissionEventDetailPage />}
        >
          <Route index element={<PermissionEventGeneral />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

export default Router;
