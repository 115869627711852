import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useAlertMessage } from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchLoggingById,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { PARENT_PATH } from '../shared';

export const usePermissionEventDetail2 = (id: string) => {
  const defaultData = null;
  const navigate = useNavigate();
  const { setAlertMessage } = useAlertMessage();

  const queryKeys = [QUERY_KEY.LOGGING_DETAIL, id];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    () => fetchLoggingById(id),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get permission event detail';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
        navigate(PARENT_PATH);
      },
    }
  );

  return {
    data,
    isLoading,
  };
};
