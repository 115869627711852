import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import { RoleRepresentationDto } from '@bom-nextgen-keycloak/models';
import {
  Form,
  FormBox,
  intersection,
  SelectCombo,
  SelectComboItem,
} from '../../index';
import { Actions } from './RoleMapper.styled';

type RoleMapperProps = {
  canEdit?: boolean;
  loading?: boolean;
  onAdd: (payload: SelectComboItem[]) => void;
  onRemove: (payload: SelectComboItem[]) => void;
  onSelect: (payload: SelectComboItem[]) => void;
  roleAvailable: SelectComboItem[];
  roleComposite: SelectComboItem[];
  roleMapping: SelectComboItem[];
  selected?: SelectComboItem[];
};

export const mapRoleToSelect = (
  data: RoleRepresentationDto[]
): SelectComboItem[] => {
  return data.map(({ id, name }) => ({ id: id || '', name: name || '' }));
};

const RoleMapper: FC<RoleMapperProps> = ({
  canEdit = false,
  loading = false,
  onAdd,
  onRemove,
  onSelect,
  roleAvailable,
  roleComposite,
  roleMapping,
  selected = [],
}) => {
  const selectedCount = selected.length;
  const hasSelectedItems = selectedCount !== 0;

  const handleSelect = (value: SelectComboItem[]) => {
    onSelect(value);
  };

  const handleAdd = () => {
    if (hasSelectedItems) {
      onAdd(selected);
    } else {
      // TODO: Implement a handler
    }
  };

  const handleRemove = () => {
    if (hasSelectedItems) {
      onRemove(selected);
    } else {
      // TODO: Implement a handler
    }
  };

  const selectedAvailable = intersection(selected, roleAvailable);
  const selectedAssigned = intersection(selected, roleMapping);
  const disableAddButton = selectedAvailable.length === 0 || !canEdit;
  const disableRemoveButton = selectedAssigned.length === 0 || !canEdit;
  const addButtonTitle = disableAddButton ? '' : 'Add selected roles';
  const removeButtonTitle = disableRemoveButton ? '' : 'Remove selected roles';

  return (
    <Form>
      <FormBox>
        <Stack alignItems="center" direction="row" spacing={5}>
          <SelectCombo
            checked={selected}
            loading={loading}
            onSelect={(selected) => handleSelect(selected)}
            title="Available Roles"
            value={roleAvailable}
          />
          <Actions>
            <Stack spacing={2}>
              <Tooltip title={addButtonTitle}>
                <span>
                  <Button
                    aria-label="add selected roles"
                    disabled={disableAddButton}
                    onClick={handleAdd}
                    size="medium"
                    variant="outlined"
                  >
                    <ArrowForwardIcon fontSize="small" />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={removeButtonTitle}>
                <span>
                  <Button
                    aria-label="remove selected roles"
                    color="error"
                    disabled={disableRemoveButton}
                    onClick={handleRemove}
                    size="medium"
                    variant="outlined"
                  >
                    <ArrowBackIcon fontSize="small" />
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Actions>
          <SelectCombo
            checked={selected}
            loading={loading}
            onSelect={(selected) => handleSelect(selected)}
            title="Assigned Roles"
            value={roleMapping}
          />
          <SelectCombo
            checked={selected}
            loading={loading}
            readonly
            subtitle="All client role mappings"
            title="Effective Roles"
            value={roleComposite}
          />
        </Stack>
      </FormBox>
    </Form>
  );
};

export { RoleMapper };
