import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  selectPermissionUserDetail,
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  addUserRoleMapping,
  ErrorMessage,
  FeaturePage,
  fetchUserRoleAvailable,
  fetchUserRoleComposite,
  fetchUserRoleMapping,
  QUERY_KEY,
  updateUserRoleMapping,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  mapRoleToSelect,
  ProtectedRouter,
  RoleMapper,
  SelectComboItem,
} from '@bom-nextgen-keycloak/web/shared/ui';

import { PARENT_PATH } from '../../shared';

export type MutationPayload = {
  clientId: string;
  payload: {
    roles: SelectComboItem[];
  };
  userId: string;
};

const RoleMapping2: FC = () => {
  const { id = '' } = useParams();
  const resource = useResource();
  const { clientId } = resource;
  const queryClient = useQueryClient();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const { isEditRoleMappingTab: canEdit } = useSelector(
    selectPermissionUserDetail
  );
  const [selected, setSelected] = useState<SelectComboItem[]>([]);

  const defaultData = {
    roleAvailable: [],
    roleComposite: [],
    roleMapping: [],
  };
  const queryKeys = [QUERY_KEY.USER_ROLE_MAPPING, id];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    () =>
      Promise.all([
        fetchUserRoleAvailable(id, clientId),
        fetchUserRoleComposite(id, clientId),
        fetchUserRoleMapping(id, clientId),
      ]).then(([roleAvailable, roleComposite, roleMapping]) => ({
        roleAvailable: mapRoleToSelect(roleAvailable) || [],
        roleComposite: mapRoleToSelect(roleComposite) || [],
        roleMapping: mapRoleToSelect(roleMapping) || [],
      })),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get group list';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
    }
  );

  const addMutation = useMutation(addUserRoleMapping, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot add role';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSettled: async () => {
      handleDeselect();
    },
    onSuccess: () => {
      setNotification({
        message: 'Added roles',
        type: 'success',
      });
      handleReload();
    },
  });

  const updateMutation = useMutation(updateUserRoleMapping, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot update role mappings';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSettled: async () => {
      handleDeselect();
    },
    onSuccess: () => {
      setNotification({
        message: 'Removed roles',
        type: 'success',
      });
      handleReload();
    },
  });

  const generateMutationPayload = (
    value: SelectComboItem[]
  ): MutationPayload => {
    const payload = {
      clientId,
      payload: { roles: value },
      userId: id,
    };

    return payload;
  };

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const handleSelect = (value: SelectComboItem[]) => {
    setSelected(value);
  };

  const handleDeselect = () => {
    setSelected([]);
  };

  const handleAdd = (value: SelectComboItem[]) => {
    addMutation.mutate(generateMutationPayload(value));
  };

  const handleRemove = (value: SelectComboItem[]) => {
    updateMutation.mutate(generateMutationPayload(value));
  };

  const { roleAvailable, roleComposite, roleMapping } = data;
  const isProcessing =
    isLoading || addMutation.isLoading || updateMutation.isLoading;

  return (
    <RoleMapper
      canEdit={canEdit}
      loading={isProcessing}
      onAdd={handleAdd}
      onRemove={handleRemove}
      onSelect={handleSelect}
      roleAvailable={roleAvailable}
      roleComposite={roleComposite}
      roleMapping={roleMapping}
      selected={selected}
    />
  );
};

const WrappedRoleMappingPage: FC = () => {
  const { canViewRoleMappingTab } = useSelector(selectPermissionUserDetail);

  return (
    <ProtectedRouter
      canView={canViewRoleMappingTab}
      feature={FeaturePage.USER}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <RoleMapping2 />
    </ProtectedRouter>
  );
};

export { WrappedRoleMappingPage };
