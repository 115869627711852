import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  useAlertMessage,
  useNotification,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchGroupById,
  QUERY_KEY,
  updateGroup,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { PARENT_PATH } from '../shared';

export const useGroupDetail2 = (groupId: string) => {
  const defaultData = null;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();

  const queryKeys = [QUERY_KEY.USER_DETAIL, groupId];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    () => fetchGroupById(groupId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get group detail';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
        navigate(PARENT_PATH);
      },
      staleTime: 5 * 1000,
    }
  );

  const checkSubscription = () => {
    const regex = new RegExp(`(/Subscriptions/${data?.name ?? ''})`, 'g');

    return data?.path?.match(regex)?.length === 1;
  };

  const isGroup = data?.level === 4;
  const isSubscription = checkSubscription();

  const updateMutation = useMutation(updateGroup, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot update group';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: () => {
      setNotification({
        message: 'Group updated',
        type: 'success',
      });
      handleReload();
    },
  });

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  return {
    data,
    isGroup,
    isLoading: isLoading,
    isSubscription,
    updateMutation,
  };
};
