import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { DialogPro } from '../DialogPro';

export type DeleteDialogProps = {
  description?: string;
  loading: boolean;
  name?: string;
  onClose: () => void;
  onDelete: () => void;
  open: boolean;
  title: string;
  titleBtn?: string;
};

const DeleteDialog: FC<DeleteDialogProps> = ({
  description = 'Delete?',
  loading,
  name = 'delete',
  onClose,
  onDelete,
  open,
  title,
  titleBtn = 'Delete',
}) => {
  const handleCancel = () => {
    onClose();
  };

  const handleClose = (
    event: unknown,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (loading && reason === 'backdropClick') {
      return;
    }

    handleCancel();
  };

  return (
    <DialogPro
      aria-describedby={`${name}-dialog-description`}
      aria-labelledby={`${name}-dialog-title`}
      disableEscapeKeyDown={loading}
      onClose={handleClose}
      open={open}
      width={480}
    >
      <DialogTitle id={`${name}-dialog-title`}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{ __html: description }}
          id={`${name}-dialog-description`}
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={loading} onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          autoFocus
          color="error"
          data-test-id="btn-delete"
          loading={loading}
          onClick={onDelete}
        >
          {titleBtn}
        </LoadingButton>
      </DialogActions>
    </DialogPro>
  );
};

export { DeleteDialog };
