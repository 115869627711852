import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPermissionEvent } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ProtectedRouter,
  TabbedContent,
  TabList,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { usePermissionEventDetail2 } from '../../hooks';
import { PARENT_PATH } from '../../shared';

const PermissionEvent2Detail: FC = () => {
  const { id = '' } = useParams();
  const { data } = usePermissionEventDetail2(id);
  const { canViewPermission } = useSelector(selectPermissionEvent);
  const parentPath = PARENT_PATH;
  const rootPath = `${parentPath}/permission/${id}`;

  const tabList: TabList = [
    {
      enabled: canViewPermission,
      label: 'General',
      value: '',
    },
  ];

  return (
    <TabbedContent
      breadcrumb={data?.name}
      feature="Permission Events"
      parentPath={parentPath}
      rootPath={rootPath}
      tabList={tabList}
    />
  );
};

const WrappedPermissionEventDetailPage: FC = () => {
  const { canViewPermission } = useSelector(selectPermissionEvent);

  return (
    <ProtectedRouter
      canView={canViewPermission}
      feature={FeaturePage.EVENT}
      navigatePath={PARENT_PATH}
    >
      <PermissionEvent2Detail />
    </ProtectedRouter>
  );
};

export { WrappedPermissionEventDetailPage };
