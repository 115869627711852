import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { PolicyUpdateDto } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchPolicyById,
  QUERY_KEY,
  updatePolicyRole,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { CONFIG, PARENT_PATH } from '../shared';

export const usePolicyDetail = (clientId: string, id: string, type: string) => {
  const defaultData = null;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();

  const queryKeys = [QUERY_KEY.POLICY_DETAIL, id];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    () => fetchPolicyById(clientId, id, type),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get policy detail';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
        navigate(PARENT_PATH);
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const updateMutation = useMutation(updatePolicyRole, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot update policy';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: () => {
      setNotification({
        message: 'Policy updated',
        type: 'success',
      });
      handleReload();
    },
  });

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const handleUpdate = (payload: PolicyUpdateDto) => {
    updateMutation.mutate({
      clientId,
      payload,
    });
  };

  return {
    data,
    handleUpdate,
    isLoading,
    isUpdating: updateMutation.isLoading,
  };
};
