import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { LoggingDto } from '@bom-nextgen-keycloak/models';
import { Capitalize, TableCellHead } from '../General/General.styled';

const GeneralUser: FC<Partial<LoggingDto>> = (props) => (
  <TableContainer component={Paper}>
    <Table aria-label="permission event user table">
      <TableBody>
        <TableRow>
          <TableCellHead sx={{ width: 150 }}>User ID</TableCellHead>
          <TableCell>{props?.userId || ''}</TableCell>
        </TableRow>
        <TableRow>
          <TableCellHead>Username</TableCellHead>
          <TableCell>{props?.username || ''}</TableCell>
        </TableRow>
        <TableRow>
          <TableCellHead>Email</TableCellHead>
          <TableCell>{props?.email || '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCellHead>Name</TableCellHead>
          <TableCell>
            <Capitalize>{props?.name || '-'}</Capitalize>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export { GeneralUser };
