import { styled } from '@mui/material/styles';

export const Box = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6px 0;
`;

export const Indicator = styled('div')`
  height: 41px;
`;

export const Title = styled('h3')`
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1;
  margin: 2rem 0 0 0;
`;

export const Wrapper = styled('div')`
  align-items: center;
  border-bottom: 1px solid rgb(224, 224, 224);
  display: flex;
  justify-content: center;
  padding: 2.5rem 0;
`;
