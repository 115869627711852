import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiDialog from '@mui/material/Dialog';
import MuiRadio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

export const Description = styled('div')`
  margin-bottom: 1rem;
`;

export const Dialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    max-width: 600px;
    width: 600px;
  }

  & .MuiDialogContent-root {
    height: 160px;
    padding-bottom: 0;
  }
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  & {
    &.active {
      .MuiFormControlLabel-label {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;

export const Note = styled('div')`
  border-top: solid 1px rgba(0, 0, 0, 0.08);
  padding-top: 1rem;
`;

export const Radio = styled(MuiRadio)`
  & .MuiSvgIcon-root {
    font-size: 0.875rem;
  }
`;
