import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const generateWidth = (value = 600) => value + 'px';

export const StyledDialog = styled(Dialog)<{ width?: number }>`
  & .MuiDialog-paper {
    max-width: ${(props) => generateWidth(props.width)};
    width: ${(props) => generateWidth(props.width)};
  }
`;
