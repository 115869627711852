import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const Control = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 35%;
`;

export const Info = styled('div')`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 65%;
`;

export const Jumper = styled(Select)`
  align-items: center;
  display: flex;
  margin-right: 0.5rem;
  max-height: 39px;
`;

export const Mode = styled('div')`
  display: inline-flex;
  margin-right: 0.5rem;
`;

export const Nav = styled(IconButton)`
  margin-left: 0.5rem;
`;

export const Navigator = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const Status = styled('div')`
  display: block;
`;

export const Step = styled('div')`
  align-items: center;
  display: flex;
`;

export const Text = styled('span')`
  display: inline;
`;

export const Wrapper = styled('div')`
  align-items: center;
  background-color: ${grey[50]};
  border-radius: 0 0 4px 4px;
  display: flex;
  font-size: 0.8125rem;
  justify-content: space-between;
  padding: 5px 1rem;
  position: relative;
`;
