import {
  AutoCompleteOption,
  IconButtonDelete,
} from '@bom-nextgen-keycloak/web/shared/ui';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableContainer as Container } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';

type RoleTableFormProps = {
  clientName: string;
  data: AutoCompleteOption[];
  isDisabled?: boolean;
  onChangeCheckBox: (checked: boolean, roleId: string) => void;
  onClickDelate: (data: AutoCompleteOption) => void;
};

const TableContainer = styled(Container)`
  max-height: 250px;
`;

const RoleTableForm: FC<RoleTableFormProps> = ({
  clientName,
  data,
  onChangeCheckBox,
  onClickDelate,
  isDisabled = false,
}) => {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Resource server</TableCell>
            <TableCell>Required</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.value.id}>
              <TableCell>{item.value.name}</TableCell>
              <TableCell>{clientName}</TableCell>
              <TableCell>
                <Checkbox
                  disabled={isDisabled}
                  checked={item.value.required}
                  onChange={(e) =>
                    onChangeCheckBox(e.target.checked, item.value.id)
                  }
                />
              </TableCell>
              <TableCell>
                <Tooltip title="Remove role">
                  <IconButtonDelete
                    aria-label="delete"
                    disabled={isDisabled}
                    onClick={() => onClickDelate(item)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButtonDelete>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { RoleTableForm };
