import React, { FC } from 'react';

type RenderProps = {
  children: React.ReactNode;
  isVisible?: boolean;
};

const Render: FC<RenderProps> = ({ children, isVisible = false }) => {
  return isVisible ? <div>{children}</div> : null;
};

export { Render };
