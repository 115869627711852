import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { FC, Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ClientRoleMemberQueryDto } from '@bom-nextgen-keycloak/models';
import {
  CONFIG,
  selectPermissionRoleDetail,
  selectPermissionUserDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { UserTable2 } from '@bom-nextgen-keycloak/web/pages/feature-user';
import {
  ErrorMessage,
  FeaturePage,
  fetchClientUserInRole,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  RefreshButton,
  ResetButton,
  Searchbox,
  SearchboxEmit,
  SearchboxOptions,
  ProtectedRouter,
  Toolbar,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  checkProcessing,
  generateHash,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';
import { useRoleDetail } from '../../hooks/useRoleDetail';
import { PARENT_PATH } from '../../shared';

const UserInRole2: FC = () => {
  const { id = '' } = useParams();
  const resource = useResource();
  const { clientId, groupId } = resource;
  const roleData = useRoleDetail(id);
  const roleName = roleData?.roleDetail?.name || '';
  const defaultParams = {
    first: CONFIG.pagination.offset,
    groupId,
    max: CONFIG.pagination.step,
    search: '',
  };
  const defaultData = {
    items: [],
    totalCount: 0,
    totalRecord: 0,
  };
  const { setAlertMessage } = useAlertMessage();
  const { canViewPage: canEdit } = useSelector(selectPermissionUserDetail);
  const [params, setParams] = useState<ClientRoleMemberQueryDto>({
    ...defaultParams,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentOffset, setCurrentOffset] = useState(CONFIG.pagination.offset);
  const [previousOffset, setPreviousOffset] = useState(
    CONFIG.pagination.offset
  );
  const [pageStep, setPageStep] = useState(CONFIG.pagination.step);
  const [queryHash, setQueryHash] = useState(generateHash());
  const [isSearching, setIsSearching] = useState(false);
  const [wasSearch, setWasSearch] = useState(false);
  const debouncedSearchQuery = useDebounce(
    searchQuery,
    CONFIG.ui.debounceDelay
  );

  const isSearch = searchQuery !== '';
  const queryKeys = [
    QUERY_KEY.CLIENT_USER_IN_ROLE,
    id,
    groupId,
    queryHash,
    debouncedSearchQuery,
  ];

  const {
    data = defaultData,
    isFetched,
    isFetching,
    isLoading,
  } = useQuery(
    queryKeys,
    () =>
      fetchClientUserInRole({
        clientId,
        roleName,
        params: {
          ...params,
          // Required when resource group ID (project selector) changes since the
          // initial values of `params` state are not updated.
          groupId,
          search: searchQuery,
        },
      }),
    {
      enabled: !!roleName,
      cacheTime: 0,
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message ||
          'Cannot get a list of members in role';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
      onSuccess: () => {
        if (isSearch && isSearching) {
          setIsSearching(false);
        }

        if (wasSearch && !isSearch) {
          setWasSearch(false);
        }

        if (!isSearch) {
          setPreviousOffset(params.first);
        }
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const isProcessing = checkProcessing({ isFetched, isFetching, isLoading });

  const handleRefetch = () => {
    setQueryHash(generateHash());
  };

  const handleRefresh = () => {
    const offset = 0;

    setCurrentOffset(offset);
    setParams((previous) => ({
      ...previous,
      first: offset,
    }));
    handleRefetch();
  };

  const handleSearch = ({ event, isEnter = false }: SearchboxOptions) => {
    const { value } = event;
    const keyword = value.trim();
    const hasKeyword = keyword !== '';
    const hasChanged = keyword !== '' && keyword !== searchQuery;
    const offset = hasKeyword ? 0 : previousOffset;

    setCurrentOffset(offset);
    setIsSearching(hasChanged);
    setParams((previous) => ({
      ...previous,
      first: offset,
      search: value,
    }));

    if (isEnter && hasKeyword) {
      handleRefetch();
    } else {
      setSearchQuery((previous) => {
        setWasSearch(previous !== '' && !hasKeyword);

        return value;
      });
    }
  };

  const handleSearchChange = (event: SearchboxEmit) => {
    handleSearch({ event, isEnter: false });
  };

  const handleSearchEnter = (event: SearchboxEmit) => {
    handleSearch({ event, isEnter: true });
  };

  const handleSearchReset = () => {
    const value = '';
    const offset = previousOffset;

    setCurrentOffset(offset);
    setWasSearch(true);
    setParams((previous) => ({
      ...previous,
      first: offset,
      isAll: false,
      search: value,
    }));
    setSearchQuery(value);
  };

  const handleSearchClear = () => {
    const value = '';
    const hasKeyword = isSearch;

    if (hasKeyword) {
      handleSearchReset();
    } else {
      // NOTE: Clear whitespaces without making an API request
      setSearchQuery(value);
    }
  };

  const handlePageChange = (offset: number) => {
    setParams((previous) => ({
      ...previous,
      first: offset,
    }));

    setCurrentOffset(offset);
    setQueryHash(generateHash());
  };

  const handleStepChange = (event: SelectChangeEvent<unknown>) => {
    const step = event.target.value as number;
    const offset = 0;

    setCurrentOffset(offset);
    setPageStep(step);

    setParams((previous) => ({
      ...previous,
      first: offset,
      max: step,
    }));

    setQueryHash(generateHash());
  };

  return (
    <Fragment>
      <Toolbar>
        <Toolbar.Control>
          <Toolbar.Search>
            <Searchbox
              autoFocus
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              onEnter={handleSearchEnter}
              placeholder="Search by username, email or name"
              value={searchQuery}
            />
            <ResetButton disabled={!isSearch} onClick={handleSearchReset}>
              Reset Search
            </ResetButton>
          </Toolbar.Search>
          <Toolbar.Action>
            <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>
          </Toolbar.Action>
        </Toolbar.Control>
      </Toolbar>
      <UserTable2
        canEdit={canEdit}
        data={data.items}
        isFetched={isFetched}
        isFetching={isFetching}
        isLoading={isLoading}
        isProcessing={isProcessing}
        isSearch={isSearch}
        isSearching={isSearching}
        paginationOffset={currentOffset}
        paginationStep={pageStep}
        onPageChange={handlePageChange}
        onStepChange={handleStepChange}
        totalCount={data.totalCount}
        totalRecord={data.totalRecord}
        wasSearch={wasSearch}
      />
    </Fragment>
  );
};

const WrappedUserInRolePage2: FC = () => {
  const { canViewUserTab } = useSelector(selectPermissionRoleDetail);

  return (
    <ProtectedRouter
      canView={canViewUserTab}
      feature={FeaturePage.ROLE}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <UserInRole2 />
    </ProtectedRouter>
  );
};

export { WrappedUserInRolePage2 };
