import HelpIcon from '@mui/icons-material/Help';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuList from '@mui/material/MenuList';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { FC } from 'react';
import { version } from '../../../../../../../../version';
import { Placeholder } from '../index';
import { Wrapper } from './SupplementaryMenu.styled';

type SupplementaryMenuProps = {
  loading: boolean;
  onReleaseNotesOpen: () => void;
  onSupportOpen: () => void;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    menuItem: {
      '&.Mui-selected': {
        color: theme.palette.primary.dark,
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.dark,
        },
      },
      '& .MuiListItemText-primary': {
        fontSize: '0.8125rem',
      },
      '& .MuiListItemText-inset': {
        paddingLeft: '38px',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '38px',

        '& svg': {
          fontSize: '13px',
        },
      },
    },
  });
});

const StyledMenuItem = (props: MenuItemProps) => {
  const classes = useStyles();

  return <MenuItem {...props} className={classes.menuItem} />;
};

const SupplementaryMenu: FC<SupplementaryMenuProps> = ({
  loading,
  onReleaseNotesOpen,
  onSupportOpen,
}) => {
  const menuList = (
    <MenuList dense>
      <StyledMenuItem onClick={onSupportOpen}>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText>Support</ListItemText>
      </StyledMenuItem>
      <StyledMenuItem onClick={onReleaseNotesOpen}>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText>Build version {version}</ListItemText>
      </StyledMenuItem>
    </MenuList>
  );

  const menuPlaceholder = (
    <List dense>
      <ListItem>
        <Placeholder amount={2} />
      </ListItem>
    </List>
  );

  return (
    <Wrapper>
      <Divider />
      {loading ? menuPlaceholder : menuList}
    </Wrapper>
  );
};

export { SupplementaryMenu };
