import RefreshIcon from '@mui/icons-material/Refresh';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC } from 'react';

const RefreshButton: FC<ButtonProps> = ({ children = 'Refresh', ...rest }) => {
  return (
    <Button
      color="primary"
      startIcon={<RefreshIcon />}
      variant="outlined"
      {...rest}
    >
      {children}
    </Button>
  );
};

export { RefreshButton };
