import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from '@bom-nextgen-keycloak/web/core';
import {
  Avatar,
  Content,
  Control,
  Email,
  Info,
  Name,
  Profile,
  Wrapper,
} from './AccountMenu.styled';

type AccountMenuProps = {
  onLogout: () => void;
};

const AccountMenu: FC<AccountMenuProps> = ({ onLogout }) => {
  const { profile } = useSelector(selectAccount);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const disableMenu = profile === null;
  const info = {
    email: profile?.email ?? 'undefined',
    name: profile?.name ?? 'undefined',
    settings: profile?.accountURL ?? '',
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout();
  };

  const renderAvatar = () => {
    let alias = undefined;

    if (profile !== null && profile.name) {
      alias = `${profile?.name.split(' ')[0][0]}${
        profile?.name.split(' ')[1][0]
      }`;
    }

    return <Avatar>{alias}</Avatar>;
  };

  return (
    <Fragment>
      <Tooltip title={disableMenu ? '' : 'Account settings'}>
        <span>
          <IconButton
            aria-controls={open ? 'account-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            disabled={disableMenu}
            edge="end"
            onClick={handleClick}
            size="small"
          >
            {renderAvatar()}
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id="account-menu"
        onClose={handleClose}
        onClick={handleClose}
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            overflow: 'visible',
            '& .MuiAvatar-root': {
              height: 32,
              ml: -0.5,
              mr: 1,
              width: 32,
            },
            '&:before': {
              bgcolor: 'background.paper',
              content: '""',
              display: 'block',
              height: 10,
              position: 'absolute',
              right: 14,
              top: 0,
              transform: 'translateY(-50%) rotate(45deg)',
              width: 10,
              zIndex: 0,
            },
          },
        }}
        MenuListProps={{
          sx: {
            padding: 0,
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Wrapper>
          <Content>
            <Profile>
              <Name>{info.name}</Name>
              <Email>{info.email}</Email>
            </Profile>
            <Info>
              This account is managed by <b>True Analytics &amp; AI.</b>
            </Info>
          </Content>
          <Divider />
          <Control>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Button
                disabled={!info.settings}
                href={info.settings}
                size="small"
                sx={{ textTransform: 'none' }}
                target="_blank"
                variant="outlined"
              >
                Account settings
              </Button>
              <Button
                onClick={handleLogout}
                size="small"
                sx={{ textTransform: 'none' }}
                variant="outlined"
              >
                Sign out
              </Button>
            </Stack>
          </Control>
        </Wrapper>
      </Menu>
    </Fragment>
  );
};

export { AccountMenu };
