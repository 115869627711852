import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { FC, Fragment, useState } from 'react';
import { IUserSessions } from '@bom-nextgen-keycloak/models';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  NotFound2,
  PaginationPro,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';

type SessionTableSortKey = Pick<
  IUserSessions,
  'clients' | 'ipAddress' | 'lastAccess' | 'start'
>;

type SessionTableProps = {
  data: IUserSessions[];
  isFetched?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  isProcessing?: boolean;
  totalCount?: number | undefined;
  totalRecord: number;
  wasSearch?: boolean;
};

const headCells: HeadCell<keyof SessionTableSortKey>[] = [
  {
    disablePadding: false,
    id: 'ipAddress',
    label: 'IP address',
    numeric: false,
    sortKey: 'ipAddress',
  },
  {
    disablePadding: false,
    id: 'start',
    label: 'Started on',
    numeric: false,
    sortKey: 'start',
  },
  {
    disablePadding: false,
    id: 'lastAccess',
    label: 'Last access on',
    numeric: false,
    sortKey: 'lastAccess',
  },
  {
    disablePadding: false,
    id: 'clients',
    label: 'App (Client ID)',
    numeric: false,
    sortKey: 'clients',
  },
];
const Session2Table: FC<SessionTableProps> = ({
  data,
  isFetched = false,
  isFetching = true,
  isLoading = true,
  isProcessing = true,
  totalCount = undefined,
  totalRecord = 0,
  wasSearch = false,
}) => {
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof SessionTableSortKey>('lastAccess');

  const isEmpty = data.length === 0;
  const columnLength = headCells.length;
  const showSkeleton = isProcessing || wasSearch;
  const showHeader =
    isProcessing || wasSearch || (!isProcessing && totalRecord > 0);

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SessionTableSortKey
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      <Fragment>
        <TableContainer>
          <Table
            aria-label="user session table"
            aria-labelledby="tableTitle"
            stickyHeader
          >
            {showHeader && (
              <EnhancedTableHead
                headCells={headCells}
                onRequestSort={handleSort}
                order={order}
                orderBy={orderBy}
              />
            )}
            <TableBody>
              <TableRowSkeleton
                cellLength={columnLength}
                loading={showSkeleton}
              >
                {!isEmpty ? (
                  stableSort<IUserSessions>(
                    data,
                    getComparator(order, orderBy)
                  ).map((row) => {
                    const { clients, id, ipAddress, lastAccess, start } = row;

                    return (
                      <TableRow key={id}>
                        <TableCell>{ipAddress}</TableCell>
                        <TableCell>
                          {format(new Date(start), 'MM/dd/yyyy HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {format(new Date(lastAccess), 'MM/dd/yyyy HH:mm:ss')}
                        </TableCell>
                        <TableCell>{Object.values(clients)}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columnLength}>
                      <NotFound2 />
                    </TableCell>
                  </TableRow>
                )}
              </TableRowSkeleton>
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationPro
          isFetched={isFetched}
          isFetching={isFetching}
          isLoading={isLoading}
          isProcessing={isProcessing}
          totalCount={totalCount}
          totalRecord={totalRecord}
        />
      </Fragment>
    </Paper>
  );
};

export { Session2Table };
