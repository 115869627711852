import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { ScopeUpdateDto } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchScopeById,
  QUERY_KEY,
  updateScopeById,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { PARENT_PATH } from '../shared';

const useScopeDetail2 = (scopeId: string) => {
  const defaultData = null;
  const { clientId } = useResource();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();

  const queryKeys = [QUERY_KEY.SCOPE_DETAIL, scopeId];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    () => fetchScopeById(clientId, scopeId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get scope detail';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
        navigate(PARENT_PATH);
      },
      staleTime: 5 * 1000,
    }
  );

  const updateMutation = useMutation(updateScopeById, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot update socpe';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: (data) => {
      setNotification({
        message: 'Scope updated',
        type: 'success',
      });
      handleReload();
    },
  });

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const handleUpdate = (payload: ScopeUpdateDto) => {
    updateMutation.mutate({
      clientId,
      payload,
    });
  };

  return {
    data,
    isLoading,
    isUpdating: updateMutation.isLoading,
    handleUpdate,
  };
};

export { useScopeDetail2 };
