import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { UserGroupMemeberDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  listGroupMembers,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  FormSpinner,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { PARENT_PATH } from '../../shared';
import { GroupCompany } from './GroupCompany';
import { GroupOption } from './GroupOption';
import { Instruction, Step } from './Group2.styled';

const Group2: FC = () => {
  const { id = '' } = useParams();
  const { groupId } = useResource();
  const queryClient = useQueryClient();
  const { setAlertMessage } = useAlertMessage();

  const defaultData: UserGroupMemeberDto[] = [];
  const queryKeys = [QUERY_KEY.USER_GROUP_MEMBERS, groupId, id];

  // Load effective groups
  const {
    data = defaultData,
    isFetching,
    isLoading,
  } = useQuery(queryKeys, () => listGroupMembers(id, { groupId }), {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot get effective groups';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
  });

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const isInitializing = isLoading;
  const company = data.find((group) => group?.level === 2);
  const companyId = company?.id ?? '';
  const hasCompany = Boolean(company);

  const renderInstruction = () => {
    const order = hasCompany ? '2/2' : '1/2';
    const text = hasCompany ? `group and subscription` : 'a company';

    return (
      <Instruction>
        <Typography>
          <Step>
            <Chip component="span" label={`Step ${order}`} />
          </Step>
          {`Select ${text}`}
        </Typography>
      </Instruction>
    );
  };

  const renderContent = () => {
    return hasCompany ? (
      <GroupOption
        companyId={companyId}
        effectiveGroups={data}
        loading={isFetching || isLoading}
        onReload={handleReload}
        resourceGroupId={groupId}
        userId={id}
      />
    ) : (
      <GroupCompany
        loading={isFetching || isLoading}
        onReload={handleReload}
        resourceGroupId={groupId}
        userId={id}
      />
    );
  };

  return (
    <div>
      {isInitializing ? (
        <FormSpinner />
      ) : (
        <div>
          {renderInstruction()}
          {renderContent()}
        </div>
      )}
    </div>
  );
};

const WrappedUserGroupPage: FC = () => {
  const { canViewGroupTab } = useSelector(selectPermissionUserDetail);

  return (
    <ProtectedRouter
      canView={canViewGroupTab}
      feature={FeaturePage.USER}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <Group2 />
    </ProtectedRouter>
  );
};

export { WrappedUserGroupPage };
