import * as yup from 'yup';
import { NO_SPACE_REGEX } from '@bom-nextgen-keycloak/web/shared/util';
import { CONFIG } from '../../shared/config';

export const validationSchema = yup.object({
  newPassword: yup
    .string()
    .matches(NO_SPACE_REGEX, 'Password cannot contain space')
    .min(6, 'Password should be of minimum 6 characters length')
    .required(CONFIG.form.fields.newPassword.message.validation),
  confirmPassword: yup
    .string()
    .matches(NO_SPACE_REGEX, 'Password cannot contain space')
    .required(CONFIG.form.fields.confirmPassword.message.validation)
    .oneOf(
      [yup.ref(CONFIG.form.fields.newPassword.name), null],
      'Passwords must match'
    ),
});

export type PasswordForm = {
  confirmPassword: string;
  newPassword: string;
  temporary: boolean;
};
