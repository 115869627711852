import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import {
  IPermissionRepresentation,
  PermissionQuery,
} from '@bom-nextgen-keycloak/models';
import {
  CONFIG,
  selectPermissionDetail,
  selectPermissionPage,
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  deletePermission,
  ErrorMessage,
  fetchPermissionList,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  SearchboxEmit,
  SearchboxOptions,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  checkProcessing,
  generateHash,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';
import { AdvancedSearchCriteria } from '../components/AdvancedSearch';

type Arguments = {
  callback?: () => void;
  disableQuery?: boolean;
};

type PermissionType = string;

const usePermission2 = (options?: Arguments) => {
  const callback = options?.callback;
  const disableQuery = options?.disableQuery;
  const enableQuery = !disableQuery;
  const resource = useResource();
  const { clientId } = resource;
  const defaultParams = {
    first: CONFIG.pagination.offset,
    max: CONFIG.pagination.step,
    name: '',
    type: 'all',
    resource: '',
    scope: '',
  };
  const defaultData = {
    items: [],
    totalCount: undefined,
    totalRecord: 0,
  };
  const defaultSelection = {
    item: null,
    type: '',
  };
  const queryClient = useQueryClient();
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const { canViewPage: canEdit } = useSelector(selectPermissionDetail);
  const { canViewAdd: canAdd, canViewDelete: canDelete } =
    useSelector(selectPermissionPage);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [params, setParams] = useState<PermissionQuery>({ ...defaultParams });
  const [selectedType, setSelectedType] = useState<PermissionType>(
    defaultSelection.type
  );
  const [selectedItem, setSelectedItem] =
    useState<IPermissionRepresentation | null>(defaultSelection.item);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentOffset, setCurrentOffset] = useState(CONFIG.pagination.offset);
  const [previousOffset, setPreviousOffset] = useState(
    CONFIG.pagination.offset
  );
  const [pageStep, setPageStep] = useState(CONFIG.pagination.step);
  const [queryHash, setQueryHash] = useState(generateHash());
  const [isSearching, setIsSearching] = useState(false);
  const [wasSearch, setWasSearch] = useState(false);
  const debouncedSearchQuery = useDebounce(
    searchQuery,
    CONFIG.ui.debounceDelay
  );

  const isSearch = searchQuery !== '';
  const isFilter =
    params.resource !== '' || params.scope !== '' || params.type !== 'all';
  const queryKeys = [
    QUERY_KEY.PERMISSION_LIST,
    clientId,
    queryHash,
    debouncedSearchQuery,
  ];

  const {
    data = defaultData,
    isFetched,
    isFetching,
    isLoading,
  } = useQuery(
    queryKeys,
    () =>
      fetchPermissionList(clientId, {
        ...params,
        name: searchQuery,
        type: params.type === 'all' ? '' : params.type,
      }),
    {
      enabled: enableQuery,
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get permissions';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
      onSuccess: () => {
        if ((isSearch && isSearching) || (isFilter && isSearching)) {
          setIsSearching(false);
        }

        if (wasSearch && !isSearch && !isFilter) {
          setWasSearch(false);
        }

        if (!isSearch && !isFilter) {
          setPreviousOffset(params.first);
        }
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const isProcessing = checkProcessing({ isFetched, isFetching, isLoading });

  const deleteMutation = useMutation(deletePermission, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot delete permission';

      setAlertMessage({
        message,
        statusCode: error.response?.status,
        typeStatusMessage: 'error',
      });
    },
    onSuccess: () => {
      setIsDeleteDialogOpen(false);
      setNotification({
        message: 'Permission deleted',
        type: 'success',
      });

      if (enableQuery) {
        handleSelectionReset();
        handleReload();
      }

      callback && callback();
    },
  });

  const handleSelectionReset = () => {
    setSelectedItem(defaultSelection.item);
    setSelectedType(defaultSelection.type);
  };

  const handleRefetch = () => {
    setQueryHash(generateHash());
  };

  const handleReload = () => {
    queryClient.invalidateQueries(queryKeys);
  };

  const handleRefresh = () => {
    const offset = 0;

    setCurrentOffset(offset);
    setParams((previous) => ({
      ...previous,
      first: offset,
    }));
    handleRefetch();
  };

  const handleCreateRequest = (type: PermissionType) => {
    setSelectedType(type);
    setIsCreateDialogOpen(true);
  };

  const handleDeleteRequest = (data: IPermissionRepresentation) => {
    if (data) {
      setSelectedItem(data);
      setIsDeleteDialogOpen(true);
    } else {
      // TODO: Implement a handler
    }
  };

  const handleCreateCancel = () => {
    setIsCreateDialogOpen(false);
    handleSelectionReset();
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
    handleSelectionReset();
  };

  const handleCreateAction = () => {
    setIsCreateDialogOpen(false);
    handleSelectionReset();
    handleReload();
  };

  const handleDeleteAction = () => {
    if (selectedItem) {
      deleteMutation.mutate({
        clientId,
        permissionId: selectedItem.id,
        type: selectedItem.type,
      });
    } else {
      // TODO: Implement a handler
    }
  };

  const handleSearch = ({ event, isEnter = false }: SearchboxOptions) => {
    const { value } = event;
    const keyword = value.trim();
    const hasKeyword = keyword !== '';
    const hasChanged = keyword !== '' && keyword !== searchQuery;
    const offset = hasKeyword ? 0 : previousOffset;

    setCurrentOffset(offset);
    setIsSearching(hasChanged || isFilter);
    setParams((previous) => ({
      ...previous,
      first: offset,
      name: value,
    }));

    if (isEnter && hasKeyword) {
      handleRefetch();
    } else {
      setSearchQuery((previous) => {
        setWasSearch(previous !== '' && !hasKeyword && !isFilter);

        return value;
      });
    }
  };

  const handleSearchChange = (event: SearchboxEmit) => {
    handleSearch({ event, isEnter: false });
  };

  const handleSearchEnter = (event: SearchboxEmit) => {
    handleSearch({ event, isEnter: true });
  };

  const handleSearchReset = () => {
    const value = '';
    const hasKeyword = isSearch;
    const offset = previousOffset;

    setCurrentOffset(offset);
    setParams((previous) => ({
      ...defaultParams,
      first: offset,
      max: previous.max,
    }));

    if (hasKeyword) {
      setWasSearch(true);
      setSearchQuery(value);
    } else {
      handleRefetch();
    }
  };

  const handleSearchClear = () => {
    const value = '';
    const hasKeyword = isSearch;

    if (hasKeyword) {
      if (isFilter) {
        setIsSearching(true);
        setWasSearch(false);
        setSearchQuery(value);
      } else {
        handleSearchReset();
      }
    } else {
      // NOTE: Clear whitespaces without making an API request
      setSearchQuery(value);
    }
  };

  const handleAdvancedSearchChange = (criteria: AdvancedSearchCriteria) => {
    const { resource, scope, type } = criteria;
    const hasCriteria = resource !== '' || scope !== '' || type !== 'all';
    const offset = hasCriteria ? 0 : previousOffset;

    setIsSearching(hasCriteria);
    setCurrentOffset(offset);
    setParams((previous) => ({
      ...previous,
      ...criteria,
      first: offset,
    }));
    handleRefetch();
  };

  const handlePageChange = (offset: number) => {
    if (wasSearch) {
      setWasSearch(false);
    }

    setCurrentOffset(offset);
    setParams((previous) => ({
      ...previous,
      first: offset,
    }));
    handleRefetch();
  };

  const handleStepChange = (event: SelectChangeEvent<unknown>) => {
    const step = event.target.value as number;
    const offset = 0;

    setCurrentOffset(offset);
    setPreviousOffset(offset);
    setPageStep(step);
    setParams((previous) => ({
      ...previous,
      first: offset,
      max: step,
    }));
    handleRefetch();
  };

  return {
    canAdd,
    canDelete,
    canEdit,
    data,
    handleAdvancedSearchChange,
    handleCreateAction,
    handleCreateCancel,
    handleCreateRequest,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    handlePageChange,
    handleRefresh,
    handleSearchChange,
    handleSearchClear,
    handleSearchEnter,
    handleSearchReset,
    handleStepChange,
    isCreateDialogOpen,
    isDeleteDialogOpen,
    isDeleting: deleteMutation.isLoading,
    isFetched,
    isFetching,
    isFilter,
    isLoading,
    isProcessing,
    isSearch,
    isSearching,
    paginationOffset: currentOffset,
    paginationStep: pageStep,
    params,
    searchQuery,
    selectedItem,
    selectedType,
    wasSearch,
  };
};

export { usePermission2 };
