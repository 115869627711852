import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import {
  AutoCompleteOption,
  StyledTableCellHead,
  TableFormFooter,
} from '../../index';
import { TableContainer } from './TableForm.styled';

type TableFormProps = {
  columnName: string;
  data: AutoCompleteOption[];
  disabled?: boolean;
  onRemove: (data: AutoCompleteOption) => void;
  tableName: string;
  tooltipTitle?: string;
  unit?: string;
};

const TableForm: FC<TableFormProps> = ({
  columnName = 'Name',
  data,
  disabled = false,
  onRemove,
  tableName,
  tooltipTitle = 'Remove',
  unit = 'record(s)',
}) => {
  const totalRecord = data.length;

  return (
    <Paper>
      <TableContainer>
        <Table aria-label={`${tableName} table`} stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCellHead>{columnName}</StyledTableCellHead>
              <StyledTableCellHead align="center" sx={{ width: '85px' }}>
                Actions
              </StyledTableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              const { label = '', value } = item;
              const { id } = value;

              return (
                <TableRow key={id}>
                  <TableCell>{label}</TableCell>
                  <TableCell align="center">
                    <Tooltip title={tooltipTitle}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => onRemove(item)}
                        size="small"
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFormFooter>
        <b>{`${totalRecord} ${unit}`}</b> selected
      </TableFormFooter>
    </Paper>
  );
};

export { TableForm };
