import { FieldMetaProps } from 'formik';

type ButtonStatusArgument = {
  canEdit: boolean;
  isDirty: boolean;
  isLoading: boolean;
  isValid: boolean;
};

export const getButtonStatus = ({
  canEdit,
  isDirty,
  isLoading,
  isValid,
}: ButtonStatusArgument) => {
  const cancelButton = isLoading;
  const resetButton = !isDirty || isLoading || !canEdit;
  const saveButton = !(isDirty && isValid) || isLoading || !canEdit;

  return {
    disabled: {
      cancel: cancelButton,
      reset: resetButton,
      save: saveButton,
    },
  };
};

export const getFieldError = (
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>,
  name: string
) => {
  const fieldMeta = getFieldMeta(name);
  const { error, touched } = fieldMeta;
  const isError = touched && !!error;
  const helperText = touched && error;

  return { isError, helperText };
};
