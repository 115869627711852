import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, ReactNode, useEffect, useState } from 'react';
import { DialogPro } from '@bom-nextgen-keycloak/web/shared/ui';

export type AlertDialogProps = {
  message: string | ReactNode;
  onClose: () => void;
  type: string;
};

const AlertDialog: FC<AlertDialogProps> = ({ message, onClose, type }) => {
  const [open, setOpen] = useState(false);

  const isError = type === 'error';

  useEffect(() => {
    if (message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <DialogPro
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={open}
      width={480}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          color: (theme) => {
            return isError
              ? theme.palette.error.main
              : theme.palette.success.main;
          },
        }}
      >
        {isError ? 'Oops...' : 'Success'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="secondary"
          data-test-id="btn-ok"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </DialogPro>
  );
};

export { AlertDialog };
