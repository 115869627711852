import { FC } from 'react';
import { DeleteDialog } from '@bom-nextgen-keycloak/web/shared/ui';

type UserRemoveDialogProps = {
  loading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  open?: boolean;
  resourceName?: string | null;
  username?: string | null;
};

const UserRemoveDialog: FC<UserRemoveDialogProps> = ({
  loading = false,
  onClose,
  onSubmit,
  open = false,
  resourceName = 'unknown',
  username = 'unknown',
}) => {
  return (
    <DeleteDialog
      description={`
        You are about to remove <b>${username}</b> from <b>${resourceName}</b> (SSO user account will not get permanently deleted).`}
      loading={loading}
      name="remove-user"
      onClose={onClose}
      onDelete={onSubmit}
      open={open}
      title="Remove user?"
      titleBtn="Remove"
    />
  );
};

export { UserRemoveDialog };
