import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { IRoleRepresentation } from '@bom-nextgen-keycloak/models';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  Loading,
  NotFound2,
  PaginationPro,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';

type RoleTableSortKey = Pick<
  IRoleRepresentation,
  'description' | 'id' | 'name'
>;

type RoleTableProps = {
  canDelete?: boolean;
  canEdit?: boolean;
  data: IRoleRepresentation[];
  enabledDelete?: boolean;
  enabledEdit?: boolean;
  isFetched?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  isProcessing?: boolean;
  isSearch?: boolean;
  isSearching?: boolean;
  onDelete: (roleName: string) => void;
  onPageChange?: (offset: number) => void;
  onStepChange?: (event: SelectChangeEvent<unknown>) => void;
  paginationOffset?: number;
  paginationStep?: number;
  totalCount?: number;
  totalRecord: number;
  wasSearch?: boolean;
};

const headCells: HeadCell<keyof RoleTableSortKey>[] = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Name',
    numeric: false,
    sortKey: 'name',
  },
  {
    disablePadding: false,
    id: 'description',
    label: 'Description',
    numeric: false,
    sortKey: 'description',
  },
  {
    disablePadding: false,
    id: 'actions',
    label: 'Actions',
    numeric: false,
    sortKey: 'id',
  },
];

const RoleTable2: FC<RoleTableProps> = ({
  canDelete = false,
  canEdit = false,
  data,
  enabledDelete = true,
  enabledEdit = true,
  isFetched = false,
  isFetching = true,
  isLoading = true,
  isProcessing = true,
  isSearch = false,
  isSearching = false,
  onDelete,
  onPageChange,
  onStepChange,
  paginationOffset = 0,
  paginationStep = 10,
  totalCount = undefined,
  totalRecord = 0,
  wasSearch = false,
}) => {
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] = useState<keyof RoleTableSortKey>('name');

  const isEmpty = data.length === 0;
  const columnLength = headCells.length;
  const showSkeleton = isProcessing || wasSearch;
  const showHeader =
    isProcessing || wasSearch || (!isProcessing && totalRecord > 0);
  const showDeleteButton = canDelete && enabledDelete;
  const showEditButton = canEdit && enabledEdit;

  const handleSort = (
    event: MouseEvent<unknown>,
    property: keyof RoleTableSortKey
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      {isSearching ? (
        <Loading title="Searching..." />
      ) : (
        <Fragment>
          <TableContainer>
            <Table
              aria-label="role table"
              aria-labelledby="tableTitle"
              stickyHeader
            >
              {showHeader && (
                <EnhancedTableHead
                  headCells={headCells}
                  onRequestSort={handleSort}
                  order={order}
                  orderBy={orderBy}
                />
              )}
              <TableBody>
                <TableRowSkeleton
                  cellLength={columnLength}
                  loading={showSkeleton}
                >
                  {!isEmpty ? (
                    stableSort<IRoleRepresentation>(
                      data,
                      getComparator(order, orderBy)
                    ).map((row) => {
                      const { description, id, name } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell>{name}</TableCell>
                          <TableCell title={description}>
                            <StyledTextEllipsis>
                              {description}
                            </StyledTextEllipsis>
                          </TableCell>
                          <TableCell>
                            {showEditButton && (
                              <Link to={`/roles/${id}`}>
                                <Tooltip title="Edit role">
                                  <IconButton aria-label="edit" size="small">
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            )}
                            {showDeleteButton && (
                              <Tooltip title="Delete role">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => onDelete(name)}
                                  size="small"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columnLength}>
                        <NotFound2 />
                      </TableCell>
                    </TableRow>
                  )}
                </TableRowSkeleton>
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationPro
            isFetched={isFetched}
            isFetching={isFetching}
            isLoading={isLoading}
            isProcessing={isProcessing}
            isSearch={isSearch}
            isSearching={isSearching}
            onPageChange={onPageChange}
            onStepChange={onStepChange}
            paginationOffset={paginationOffset}
            paginationStep={paginationStep}
            totalCount={totalCount}
            totalRecord={totalRecord}
            wasSearch={wasSearch}
          />
        </Fragment>
      )}
    </Paper>
  );
};

export { RoleTable2 };
