import * as yup from 'yup';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';
import { CONFIG } from '../../shared/config';

export const validationSchema = yup.object({
  email: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required(CONFIG.form.fields.email.message.validation),
  firstName: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required(CONFIG.form.fields.firstName.message.validation),
  lastName: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required(CONFIG.form.fields.lastName.message.validation),
  username: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required(CONFIG.form.fields.username.message.validation),
});

export type UserForm = {
  createdTimestamp: string;
  email: string;
  enabled: boolean;
  enabledSSO: boolean;
  firstName: string;
  groupId: string;
  lastName: string;
  username: string;
};
