import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
  selectPermissionUser,
  selectPermissionUserDetail,
} from '@bom-nextgen-keycloak/web/core';
import { ExportFormDialog as ExportDialog } from '@bom-nextgen-keycloak/web/pages/feature-group';
import {
  AddButton,
  ExportButton,
  RefreshButton,
  Render,
  ResetButton,
  Searchbox,
  Toolbar,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  UserAddDialog as AddDialog,
  UserValidateDialog as ValidateDialog,
} from './components';
import {
  UserDeleteDialog as DeleteDialog,
  UserFormDialog as CreateDialog,
  UserRemoveDialog as RemoveDialog,
  UserTable2,
} from './components';
import { useUserPage2 } from './hooks';

const User2: FC = () => {
  const userpage = useUserPage2();
  const {
    canViewAdd: canAdd,
    canViewDelete: canDelete,
    canViewExport: canExport,
    canViewLeaveGroup: canRemove,
  } = useSelector(selectPermissionUser);
  const { canViewPage: canEdit } = useSelector(selectPermissionUserDetail);
  const {
    data,
    handleAddAction,
    handleAddCancel,
    handleAddRequest,
    handleCreateAction,
    handleCreateCancel,
    handleCreateRequest,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    handleExportCancel,
    handleExportRequest,
    handlePageChange,
    handleRefresh,
    handleRemoveAction,
    handleRemoveCancel,
    handleRemoveRequest,
    handleSearchChange,
    handleSearchClear,
    handleSearchEnter,
    handleSearchReset,
    handleStepChange,
    handleValidateRequest,
    isAddDialogOpen,
    isAdding,
    isCreateDialogOpen,
    isDeleteDialogOpen,
    isDeleting,
    isExportDialogOpen,
    isFetched,
    isFetching,
    isLoading,
    isProcessing,
    isRemoveDialogOpen,
    isRemoving,
    isSearching,
    isValidateDialogOpen,
    paginationOffset,
    paginationStep,
    resource,
    searchQuery,
    selectedEmail,
    selectedItem,
    wasSearch,
  } = userpage;
  const showAddButton = canAdd;
  const showExportButton = canExport;
  const isSearch = searchQuery !== '';
  const hasData = Boolean(data.totalCount);

  return (
    <Fragment>
      <Toolbar>
        <Toolbar.Control>
          <Toolbar.Search>
            <Searchbox
              autoFocus
              disabled={isFetched && !hasData && !isSearch}
              name="user"
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              onEnter={handleSearchEnter}
              placeholder="Search by username, email or name"
              value={searchQuery}
            />
            <ResetButton disabled={!isSearch} onClick={handleSearchReset}>
              Reset Search
            </ResetButton>
          </Toolbar.Search>
          <Toolbar.Action>
            <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>

            {showExportButton && (
              <ExportButton onClick={handleExportRequest}>Export</ExportButton>
            )}

            {showAddButton && (
              <AddButton onClick={handleValidateRequest}>Add User</AddButton>
            )}
          </Toolbar.Action>
        </Toolbar.Control>
      </Toolbar>
      <UserTable2
        canDelete={canDelete}
        canEdit={canEdit}
        canRemove={canRemove}
        data={data.items}
        isFetched={isFetched}
        isFetching={isFetching}
        isLoading={isLoading}
        isProcessing={isProcessing}
        isSearch={isSearch}
        isSearching={isSearching}
        paginationOffset={paginationOffset}
        paginationStep={paginationStep}
        onDelete={handleDeleteRequest}
        onPageChange={handlePageChange}
        onRemove={handleRemoveRequest}
        onStepChange={handleStepChange}
        totalCount={data.totalCount}
        totalRecord={data.totalRecord}
        wasSearch={wasSearch}
      />
      <ValidateDialog
        onClose={handleCreateRequest}
        onValidEmailAndGroup={handleAddRequest}
        open={isValidateDialogOpen}
      />
      <CreateDialog
        email={selectedEmail}
        onClose={handleCreateCancel}
        onSaveUser={handleCreateAction}
        open={isCreateDialogOpen}
      />
      <AddDialog
        loading={isAdding}
        onClose={handleAddCancel}
        onJoin={handleAddAction}
        open={isAddDialogOpen}
      />
      <RemoveDialog
        loading={isRemoving}
        onClose={handleRemoveCancel}
        onSubmit={handleRemoveAction}
        open={isRemoveDialogOpen}
        resourceName={resource.resourceName}
        username={selectedItem?.username}
      />
      <DeleteDialog
        loading={isDeleting}
        onClose={handleDeleteCancel}
        onDelete={handleDeleteAction}
        open={isDeleteDialogOpen}
        username={selectedItem?.username}
      />
      <Render isVisible={isExportDialogOpen}>
        <ExportDialog
          groupId={resource.groupId || ''}
          onClose={handleExportCancel}
          open={isExportDialogOpen}
          titleForm={resource.resourceName || ''}
        />
      </Render>
    </Fragment>
  );
};

export { User2 };
