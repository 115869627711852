import ListItem from '@mui/material/ListItem';
import { FC, forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export interface AppMenuItemComponentProps {
  className?: string;
  link?: string | null;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const AppMenuItemComponent: FC<AppMenuItemComponentProps> = ({
  children,
  className,
  link,
  onClick,
}) => {
  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        button
        children={children}
        className={className}
        onClick={onClick}
      />
    );
  }

  return (
    <ListItem
      button
      children={children}
      className={className}
      component={forwardRef((propsNavLink: NavLinkProps, ref: any) => (
        <NavLink {...propsNavLink} />
      ))}
      to={link}
    />
  );
};

export default AppMenuItemComponent;
