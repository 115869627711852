import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type ErrorResponse = {
  message: string | null;
  statusCode: number | null;
};

type ErrorState = {
  authorization: ErrorResponse;
};

const initialState: ErrorState = {
  authorization: {
    message: null,
    statusCode: null,
  },
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    resetAuthzError: (state) => {
      state.authorization = initialState.authorization;
    },
    setAuthzError: (state, action: PayloadAction<ErrorResponse>) => {
      state.authorization = action.payload;
    },
    setGenericAuthzError: (state) => {
      state.authorization = {
        message: 'Something went wrong',
        statusCode: 500,
      };
    },
  },
});

export const { resetAuthzError, setAuthzError, setGenericAuthzError } =
  errorSlice.actions;

export const selectError = (state: RootState) => state.error;

export default errorSlice.reducer;
