import { FC } from 'react';
import { DeleteDialog } from '@bom-nextgen-keycloak/web/shared/ui';

type GroupDeleteDialogProps = {
  loading?: boolean;
  name?: string | null;
  onClose: () => void;
  onSubmit: () => void;
  open?: boolean;
};

const GroupDeleteDialog: FC<GroupDeleteDialogProps> = ({
  loading = false,
  name = 'unknown',
  onClose,
  onSubmit,
  open = false,
}) => {
  return (
    <DeleteDialog
      description={`
      You are about to permanently delete <b>${name}</b> and all of its sub-groups. <b>This operation cannot be undone.</b>
      `}
      loading={loading}
      onClose={onClose}
      onDelete={onSubmit}
      open={open}
      title="Delete group?"
    />
  );
};

export { GroupDeleteDialog };
