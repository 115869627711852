import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import MuiTextField from '@mui/material/TextField';
import { AxiosError } from 'axios';
import { FieldMetaProps } from 'formik';
import React, { FC, Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { IScope } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionResourceDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchScope,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  FormBox,
  FormField,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  NO_BEGINNING_SPACE_REGEX,
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';

export const validationSchema2 = yup.object({
  name: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Name is required'),
  displayName: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Display name is required'),
});

type ScopeList = {
  label: string;
  value: IScope;
}[];

type AttributeEntity = {
  label: string;
  value: {
    id: string;
    name: string;
  };
};

type AttributeList = AttributeEntity[];

type ResourceFormProps = {
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
  isEdit?: boolean;
  scopes?: AutoCompleteOption[];
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  setFieldValue: (field: string, value: AttributeList) => void;
};

const ResourceForm2: FC<ResourceFormProps> = ({
  getFieldMeta,
  isEdit = false,
  setFieldTouched,
  setFieldValue,
  scopes,
}) => {
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const [scopeName] = useState('');
  const debouncedQueryTerm = useDebounce(scopeName, 700);
  const { isEditGeneralTab: canEdit } = useSelector(
    selectPermissionResourceDetail
  );
  const disableField = isEdit && !canEdit;
  const defaultData: ScopeList = [];
  const queryKeys = [
    QUERY_KEY.SCOPE_LIST,
    resource.clientId,
    debouncedQueryTerm,
  ];

  const { data = defaultData, isLoading } = useQuery(
    queryKeys,
    async () => {
      // FIXME: Load data dynamically
      const payload = {
        first: 0,
        max: 200,
        name: scopeName,
      };
      const data = await fetchScope(resource.clientId, payload);
      const scopes = data.items;

      if (scopes.length) {
        return scopes.map((scope) => ({
          label: scope.name,
          value: scope,
        }));
      }

      return [];
    },
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get scopes';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
    }
  );

  const handleScopeInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // TODO: Load data dynamically
  };

  const handleScopeOptionChange = (
    event: React.SyntheticEvent,
    value: AttributeList
  ) => {
    setFieldValue('scopes', value);
  };

  return (
    <FormBox>
      <FormField>
        <TextField disabled={disableField} label="Name" name="name" required />
      </FormField>
      <FormField>
        <TextField
          disabled={disableField}
          label="Display name"
          name="displayName"
          required
        />
      </FormField>
      <FormField>
        <TextField disabled={disableField} label="Type" name="type" />
      </FormField>
      <FormField>
        <Autocomplete
          ChipProps={{ size: 'small' }}
          disabled={disableField}
          filterSelectedOptions
          getOptionLabel={(option) => option.label}
          id="scopes"
          isOptionEqualToValue={(option, value) => option.label === value.label}
          limitTags={3}
          loading={isLoading}
          multiple
          onChange={handleScopeOptionChange}
          onInputChange={handleScopeInputChange}
          options={data}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              disabled={disableField}
              error={
                getFieldMeta('scopes').touched && !!getFieldMeta('scopes').error
              }
              fullWidth
              helperText={
                getFieldMeta('scopes').touched && getFieldMeta('scopes').error
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
              label="Scopes"
              name="scopes"
              onBlur={() => {
                setFieldTouched('scopes');
              }}
              placeholder="Enter scope name"
              variant="standard"
            />
          )}
          value={scopes}
        />
      </FormField>
    </FormBox>
  );
};

export { ResourceForm2 };
