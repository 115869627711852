import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { FC } from 'react';
import { Box, Indicator, Title, Wrapper } from './NotFound2.styled';

export type NotFound2Props2 = {
  title?: string;
};

const NotFound2: FC<NotFound2Props2> = ({ title = 'No results found' }) => {
  return (
    <Wrapper>
      <Box>
        <Indicator>
          <ManageSearchIcon fontSize="large" />
        </Indicator>
        <Title>{title}</Title>
      </Box>
    </Wrapper>
  );
};

export { NotFound2 };
