import MuiAvatar from '@mui/material/Avatar';
import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const Avatar = styled(MuiAvatar)`
  height: 24px;
  width: 24px;

  &.MuiAvatar-root {
    font-size: 0.75rem;
  }
`;

export const Content = styled('div')`
  margin: 1rem;
`;

export const Control = styled('div')`
  margin: 1rem;
`;

export const Email = styled(MuiTypography)`
  font-size: 0.8125rem;
`;
export const Info = styled(MuiTypography)`
  font-size: 0.75rem;
`;

export const Name = styled(MuiTypography)`
  font-size: 0.8125rem;
  font-weight: bold;
`;

export const Profile = styled('div')`
  margin-bottom: 1rem;
`;

export const Wrapper = styled('div')`
  width: 298px;
`;
