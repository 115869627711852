import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC } from 'react';

const ExportButton: FC<ButtonProps> = ({ children = 'Export', ...rest }) => {
  return (
    <Button
      color="primary"
      startIcon={<FileDownloadIcon />}
      variant="outlined"
      {...rest}
    >
      {children}
    </Button>
  );
};

export { ExportButton };
