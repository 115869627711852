import { FC } from 'react';
import { DeleteDialog } from '@bom-nextgen-keycloak/web/shared/ui';

type PermissionDeleteDialogProps = {
  loading?: boolean;
  name?: string | null;
  onClose: () => void;
  onSubmit: () => void;
  open?: boolean;
};

const PermissionDeleteDialog: FC<PermissionDeleteDialogProps> = ({
  loading = false,
  name = 'unknown',
  onClose,
  onSubmit,
  open = false,
}) => {
  return (
    <DeleteDialog
      description={`You are about to permanently delete <b>${name}</b> and its relationships. <b>This operation cannot be undone.</b>`}
      loading={loading}
      onClose={onClose}
      onDelete={onSubmit}
      open={open}
      title="Delete permission?"
    />
  );
};

export { PermissionDeleteDialog };
