import * as yup from 'yup';

export const validationSchema = yup.lazy((values) => {
  if (values.unlimited) {
    return yup.object({
      startDate: yup.date().nullable().required('Start date is required'),
      endDate: yup.date().nullable(),
    });
  } else {
    return yup.object({
      startDate: yup.date().nullable().required('Start date is required'),
      endDate: yup.date().nullable().required('End date is required'),
    });
  }
});
