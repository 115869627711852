import { CONFIG as DEFAULT_CONFIG } from '@bom-nextgen-keycloak/web/core';

export const CONFIG = {
  ...DEFAULT_CONFIG,
  form: {
    fields: {
      confirmPassword: {
        label: 'Confirm password',
        message: {
          validation: 'Password is required',
        },
        name: 'confirmPassword',
        value: '',
      },
      createdTimestamp: {
        label: 'Created date',
        name: 'createdTimestamp',
      },
      email: {
        label: 'Email',
        message: {
          validation: 'Email is required',
        },
        name: 'email',
        placeholder: 'Enter email',
        value: '',
      },
      enabled: {
        label: 'Status',
        name: 'enabled',
      },
      enabledSSO: {
        label: 'User enabled (SSO)',
        name: 'enabledSSO',
      },
      firstName: {
        label: 'First name',
        message: {
          validation: 'First name is required',
        },
        name: 'firstName',
        placeholder: 'Enter first name',
        value: '',
      },
      id: {
        label: 'ID',
        name: 'id',
        value: '',
      },
      lastName: {
        label: 'Last name',
        message: {
          validation: 'Last name is required',
        },
        name: 'lastName',
        placeholder: 'Enter last name',
        value: '',
      },
      newPassword: {
        label: 'Password',
        message: {
          validation: 'Password is required',
        },
        name: 'newPassword',
        value: '',
      },
      username: {
        label: 'Username',
        message: {
          validation: 'Username is required',
        },
        name: 'username',
        placeholder: 'Enter username',
        value: '',
      },
    },
  },
  query: {
    ...DEFAULT_CONFIG.query,
    staleTime: 5 * 100,
  },
};

export const PARENT_PATH = '/users';
