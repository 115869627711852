import SearchIcon from '@mui/icons-material/Search';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC } from 'react';

const SearchButton: FC<ButtonProps> = ({ children = 'Search', ...rest }) => {
  return (
    <Button startIcon={<SearchIcon />} variant="outlined" {...rest}>
      {children}
    </Button>
  );
};

export { SearchButton };
