import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { FC, Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getButtonStatus } from '@bom-nextgen-keycloak/web/shared/util';
import {
  FormActionsGroup,
  FormBox,
  StyledTableCellHead as TableCellHead,
  StyledTableCellLight as TableCellLight,
} from '../../index';
import { Input, TableContainer } from './FormAttribute.styled';

enum Status {
  Add = 1,
  Delete = 2,
}

type AttributeKey = string;

type AttributeValue = string | string[];

type AttributeEntity = {
  id: string;
  key: string;
  status: Status;
  value: AttributeValue;
};

export type AttributeList = Record<AttributeKey, AttributeValue>;

type InputEvent = React.ChangeEvent<HTMLInputElement>;

type FormAttributeProps = {
  disabled?: boolean;
  loading: boolean;
  onSubmit: (attributes: AttributeList) => void;
  parentPath?: string;
  values: AttributeList;
};

const generateId = () => uuidv4();

const mapObjectToArray = (
  data: AttributeList,
  template: AttributeEntity
): AttributeEntity[] => {
  if (Object.keys(data).length) {
    const attributes = Object.entries(data).map(([key, value]) => ({
      id: generateId(),
      key,
      status: Status.Delete,
      value,
    }));

    return [...attributes, template];
  }

  return [template];
};

const mapArrayToObject = (data: AttributeEntity[]): AttributeList => {
  const output: AttributeList = {};

  data.forEach(({ key, value }) => {
    if (key) {
      output[key] = value instanceof Array ? value : [value];
    } else {
      // TODO: Implement a handler
    }
  });

  return output;
};

const FormAttribute: FC<FormAttributeProps> = ({
  disabled = false,
  loading,
  onSubmit,
  parentPath = '/',
  values,
}) => {
  const inputAttributes = ['key', 'value'];
  const template: AttributeEntity = {
    id: generateId(),
    key: '',
    status: Status.Add,
    value: '',
  };
  const initialValues = [...mapObjectToArray(values, template)];

  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);
  const [attributes, setAttributes] =
    useState<AttributeEntity[]>(initialValues);

  const { disabled: buttonDisabled } = getButtonStatus({
    canEdit: !disabled,
    isDirty,
    isLoading: loading,
    isValid: true,
  });

  const handleChange = (event: InputEvent, data: AttributeEntity) => {
    const { name, value } = event.target;
    const { id } = data;
    const payload = attributes.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );

    setAttributes(payload);
  };

  const handleAdd = (data: AttributeEntity) => {
    const payload = attributes.map((item) =>
      item.id === data.id ? { ...item, status: Status.Delete } : item
    );

    payload.push(template);
    setAttributes(payload);
    setIsDirty(true);
  };

  const handleDelete = (id: string) => {
    const payload = attributes.filter((item) => item.id !== id);

    setAttributes(payload);
    setIsDirty(true);
  };

  const handleSave = () => {
    onSubmit(mapArrayToObject(attributes));
    setIsDirty(false);
  };

  const handleReset = () => {
    setAttributes(initialValues);
    setIsDirty(false);
  };

  const handleCancel = () => {
    navigate(parentPath);
  };

  return (
    <Fragment>
      <FormBox>
        <Paper>
          <TableContainer>
            <Table
              aria-label="attribute table"
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCellHead width={350}>Key</TableCellHead>
                  <TableCellHead>Value</TableCellHead>
                  <TableCellHead width={80}>Actions</TableCellHead>
                </TableRow>
              </TableHead>
              <TableBody>
                {attributes.map((row) => {
                  const { id, key, status, value } = row;
                  const isAdd = status === Status.Add;
                  const isDelete = status === Status.Delete;
                  const disableInput = (key && value && isDelete) || disabled;
                  const disableAddButton = !key || !value || disabled;
                  const disableDeleteButton = disabled;

                  return (
                    <TableRow key={id}>
                      {inputAttributes.map((item, index) => {
                        return (
                          <TableCellLight key={`${index}=${item}`} scope="row">
                            <Input
                              disabled={disableInput}
                              id={`${item}-${id}`}
                              name={item}
                              onChange={(event: InputEvent) =>
                                handleChange(event, row)
                              }
                              value={row[item as keyof AttributeEntity]}
                            />
                          </TableCellLight>
                        );
                      })}
                      <TableCellLight align="center" scope="row">
                        {isAdd ? (
                          <Tooltip
                            title={disableAddButton ? '' : 'Add attribute'}
                          >
                            <span>
                              <IconButton
                                aria-label="add"
                                disabled={disableAddButton}
                                onClick={() => handleAdd(row)}
                              >
                                <AddCircleIcon fontSize="inherit" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              disableDeleteButton ? '' : 'Delete attribute'
                            }
                          >
                            <span>
                              <IconButton
                                aria-label="delete"
                                disabled={disableDeleteButton}
                                onClick={() => handleDelete(id)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </TableCellLight>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </FormBox>
      <FormActionsGroup
        disableCancel={buttonDisabled.cancel}
        disableReset={buttonDisabled.reset}
        disableSave={buttonDisabled.save}
        loading={loading}
        onCancel={handleCancel}
        onReset={handleReset}
        onSubmit={handleSave}
      />
    </Fragment>
  );
};

export { FormAttribute };
