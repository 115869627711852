import { green, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    // primary: {
    //   main: '#0f62fe',
    // },
    secondary: {
      dark: grey[900],
      main: grey[800],
    },
    success: {
      main: green[700],
    },
  },
  typography: {
    fontSize: 13,
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 5,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 10.5,
          marginBottom: 8,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: 13,
        },
      },
    },
  },
});

export type CustomizedTheme = typeof theme;
