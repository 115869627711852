import MuiDialog from '@mui/material/Dialog';
import MuiRadio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

export const Dialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    max-width: 600px;
    width: 600px;
  }

  & .MuiDialogContent-root {
    height: 350px;
    padding-bottom: 0;
  }
`;

export const Radio = styled(MuiRadio)`
  & .MuiSvgIcon-root {
    font-size: 0.875rem;
  }
`;
