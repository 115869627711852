import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  background-color: #fff;
  border-right: 1px solid rgb(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  margin: 0;
  min-height: 0;
  min-width: 0;
  overflow: visible;
  position: relative;
  width: 256px;
`;
