import * as yup from 'yup';
import { AutoCompleteOption } from '@bom-nextgen-keycloak/web/shared/ui';
import { CONFIG, PolicyForm, VALIDATION_SCHEMA } from '../../shared/config';

export const validationSchema = yup.object({
  ...VALIDATION_SCHEMA,
  roles: yup.array().min(1, CONFIG.form.fields.roles.message.validation),
});

export type RoleForm = PolicyForm & {
  roles: AutoCompleteOption[];
};
