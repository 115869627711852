import { ScopeCreateDto } from '@bom-nextgen-keycloak/models';
import {
  useAlertMessage,
  useNotification,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  createScope,
  ErrorMessage,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { StyledDialogTitle } from '@bom-nextgen-keycloak/web/shared/ui';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { ScopeForm, validationSchema } from '../ScopeForm';
import { StyledDialog } from './ScopeFormDialog.styled';

type ScopeFormDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

const initialValues: ScopeCreateDto = {
  name: '',
  displayName: '',
};

const ScopeFormDialog: FC<ScopeFormDialogProps> = ({
  onClose,
  onSave,
  open,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const { setNotification } = useNotification();
  const resource = useResource();

  const createScopeMutation = useMutation(createScope, {
    onSuccess: () => {
      setNotification({
        message: 'Scope created',
        type: 'success',
      });
      onSave();
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot create user';
      setAlertMessage({
        message:
          error.response?.status === 409
            ? 'Scope name already exists. try another name'
            : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  return (
    <StyledDialog
      onClose={() => onClose()}
      aria-labelledby="dialog-title"
      open={open}
    >
      <StyledDialogTitle id="dialog-title">Add scope</StyledDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: ScopeCreateDto,
          { setSubmitting }: FormikHelpers<ScopeCreateDto>
        ) => {
          createScopeMutation.mutate({
            clientId: resource.clientId,
            payload: values,
          });
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <DialogContent>
              <ScopeForm />
            </DialogContent>

            <DialogActions>
              <Button color="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <LoadingButton
                data-test-id="btn-save-scope"
                disabled={!(isValid && dirty) || isSubmitting}
                loading={createScopeMutation.isLoading}
                type="submit"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export { ScopeFormDialog };
