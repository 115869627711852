import AddIcon from '@mui/icons-material/Add';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC } from 'react';

const AddButton: FC<ButtonProps> = ({ children = 'Add', ...rest }) => {
  return (
    <Button
      color="primary"
      startIcon={<AddIcon />}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
};

export { AddButton };
