export const CONFIG = {
  pagination: {
    offset: 0,
    step: 25,
  },
  query: {
    staleTime: 0,
  },
  search: {
    term: '',
  },
  ui: {
    debounceDelay: 700,
  },
};
