import Button from '@mui/material/Button';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IResourceClient } from '@bom-nextgen-keycloak/models';
import { selectPermissionResourceDetail } from '@bom-nextgen-keycloak/web/core';
import {
  ResourceDeleteDialog as DeleteDialog,
  useResource2,
} from '@bom-nextgen-keycloak/web/pages/feature-resources';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ProtectedRouter,
  TabbedContent,
  TabList,
} from '@bom-nextgen-keycloak/web/shared/ui';

import { useResourceDetail2 } from './hooks';
import { PARENT_PATH } from './shared';

const ResourceDetail2: FC = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const {
    canDelete,
    isDeleteDialogOpen,
    isDeleting,
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
  } = useResource2({
    callback: () => navigate(PARENT_PATH),
    disableQuery: true,
  });

  const { data, isLoading } = useResourceDetail2(id);
  const { canViewAttributesTab, canViewGeneralTab } = useSelector(
    selectPermissionResourceDetail
  );
  const parentPath = PARENT_PATH;
  const rootPath = `${parentPath}/${id}`;
  const showDeleteButton = canDelete;
  const disableDeleteButton = isLoading;

  const tabList: TabList = [
    {
      enabled: canViewGeneralTab,
      label: 'General',
      value: '',
    },
    {
      enabled: canViewAttributesTab,
      label: 'Attributes',
      value: 'attributes',
    },
  ];

  const itemRepresentation: IResourceClient = {
    _id: data?._id ?? '',
    attributes: data?.attributes ?? [],
    displayName: data?.displayName ?? '',
    name: data?.name ?? '',
    owner: data?.owner ?? {},
    ownerManagedAccess: data?.ownerManagedAccess ?? false,
    resourceScopes: data?.resourceScopes ?? [],
    scopes: data?.scopes ?? [],
    type: data?.type ?? '',
    uris: data?.uris ?? [],
  };

  const handleDelete = () => {
    handleDeleteRequest(itemRepresentation);
  };

  const renderActions = () => {
    return showDeleteButton ? (
      <Button
        color="error"
        disabled={disableDeleteButton}
        onClick={handleDelete}
        variant="text"
      >
        Delete
      </Button>
    ) : null;
  };

  return (
    <Fragment>
      <TabbedContent
        actions={renderActions()}
        breadcrumb={data?.name}
        feature="Resources"
        parentPath={parentPath}
        rootPath={rootPath}
        tabList={tabList}
      />
      <DeleteDialog
        loading={isDeleting}
        name={itemRepresentation.name}
        onClose={handleDeleteCancel}
        onSubmit={handleDeleteAction}
        open={isDeleteDialogOpen}
      />
    </Fragment>
  );
};

const WrappedResourceDetailPage: FC = () => {
  const { canViewPage } = useSelector(selectPermissionResourceDetail);

  return (
    <ProtectedRouter
      canView={canViewPage}
      feature={FeaturePage.RESOURCE}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <ResourceDetail2 />
    </ProtectedRouter>
  );
};

export { WrappedResourceDetailPage };
