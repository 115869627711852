import {
  ResourceApplication,
  ScopeApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import IconDashboard from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import IconPeople from '@mui/icons-material/People';
import SecurityIcon from '@mui/icons-material/Security';

const appMenuItems = [
  {
    name: 'Dashboard',
    title: 'Dashboard',
    link: '/dashboard',
    Icon: IconDashboard,
    isIndex: true,
  },
  {
    name: 'Users & Groups',
    title: 'Users & Groups',
    Icon: IconPeople,
    items: [
      { name: 'User', title: 'Users', link: '/users' },
      { name: 'Group', title: 'Groups', link: '/groups' },
    ],
  },
  {
    name: 'Company',
    title: 'Companies',
    Icon: LocationCityIcon,
    link: '/companies',
  },
  {
    name: 'Role',
    title: 'Roles',
    Icon: AssignmentIndIcon,
    link: '/roles',
  },
  {
    name: 'Authorization',
    title: 'Authorization',
    Icon: SecurityIcon,
    items: [
      { name: 'Scope', title: 'Scopes', link: '/scopes' },
      { name: 'Resource', title: 'Resources', link: '/resources' },
      { name: 'Policy', title: 'Policies', link: '/policies' },
      { name: 'Permission', title: 'Permissions', link: '/permissions' },
    ],
  },
  {
    name: 'Event',
    title: 'Events',
    Icon: EventIcon,
    link: '/events',
  },
];

export const getAppMenuItems = (features: ResourceApplication[]) => {
  const pages = features.map((val) => ({
    name: val.resourceName,
    scopes: val.scopes?.map((val) => val.name),
  }));

  return appMenuItems.filter((item) => {
    const findMenuItem = pages.find((page) => page.name === item.name);

    if (item.isIndex) {
      return true;
    } else if (
      findMenuItem &&
      findMenuItem?.scopes?.includes(ScopeApplication.VIEW)
    ) {
      return true;
    } else if (item.items) {
      item.items = item.items.filter((subMenu) => {
        const findSubMenuItem = pages.find(
          (page) => page.name === subMenu.name
        );
        return (
          findSubMenuItem &&
          findSubMenuItem?.scopes?.includes(ScopeApplication.VIEW)
        );
      });
      return item.items.length ? true : false;
    }
    return false;
  });
};
