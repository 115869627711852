import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DECISION_STRATEGIES,
  PERMISSION_TYPES,
  selectPermissionScopeDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchOptionScopeByResource,
  fetchPolicy,
  fetchResources,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  Capitalize,
  DropdownFieldPro,
  FormActionsGroup,
  FormBox,
  FormField,
  FormSpinner,
  FormText,
  ProtectedRouter,
  SwitchField,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getButtonStatus,
  getFieldError,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';
import { usePermissionDetail } from '../../hooks';
import { CONFIG, FormType, PARENT_PATH, validationSchema } from '../../shared';

const FORM_FIELDS = { ...CONFIG.form.fields };

const General: FC = () => {
  const { id = '', type = '' } = useParams();
  const { clientId } = useResource();
  const navigate = useNavigate();
  const { data, handleUpdate, isLoading, isUpdating } = usePermissionDetail(
    clientId,
    id,
    type
  );
  const resourcePermission = data?.resourcePermissions[0];
  const resourceId = resourcePermission?._id || '';
  const resourceName = resourcePermission?.name || '';
  const { isEditGeneralTab: canEdit } = useSelector(
    selectPermissionScopeDetail
  );
  const { setAlertMessage } = useAlertMessage();
  const [searchResourceQuery, setSearchResourceQuery] = useState('');
  const [searchPolicyQuery, setSearchPolicyQuery] = useState('');
  const [selectedResource, setSelectedResource] = useState('');
  const [isResourceChanged, setIsResourceChanged] = useState(false);
  const debouncedSearchResourceQuery = useDebounce(
    searchResourceQuery,
    CONFIG.ui.debounceDelay
  );
  const debouncedSearchPolicyQuery = useDebounce(
    searchPolicyQuery,
    CONFIG.ui.debounceDelay
  );
  const isScopeType = type === PERMISSION_TYPES.scope;
  const isResourceLoaded = data !== null && Boolean(resourceId);
  const hasResource = resourceName !== '';
  const disableField = !canEdit;

  const initialValues: FormType = {
    decisionStrategy:
      data?.decisionStrategy || DECISION_STRATEGIES.affirmative.value,
    description: data?.description || '',
    isResourceType: data?.resourcePermissions?.length ? false : true,
    name: data?.name || '',
    policies:
      data?.associatedPolicies?.map((item) => ({
        label: item?.name || '',
        value: item,
      })) || [],
    resources: data?.resourcePermissions?.length
      ? {
          label: data?.resourcePermissions[0].name || '',
          value: data?.resourcePermissions[0],
        }
      : null,
    resourceType: data?.resourceType || '',
    scopes:
      data?.scopePermissions?.map((item) => ({
        label: item?.name || '',
        value: item,
      })) || [],
    type,
  };

  const queryKey = {
    policy: [QUERY_KEY.POLICY_LIST, clientId, debouncedSearchPolicyQuery],
    resource: [
      QUERY_KEY.RESOURCE_LIST,
      clientId,
      'general',
      debouncedSearchResourceQuery,
    ],
    scope: [QUERY_KEY.PERMISSION_OPTIONS_SCOPE, clientId, selectedResource],
  };

  const resourceQuery = useQuery(
    queryKey.resource,
    async () => {
      const resources = await fetchResources(clientId, {
        ...CONFIG.pagination,
        name: searchResourceQuery,
      });

      const payload = resources.items.map((item) => ({
        label: item.name,
        value: {
          ...item,
          required: false,
        },
      }));

      return payload;
    },
    {
      enabled: debouncedSearchResourceQuery !== '',
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get resources';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const scopeQuery = useQuery(
    queryKey.scope,
    async () => {
      const options = await fetchOptionScopeByResource(
        clientId,
        isResourceChanged && selectedResource ? selectedResource : resourceId
      );

      return options.map((item) => ({
        label: item.name,
        value: item,
      }));
    },
    {
      enabled: isScopeType && isResourceLoaded,
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get scopes';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const policyQuery = useQuery(
    queryKey.policy,
    async () => {
      const policys = await fetchPolicy(clientId, {
        ...CONFIG.pagination,
        name: searchPolicyQuery,
      });

      return policys.items.map((item) => ({
        label: item.name,
        value: {
          ...item,
          required: false,
        },
      }));
    },
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get policies';

        setAlertMessage({
          message,
          statusCode: error.response?.status,
          typeStatusMessage: 'error',
        });
      },
      staleTime: CONFIG.query.staleTime,
    }
  );

  const handleCancel = () => {
    navigate(PARENT_PATH);
  };

  const renderAutocompleteSpinner = (isLoading: boolean) => {
    return isLoading ? <CircularProgress color="inherit" size={16} /> : null;
  };

  useEffect(() => {
    if (hasResource) {
      setSearchResourceQuery(resourceName);
    }
  }, [hasResource, resourceName]);

  return isLoading ? (
    <FormSpinner />
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(
        values: FormType,
        { setSubmitting }: FormikHelpers<FormType>
      ) => {
        const payload = {
          decisionStrategy: values.decisionStrategy,
          description: values.description,
          id: data?.id || '',
          name: values.name,
          policies: values.policies.length
            ? values.policies.map((item) => item.value.id)
            : [],
          resources:
            values.resources?.value._id && !values.isResourceType
              ? [values.resources?.value._id]
              : [],
          resourceType: values.isResourceType ? values.resourceType : '',
          scopes: values.scopes.length
            ? values.scopes.map((item) => item.value.id)
            : [],
          type: type,
        };

        handleUpdate(payload);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({
        dirty,
        getFieldMeta,
        isValid,
        resetForm,
        setFieldTouched,
        setFieldValue,
        values,
      }) => {
        const { isResourceType, policies, resources, scopes } = values;
        const { disabled: buttonDisabled } = getButtonStatus({
          canEdit,
          isDirty: dirty,
          isLoading: isUpdating,
          isValid,
        });
        const fieldProps = { disabled: disableField };
        const autocompleteInputProps: TextFieldProps = {
          fullWidth: true,
          InputLabelProps: {
            shrink: true,
          },
          variant: 'standard',
        };
        const fieldErrors = {
          policy: getFieldError(getFieldMeta, FORM_FIELDS.policies.name),
          resource: getFieldError(getFieldMeta, FORM_FIELDS.resources.name),
          scope: getFieldError(getFieldMeta, FORM_FIELDS.scopes.name),
        };
        const resourceOptions = resourceQuery.data || [];
        const scopeOptions = resources ? scopeQuery.data || [] : [];
        const policyOptions = policyQuery.data || [];

        const handleResourceInputChange = (
          event: React.ChangeEvent<unknown>,
          value: string,
          reason: AutocompleteInputChangeReason
        ) => {
          setSearchResourceQuery(value);
        };

        const handlePolicyInputChange = (
          event: React.ChangeEvent<unknown>,
          value: string,
          reason: AutocompleteInputChangeReason
        ) => {
          setSearchPolicyQuery(value);
        };

        const resetScope = () => setFieldValue(FORM_FIELDS.scopes.name, []);

        return (
          <Form>
            <FormBox>
              <FormField>
                <InputLabel shrink>{FORM_FIELDS.type.label}</InputLabel>
                <FormText>
                  <Capitalize>{type}</Capitalize>-based
                </FormText>
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.name.label}
                  name={FORM_FIELDS.name.name}
                  required
                />
              </FormField>
              <FormField>
                <TextField
                  {...fieldProps}
                  label={FORM_FIELDS.description.label}
                  multiline
                  name={FORM_FIELDS.description.name}
                />
              </FormField>

              {!isScopeType && (
                <FormField>
                  <SwitchField
                    {...fieldProps}
                    checked={isResourceType}
                    label={FORM_FIELDS.isResourceType.label}
                    name={FORM_FIELDS.isResourceType.name}
                  />
                </FormField>
              )}

              {!isScopeType && isResourceType && (
                <FormField>
                  <TextField
                    {...fieldProps}
                    label={FORM_FIELDS.resourceType.label}
                    name={FORM_FIELDS.resourceType.name}
                    required
                  />
                </FormField>
              )}

              {!isResourceType && (
                <FormField>
                  <Autocomplete
                    {...fieldProps}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.label}
                    id={FORM_FIELDS.resources.name}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    loading={resourceQuery.isLoading}
                    onChange={(event, value) => {
                      if (!value && isScopeType) {
                        setSelectedResource('');
                      } else if (isScopeType) {
                        setSelectedResource(value?.value._id || '');
                      }

                      resetScope();
                      setIsResourceChanged(true);
                      setFieldValue(FORM_FIELDS.resources.name, value);
                    }}
                    onInputChange={handleResourceInputChange}
                    options={resourceOptions}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        {...fieldProps}
                        {...autocompleteInputProps}
                        error={fieldErrors.resource.isError}
                        helperText={fieldErrors.resource.helperText}
                        // id={FORM_FIELDS.resources.name}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {renderAutocompleteSpinner(
                                resourceQuery.isLoading
                              )}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                        label={FORM_FIELDS.resources.label}
                        name={FORM_FIELDS.resources.name}
                        onBlur={() => {
                          setFieldTouched(FORM_FIELDS.resources.name);
                        }}
                        placeholder={FORM_FIELDS.resources.placeholder}
                        required
                      />
                    )}
                    value={resources}
                  />
                </FormField>
              )}

              {isScopeType && (
                <FormField>
                  <Autocomplete
                    {...fieldProps}
                    ChipProps={{ size: 'small' }}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.label}
                    id={FORM_FIELDS.scopes.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.label === value.label;
                    }}
                    limitTags={3}
                    loading={scopeQuery.isLoading}
                    multiple
                    onChange={(event, value) => {
                      setFieldValue(FORM_FIELDS.scopes.name, value || []);
                    }}
                    options={scopeOptions}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        {...fieldProps}
                        {...autocompleteInputProps}
                        error={fieldErrors.scope.isError}
                        helperText={fieldErrors.scope.helperText}
                        // id={FORM_FIELDS.scopes.name}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {renderAutocompleteSpinner(scopeQuery.isLoading)}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                        label={FORM_FIELDS.scopes.label}
                        name={FORM_FIELDS.scopes.name}
                        onBlur={() => {
                          setFieldTouched(FORM_FIELDS.scopes.name);
                        }}
                        placeholder={FORM_FIELDS.scopes.placeholder}
                        required={scopes.length === 0}
                      />
                    )}
                    value={scopes}
                  />
                </FormField>
              )}

              <FormField>
                <Autocomplete
                  {...fieldProps}
                  ChipProps={{ size: 'small' }}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.label}
                  id={FORM_FIELDS.policies.name}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  limitTags={3}
                  loading={policyQuery.isLoading}
                  multiple
                  onChange={(event, value) => {
                    setFieldValue(FORM_FIELDS.policies.name, value || []);
                  }}
                  onInputChange={handlePolicyInputChange}
                  options={policyOptions}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      {...fieldProps}
                      {...autocompleteInputProps}
                      error={fieldErrors.policy.isError}
                      helperText={fieldErrors.policy.helperText}
                      // id={FORM_FIELDS.policies.name}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {renderAutocompleteSpinner(policyQuery.isLoading)}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                      label={FORM_FIELDS.policies.label}
                      // name={FORM_FIELDS.policies.name}
                      onBlur={() => {
                        setFieldTouched(FORM_FIELDS.policies.name);
                      }}
                      placeholder={FORM_FIELDS.policies.placeholder}
                    />
                  )}
                  value={policies}
                />
              </FormField>
              <FormField>
                <DropdownFieldPro
                  {...fieldProps}
                  label={FORM_FIELDS.decisionStrategy.label}
                  name={FORM_FIELDS.decisionStrategy.name}
                  options={DECISION_STRATEGIES}
                  placeholder={FORM_FIELDS.decisionStrategy.placeholder}
                  required
                />
              </FormField>
            </FormBox>
            <FormActionsGroup
              disableCancel={buttonDisabled.cancel}
              disableReset={buttonDisabled.reset}
              disableSave={buttonDisabled.save}
              loading={isUpdating}
              onCancel={handleCancel}
              onReset={resetForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const WrappedPermissionGeneralPage: FC = () => {
  const { canViewGeneralTab } = useSelector(selectPermissionScopeDetail);

  return (
    <ProtectedRouter
      canView={canViewGeneralTab}
      feature={FeaturePage.SCOPE}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <General />
    </ProtectedRouter>
  );
};

export { WrappedPermissionGeneralPage };
