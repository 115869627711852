import { DialogProps } from '@mui/material/Dialog';
import { FC } from 'react';
import { StyledDialog } from './DialogPro.styled';

type DialogProProps = DialogProps & {
  width?: number;
};

const DialogPro: FC<DialogProProps> = ({ children, ...rest }) => (
  <StyledDialog {...rest}>{children}</StyledDialog>
);

export { DialogPro };
