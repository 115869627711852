import Button from '@mui/material/Button';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IUserRepresentation } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUser,
  selectPermissionUserDetail,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  useUserPage2,
  UserDeleteDialog as DeleteDialog,
  UserRemoveDialog as RemoveDialog,
} from '@bom-nextgen-keycloak/web/pages/feature-user';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ProtectedRouter,
  TabbedContent,
  TabList,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { PARENT_PATH } from './shared/config';
import { useUserDetail2 } from './hooks';

const UserDetail2: FC = () => {
  const params = useParams();
  const { id = '' } = params;
  const navigate = useNavigate();
  const { resourceName } = useResource();
  const {
    handleDeleteAction,
    handleDeleteCancel,
    handleDeleteRequest,
    handleRemoveAction,
    handleRemoveCancel,
    handleRemoveRequest,
    isDeleteDialogOpen,
    isDeleting,
    isRemoveDialogOpen,
    isRemoving,
  } = useUserPage2({
    callback: () => navigate(PARENT_PATH),
    disableQuery: true,
  });
  const { data, isLoading } = useUserDetail2(id);
  const {
    canViewAttributesTab,
    canViewGeneralTab,
    canViewGroupTab,
    canViewIdpTab,
    canViewPasswordTab,
    canViewRoleMappingTab,
    canViewSessionTab,
  } = useSelector(selectPermissionUserDetail);
  const { canViewDelete: canDelete, canViewLeaveGroup: canRemove } =
    useSelector(selectPermissionUser);
  const isFederated =
    data?.federatedIdentities && data?.federatedIdentities?.length > 0;
  const parentPath = PARENT_PATH;
  const rootPath = `${parentPath}/${id}`;
  const showDeleteButton = canDelete;
  const showRemoveButton = canRemove;
  const disableButton = isLoading;

  const tabList: TabList = [
    {
      enabled: canViewGeneralTab,
      label: 'General',
      value: '',
    },
    {
      enabled: canViewAttributesTab,
      label: 'Attributes',
      value: 'attributes',
    },
    {
      enabled: canViewPasswordTab,
      label: 'Password',
      value: 'credentials',
    },
    {
      enabled: canViewGroupTab,
      label: 'Groups & Subscriptions',
      value: 'groups',
    },
    {
      enabled: canViewRoleMappingTab,
      label: 'Role Mappings',
      value: 'role-mappings',
    },
    {
      enabled: canViewSessionTab,
      label: 'Sessions',
      value: 'sessions',
    },
    {
      enabled: isFederated && canViewIdpTab,
      label: 'Identity Provider Links',
      value: 'identity-provider-links',
    },
  ];

  const itemRepresentation: IUserRepresentation = {
    attributes: data?.attributes ?? [],
    clientRoles: data?.clientRoles ?? [],
    createdTimestamp: data?.createdTimestamp ?? 0,
    email: data?.email ?? '',
    emailVerified: data?.emailVerified ?? false,
    enabled: data?.enabled ?? false,
    enabledSSO: data?.enabledSSO ?? false,
    firstName: data?.firstName ?? '',
    groups: data?.groups ?? [],
    id: data?.id ?? '',
    lastName: data?.lastName ?? '',
    origin: data?.origin ?? '',
    realmRoles: data?.realmRoles ?? [],
    self: data?.self ?? '',
    serviceAccountClientId: data?.serviceAccountClientId ?? '',
    totp: data?.totp ?? false,
    username: data?.username ?? '',
  };

  const handleDelete = () => {
    handleDeleteRequest(itemRepresentation);
  };

  const handleRemove = () => {
    handleRemoveRequest(itemRepresentation);
  };

  const renderActions = () => {
    if (showDeleteButton && showRemoveButton) {
      return (
        <Fragment>
          {showRemoveButton && (
            <Button
              color="secondary"
              disabled={disableButton}
              onClick={handleRemove}
              variant="text"
            >
              Remove
            </Button>
          )}
          {showDeleteButton && (
            <Button
              color="error"
              disabled={disableButton}
              onClick={handleDelete}
              variant="text"
            >
              Delete
            </Button>
          )}
        </Fragment>
      );
    }

    return null;
  };

  return (
    <Fragment>
      <TabbedContent
        actions={renderActions()}
        breadcrumb={data?.username}
        feature="Users"
        parentPath={parentPath}
        rootPath={rootPath}
        tabList={tabList}
      />
      <DeleteDialog
        loading={isDeleting}
        onClose={handleDeleteCancel}
        onDelete={handleDeleteAction}
        open={isDeleteDialogOpen}
        username={data?.username}
      />
      <RemoveDialog
        loading={isRemoving}
        onClose={handleRemoveCancel}
        onSubmit={handleRemoveAction}
        open={isRemoveDialogOpen}
        resourceName={resourceName}
        username={itemRepresentation.username}
      />
    </Fragment>
  );
};

const WrappedUserDetailPage: FC = () => {
  const { canViewPage } = useSelector(selectPermissionUserDetail);

  return (
    <ProtectedRouter
      canView={canViewPage}
      feature={FeaturePage.USER}
      isEditPage
      navigatePath={PARENT_PATH}
    >
      <UserDetail2 />
    </ProtectedRouter>
  );
};

export { WrappedUserDetailPage };
